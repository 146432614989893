<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.7,1.7C0,3.4,0,6.1,0,11.5v1c0,5.4,0,8.1,1.7,9.8C3.4,24,6.1,24,11.5,24h1c5.4,0,8.1,0,9.8-1.7
	c1.7-1.7,1.7-4.4,1.7-9.8v-1c0-5.4,0-8.1-1.7-9.8C20.6,0,17.9,0,12.5,0h-1C6.1,0,3.4,0,1.7,1.7z M4.1,7.4c0.1,6.2,3.2,10,8.7,10h0.3
	v-3.6c2,0.2,3.5,1.7,4.1,3.6H20c-0.8-2.8-2.8-4.4-4.1-5c1.3-0.7,3.1-2.5,3.5-5h-2.6c-0.6,2-2.2,3.8-3.8,3.9V7.4h-2.6v6.9
	C8.9,13.9,6.9,12,6.8,7.4H4.1z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "IVk",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
