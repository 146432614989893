<template>
    <div class="fixed top-0 left-0 w-full h-full z-50 p-4 bg-slate-100">
        <div class="text-xl text-center text-slate-400">СПИСОК ОПЛАТ</div>
        <div class="absolute top-2 right-2 text-slate-400 hover:text-slate-500 cursor-pointer" @click="closePopup()">
            <IcoBig name="close" :size="6" />
        </div>

        <div class="flex justify-between items-center gap-4 h-12 my-4">
            <div class="flex gap-2">
                <div class="count h-8">{{ parseInt(total.count).toLocaleString() }}</div>
                <div class="count h-8">{{ parseInt(total.sum).toLocaleString() }}</div>
            </div>

            <input v-model="filters.date" class="input h-8 w-2/12" type="date" placeholder="Дата" />

            <div class="flex items-center gap-2 h-8 w-3/12 text-cyan-600 cursor-pointer">
                <div @click="filters.channel_id = null">ВСЕ</div>
                <div @click="filters.channel_id = 1"><IVk :size="6" /></div>
                <div @click="filters.channel_id = 2"><IVkAds :size="6" /></div>
                <div @click="filters.channel_id = 3"><IVkCamp :size="6" /></div>
                <div @click="filters.channel_id = 4"><IFb :size="6" /></div>
                <div @click="filters.channel_id = 5"><IYa :size="6" /></div>
                <div @click="filters.channel_id = 9"><ITgads :size="6" /></div>
                <div @click="filters.channel_id = 10"><IYoutube :size="6" /></div>
                <div @click="filters.channel_id = 100"><IBase :size="6" /></div>
            </div>

            <div class="relative group w-8 h-8 shrink-0 cursor-pointer" v-if="uniqueTgIds.length">
                <img
                    v-if="parseInt(filters.tg_id)"
                    :src="targetologs[filters.tg_id]?.pic"
                    class="w-8 h-8 shrink-0"
                    @click="tgDropdown = !tgDropdown"
                />
                <img v-else :src="targetologs[0]?.pic" class="w-8 h-8 shrink-0" @click="tgDropdown = !tgDropdown" />
                <div v-if="tgDropdown" class="absolute top-8 -left-20 w-60 z-40 h-fit bg-white p-2 grid grid-cols-4">
                    <template v-for="(tgId, index) in uniqueTgIds" :key="index">
                        <div v-if="tgId > 0" @click="(tgDropdown = false), (filters.tg_id = tgId)">
                            <img :src="targetologs[tgId]?.pic" class="w-12 h-12 aspect-square cursor-pointer" :alt="tgId" />
                        </div>
                    </template>
                </div>
            </div>

            <input v-model.number="filters.minSum" class="input h-8 w-2/12" type="number" placeholder="цена от" />
            <input v-model.number="filters.maxSum" class="input h-8 w-2/12" type="number" placeholder="цена до" />

            <select v-model="filters.event_id" class="input py-1 h-8 w-3/5">
                <div @click="filters.eventId = null" class="text-blue-500 cursor-pointer">ВСЕ</div>
                <option v-for="(title, eventId) in uniqueTitles" :key="eventId" :value="eventId">{{ title }}</option>
            </select>

            <div class="flex items-center gap-2 text-cyan-600 cursor-pointer">
                <div @click="filters.status = null" class="cursor-pointer">ВСЕ</div>
                <div @click="filters.status = 7" class="w-4 h-4 rounded-full !bg-teal-500 cursor-pointer"></div>
                <div @click="filters.status = 9" class="w-4 h-4 rounded-full !bg-pink-500 cursor-pointer"></div>
            </div>

            <button @click="clearFilters()" class="bg-slate-400 text-white py-1 px-2 text-xs rounded">Очистить</button>
        </div>

        <div class="grid grid-cols-11 items-center gap-4 px-4 text-xs text-slate-400">
            <span>ДАТА</span>
            <span>КАНАЛ</span>
            <span>НОМЕР</span>
            <span>ЗАКАЗ</span>
            <span>КЛИЕНТ</span>
            <span class="text-right">ЦЕНА</span>
            <span class="col-span-4">НАЗВАНИЕ</span>
        </div>

        <template v-if="paymentsF">
            <RecycleScroller class="h-[calc(100vh-110px)]" :items="paymentsF" :item-size="44" key-field="id" v-slot="{ item }">
                <div class="w-full rounded-xl h-10 relative my-1 text-slate-500 text-sm group hover:my-2 duration-500">
                    <div class="bg-white/50 h-10 rounded-xl grid grid-cols-11 justify-center items-center gap-4 hover:bg-white">
                        <div class="flex items-center gap-2 pl-2">
                            <div
                                class="w-2 h-2 rounded-full shrink-0"
                                :class="{
                                    '!bg-pink-500': item.status == 9,
                                    '!bg-teal-500': item.status == 7,
                                    '!bg-amber-500': item.status == 3,
                                    '!bg-slate-200': item.status == 1,
                                }"
                            ></div>
                            {{ moment(item.date).format("DD.MM") }}

                            <div class="text-cyan-600">
                                <div v-if="item.channel_id == 1"><IVk :size="6" /></div>
                                <div v-if="item.channel_id == 2"><IVkAds :size="6" /></div>
                                <div v-if="item.channel_id == 3"><IVkCamp :size="6" /></div>
                                <div v-if="item.channel_id == 4"><IFb :size="6" /></div>
                                <div v-if="item.channel_id == 5"><IYa :size="6" /></div>
                                <div v-if="item.channel_id == 9"><ITgads :size="6" /></div>
                                <div v-if="item.channel_id == 10"><IYoutube :size="6" /></div>
                                <div v-if="item.channel_id == 100"><IBase :size="6" /></div>
                            </div>
                        </div>

                        <div class="flex items-center gap-1">
                            <img
                                :src="targetologs[item.tg_id]?.pic"
                                class="shrink-0 h-8 mt-2 opacity-50 group-hover:h-[40px] group-hover:mt-0 group-hover:opacity-100 duration-500"
                            />
                            <span class="text-xs">{{ targetologs[item.tg_id]?.utm }}</span>
                        </div>

                        <div>{{ item.id }}</div>

                        <div>{{ item.order_id }}</div>

                        <div @click="pickUserId(item.user_id)">{{ item.user_id }}</div>

                        <div class="latobold text-right text-cyan-500">{{ parseInt(item.sum).toLocaleString() }}</div>
                        <!-- <div class="col-span-4">{{ item.event_id }}</div> -->
                        <div class="col-span-4 h-6 overflow-hidden">{{ item.title }}</div>
                    </div>
                </div>
            </RecycleScroller>
        </template>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
    name: "OrderList",

    components: { RecycleScroller },

    data() {
        return {
            moment: moment,
            filters: {
                date: "",
                channel_id: null,
                tg_id: null,
                event_id: null,
                minSum: null,
                maxSum: null,
                status: null,
            },
            tgDropdown: false,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "targetologs", "closePopup"]),
        ...mapState(useClientStore, ["payments", "pickUserId", "getPayments"]),

        uniqueTitles() {
            const titlesMap = {};
            this.payments.forEach((item) => {
                if (!titlesMap[item.event_id]) titlesMap[item.event_id] = item.title;
            });
            return titlesMap;
        },

        uniqueTgIds() {
            const tgIdsSet = new Set();
            this.payments.forEach((order) => {
                tgIdsSet.add(order.tg_id);
            });
            return Array.from(tgIdsSet);
        },

        paymentsF() {
            return this.payments.filter((item) => {
                return (
                    (!this.filters.date || item.date == this.filters.date) &&
                    (!this.filters.channel_id || item.channel_id == this.filters.channel_id) &&
                    (!this.filters.event_id || item.event_id == this.filters.event_id) &&
                    (!this.filters.tg_id || item.tg_id == this.filters.tg_id) &&
                    (!this.filters.minSum || parseFloat(item.sum) >= this.filters.minSum) &&
                    (!this.filters.maxSum || parseFloat(item.sum) <= this.filters.maxSum) &&
                    (!this.filters.status || item.status == this.filters.status)
                );
            });
        },

        total() {
            const count = this.paymentsF.length;
            const sum = this.paymentsF.reduce((sum, order) => sum + parseFloat(order.sum), 0);

            return { count, sum };
        },
    },

    watch: {
        filters: {
            handler() {},
            deep: true,
        },
    },

    methods: {
        clearFilters() {
            this.filters = { date: "", channel_id: null, event_id: null, tg_id: null, minSum: null, maxSum: null, status: null };
            this.tgDropdown = false;
        },
    },

    mounted() {
        this.getPayments();
    },
};
</script>
