<template>
    <svg
        class="fill-stroke transition duration-300"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 100 100"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <circle cx="50" cy="50" r="30" stroke="#0ea5e9" stroke-opacity="0.5" stroke-width="10" fill="none"></circle>
        <circle cx="50" cy="50" r="30" stroke="#0ea5e9" stroke-width="8" stroke-linecap="round" fill="none">
            <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;180 50 50;720 50 50"
                keyTimes="0;0.5;1"
            ></animateTransform>
            <animate
                attributeName="stroke-dasharray"
                repeatCount="indefinite"
                dur="1s"
                values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
                keyTimes="0;0.5;1"
            ></animate>
        </circle>
    </svg>
</template>

<script>
export default {
    name: "IconLoader",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
