<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            clip-rule="evenodd"
            d="m13.4755 6.51266c0-.44167.4854-.65067.7971-.40594l9.5321 7.48368c.2604.2044.2604.6075 0 .8119l-9.5321 7.4837c-.3117.2447-.7971.0357-.7971-.4059v-4.4848c0-.5521-.4477-.9997-1-.9997-3.56486 0-6.25118 1.4484-8.25863 3.2666.36088-2.1616 1.15234-3.8067 2.22404-5.0458 1.49731-1.7312 3.63669-2.7798 6.21929-3.2648.4728-.0888.8153-.5016.8153-.9825zm2.0324-1.97826c-1.6538-1.29839-4.0324-.09353-4.0324 1.97826v2.64707c-2.56424.6094-4.85357 1.79057-6.54753 3.74907-1.87682 2.17-2.92797 5.1847-2.92797 9.1421 0 .4266.27078.8061.67422.9451s.85064.0067 1.11351-.3293c1.74124-2.2258 4.17502-4.2919 7.68777-4.6251v3.4385c0 2.0718 2.3786 3.2766 4.0324 1.9782l9.5322-7.4837c1.2799-1.0048 1.2799-2.9516 0-3.9565z"
            fill="currentColor"
            fill-rule="evenodd"
        />
    </svg>
</template>

<script>
export default {
    name: "IVkRepost",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
