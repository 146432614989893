// Функция для получения размера LocalStorage
export function size() {
    let totalSize = 0;

    for (let key in localStorage) {
        if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
            let item = localStorage.getItem(key);
            totalSize += key.length + item.length;
        }
    }

    // Функция для форматирования размера в удобочитаемый формат
    if (totalSize >= 1048576) return (totalSize / 1048576).toFixed(2) + " MB";
    if (totalSize >= 1024) return (totalSize / 1024).toFixed(2) + " KB";
    return totalSize + " bytes";
}
