<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19,3.5H5c-2.8,0-5,2.2-5,5v7c0,2.8,2.2,5,5,5h14c2.8,0,5-2.2,5-5v-7C24,5.7,21.8,3.5,19,3.5z M15.6,12.3
		l-6.6,3.1c-0.2,0.1-0.4,0-0.4-0.2V8.8c0-0.2,0.2-0.3,0.4-0.2l6.6,3.3C15.8,12,15.8,12.3,15.6,12.3z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "IYoutube",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
