import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";

export const useAccountStore = defineStore("account", {
    state: () => ({
        apikey: null,
        projects: [], // проекты (перенести в аккаунт)
        clients: [], // расход на каждый клиентский кабинет
        ads: [], // расходы на рекламу

        events: [], // события
        products: [], // товары
        // utms: [], // список всех utm меток
    }),

    actions: {
        // получаем все данные
        async getAccount() {
            const res = await API.GET("account/account/data");

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.apikey = res.data.apikey;
            this.projects = res.data.projects;
            this.events = res.data.events;
            this.products = res.data.products;

            // if (res.data.utms) this.utms.source = res.data.utms.source.sort((a, b) => b.count - a.count);
            // if (res.data.utms) this.utms.medium = res.data.utms.medium.sort((a, b) => b.count - a.count);
            // if (res.data.utms) this.utms.campaign = res.data.utms.campaign.sort((a, b) => b.count - a.count);
            // if (res.data.utms) this.utms.content = res.data.utms.content.sort((a, b) => b.count - a.count);
            // if (res.data.utms) this.utms.term = res.data.utms.term.sort((a, b) => b.count - a.count);

            // this.utms = res.data.utms;
        },

        // получаем все данные по рекламным кабинетам
        async getClients() {
            const res = await API.GET("account/client/list", { srt: mainStore().s.start, end: mainStore().s.end, utms: this.utmsFilter });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.clients = res.data.clients;
        },

        async parseClientCampaigns(client_id) {
            let formData = new FormData();
            formData.append("client_id", client_id);

            const res = await API.POST("account/client/parse-ads", formData);
            console.log("parseClient", res.data);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.clients = res.data.clients;
        },

        // получаем все данные
        async getProjects() {
            const res = await API.GET("account/project/list");

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.projects = res.data.projects;
        },

        async generateApiKey() {
            const res = await API.GET("account/account/apikey");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.apikey = res.data.apikey;
        },
    },
});
