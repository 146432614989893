<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
            d="M0,12C0,5.4,5.4,0,12,0c2.7,0,5.2,0.9,7.3,2.5l-2.8,3.6c-1.3-1-2.9-1.5-4.5-1.5c-4.1,0-7.4,3.3-7.4,7.4s3.3,7.4,7.4,7.4
	c3.3,0,6.1-2.2,7.1-5.1H12V9.7h12V12c0,6.6-5.4,12-12,12S0,18.6,0,12z"
        />
    </svg>
</template>

<script>
export default {
    name: "IGg",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
