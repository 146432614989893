<template>
    <!-- <div class="grid grid-cols-main min-h-screen gap-4 tabular-nums"> -->
    <!-- <div class="w-100px sticky top-16 w-32 max-h-[calc(100vh-100px)] overflow-y-auto z-30" @click="setPopup('roadmap')">ФИЛЬТР</div> -->

    <div class="w-full">
        <div
            class="flex justify-between items-center gap-4 bg-white p-4 rounded relative mb-8"
            :class="{
                '!text-pink-700': progress.total.progress < 30,
                '!text-yellow-700': progress.total.progress >= 30 && progress.total.progress < 70,
                '!text-teal-700': progress.total.progress >= 70,
            }"
        >
            <div
                class="absolute top-0 left-0 h-full bg-gray-200 rounded"
                :style="{ width: animatedProgress + '%' }"
                :class="{
                    'bg-pink-400': progress.total.progress < 30,
                    'bg-yellow-400': progress.total.progress >= 30 && progress.total.progress < 70,
                    'bg-teal-400': progress.total.progress >= 70,
                }"
            ></div>

            <div class="z-30">ОБЩИЙ ПРОГРЕСС</div>
            <div class="z-30 latobold justify-self-end">{{ progress.total.progress }}%</div>
        </div>

        <div class="flex justify-between items-center mb-2">
            <h2 class="text-slate-400">МОДУЛИ СИСТЕМЫ</h2>
            <div v-if="s.clear" class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings()">ОЧИСТИТЬ ФИЛЬТР</div>
        </div>

        <div class="grid grid-cols-2 md:grid-cols-6 gap-4 mb-8">
            <div v-for="(item, module) in progress.modules" :key="module" class="w-full group relative duration-500" @click="setModule(module)">
                <div class="grid grid-cols-6 items-center gap-4 bg-white text-slate-600 p-4 rounded">
                    <div class="col-span-4">{{ module }}</div>
                    <div
                        class="w-fit bg-slate-300 text-white text-sm px-2 rounded-full col-span-2 justify-self-end"
                        :class="{
                            '!bg-pink-400': item.progress < 30,
                            '!bg-yellow-400': item.progress >= 30 && item.progress < 70,
                            '!bg-teal-400': item.progress >= 70,
                        }"
                    >
                        {{ item.progress }}%
                    </div>
                </div>

                <div
                    class="bg-gray-200 rounded-full h-1 overflow-hidden mt-1"
                    :style="{ width: item.progress + '%' }"
                    :class="{
                        'bg-pink-400': item.progress < 30,
                        'bg-yellow-400': item.progress >= 30 && item.progress < 70,
                        'bg-teal-400': item.progress >= 70,
                    }"
                ></div>
            </div>
        </div>

        <h2 class="text-slate-400 mb-2">ЗАДАЧИ</h2>
        <div v-for="item in tasks" :key="item" class="py-px w-full group hover:py-1 relative duration-500">
            <div class="grid grid-cols-12 items-center gap-4 bg-white/50 text-slate-400 hover:bg-cyan-50 hover:text-cyan-600 py-2 px-4 rounded">
                <div class="col-span-2 md:col-span-1">
                    <div v-if="item.status == 1" class="w-fit bg-slate-300 text-white text-sm px-2 rounded-full mx-auto">new</div>
                    <div v-if="item.status == 3" class="w-fit bg-amber-400 text-white text-sm px-2 rounded-full mx-auto">work</div>
                    <div v-if="item.status == 5" class="w-fit bg-pink-400 text-white text-sm px-2 rounded-full mx-auto">test</div>
                    <div v-if="item.status == 7" class="w-fit bg-teal-400 text-white text-sm px-2 rounded-full mx-auto">done</div>
                </div>

                <div v-if="item.module == 'vk'" class="text-blue-500"><IVk :size="5" /></div>
                <div v-if="item.module == 'vkads'" class="text-blue-600 p-1"><IVkAds :size="4" /></div>
                <div v-if="item.module == 'market'" class="text-pink-600"><IVkCamp :size="5" /></div>
                <div v-if="item.module == 'facebook'"><IFb :size="5" /></div>
                <div v-if="item.module == 'yandex'" class="p-1"><IYa :size="4" /></div>
                <div v-if="item.module == 'getcourse'" class="p-1">C</div>
                <div v-if="item.module == 'bizon'" class="p-1">B</div>
                <div v-if="item.module == 'metrika'" class="p-1">M</div>
                <div v-if="item.module == 'senler'" class="p-1">S</div>
                <div v-if="item.module == 'google'" class="p-1">G</div>
                <div v-if="item.module == 'tgads'" class="p-1">T</div>
                <div v-if="item.module == 'frontend'" class="p-1">F</div>
                <div v-if="item.module == 'api'" class="p-1">A</div>
                <div v-if="item.module == 'user'"><IconUser :size="5" /></div>
                <div v-if="item.module == 'optimize'"><IcoBig name="new" /></div>
                <div v-if="item.module == 'telegram'" class="p-1">Tl</div>

                <div class="flex items-center gap-2 col-span-9 md:col-span-10">
                    <span class="w-5 text-xs">#{{ item.id }}</span>
                    <span class="text-slate-500 group-hover:text-cyan-600"> {{ item.name }}</span>
                </div>
                <!-- <div class="">{{ item.plan }}</div> -->
                <!-- <div class="">{{ item.fact }}</div> -->
            </div>
        </div>
    </div>
    <!-- </div> -->

    <div v-if="s.popup == 'roadmap'" class="absolute top-16 left-0 right-0 bottom-0 bg-white z-40 p-4 shadow-lg"></div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAdminStore } from "@/store/admin";
import { useProfileStore } from "@/store/profile";

export default {
    name: "AdminPage",

    data() {
        return {
            animatedProgress: 0, // Начальное значение анимированного прогресса
            filters: [
                // Список фильтров
                { id: 1, name: "Фильтр 1" },
                { id: 2, name: "Фильтр 2" },
                { id: 3, name: "Фильтр 3" },
            ],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "clearSettings"]),
        ...mapState(useAdminStore, ["roadmap", "getRoadmap"]),
        ...mapState(useProfileStore, ["profile", "getProfile", "getPermission", "uploadAvatar"]),

        progress() {
            const modules = {
                vk: { name: "vk", done: 0, work: 0 },
                vkads: { name: "vkads", done: 0, work: 0 },
                market: { name: "market", done: 0, work: 0 },
                facebook: { name: "facebook", done: 0, work: 0 },
                yandex: { name: "yandex", done: 0, work: 0 },
                getcourse: { name: "getcourse", done: 0, work: 0 },
                bizon: { name: "bizon", done: 0, work: 0 },
                metrika: { name: "metrika", done: 0, work: 0 },
                senler: { name: "senler", done: 0, work: 0 },
                google: { name: "google", done: 0, work: 0 },
                tg: { name: "tgads", done: 0, work: 0 },
                tgads: { name: "tgads", done: 0, work: 0 },
                api: { name: "api", done: 0, work: 0 },
                // admin: { name: "admin", done: 0, work: 0 },
                frontend: { name: "frontend", done: 0, work: 0 },
                optimize: { name: "optimize", done: 0, work: 0 },
                user: { name: "user", done: 0, work: 0 },
                telegram: { name: "telegram", done: 0, work: 0 },
            };

            let totalDone = 0;
            let totalWork = 0;

            this.roadmap.forEach((item) => {
                if (!modules[item.module]) modules[item.module] = { done: 0, work: 0 };

                if (item.status === 7) {
                    modules[item.module].done += item.plan;
                    totalDone += item.plan;
                }

                if ([1, 2, 3, 4, 5, 6].includes(item.status)) {
                    modules[item.module].work += item.plan;
                    totalWork += item.plan;
                }
            });

            // Рассчитываем процент прогресса
            Object.values(modules).forEach((item) => {
                const total = item.done + item.work;
                item.progress = total > 0 ? Math.round((item.done / total) * 100) : 0;
            });

            let total = { done: totalDone, work: totalWork };
            total.progress = totalDone > 0 ? Math.round((totalDone / (totalDone + totalWork)) * 100) : 0;

            return { modules, total };
        },

        tasks() {
            if (!this.roadmap) return [];
            let data = this.roadmap;

            if (this.s.module_id) data = data.filter((item) => item.module == this.s.module_id);

            return data;
        },
    },

    methods: {
        setModule(module) {
            this.s.module_id = module;
            this.s.clear = true;
            this.setSettings(this.s);
        },
    },

    mounted() {
        this.getRoadmap();

        // Анимация заполнения прогресс-бара за 2 секунды
        setInterval(() => {
            if (this.animatedProgress < this.progress.total.progress) {
                this.animatedProgress += 1;
            }
        }, 20); // Интервал анимации
    },
};
</script>

<style scoped>
@media (max-width: 640px) {
    .grid-cols-main {
        grid-template-columns: 1fr;
    }
}
</style>

<!-- <div v-if="item.status == 1"><IcoBig name="new" /></div>
                        <div v-if="item.status == 3"><IcoBig name="edit" /></div>
                        <div v-if="item.status == 5"><IcoBig name="eye" /></div>
                        <div v-if="item.status == 7" class="text-teal-500"><IcoBig name="ok" /></div> -->
