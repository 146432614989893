<template>
    <div class="fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] h-fit w-5/6 rounded z-50 p-8 bg-white overflow-y-scroll">
        <div class="text-xl text-center text-slate-400">СОЗДАТЬ РУЧНОЙ РАСХОД</div>

        <div class="grid grid-cols-2 gap-2 my-4 items-center">
            <p class="text-base mr-4">Название</p>
            <input type="text" class="input" v-model="hand.name" />

            <p class="text-base mr-4">Дата</p>
            <input type="date" class="input" v-model="hand.date" />

            <p class="text-base mr-4">Канал трафика <span class="text-xs text-slate-400">vk, youtube, партнер</span></p>
            <input type="text" class="input" v-model="hand.channel_id" />

            <p class="text-base mr-4">Сумма расхода</p>
            <input type="text" class="input" v-model="hand.spent" />
        </div>

        <div class="flex items-center gap-2 btn btn-green w-fit py-2 px-3 mx-auto mt-4" @click="createHandStat(hand), closePopup()">
            <IcoBig name="plus" :size="5" />Создать
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";

export default {
    name: "CreateHandStat",

    data() {
        return {
            hand: {},
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useClientStore, ["createHandStat"]),
    },
};
</script>
