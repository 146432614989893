<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent :view="['date', 'compact', 'channel']" @update="update" />
        </div>

        <div class="w-full">
            <!-- <div class="w-full my-4">{{ funnel[7] }}</div> -->

            <div class="w-full flex">
                <div class="tbl w-52 shrink-0 border border-slate-100 rounded overflow-hidden text-sm text-slate-500">
                    <div class="bg-white">
                        <div class="flex items-center !h-8 text-slate-400 bg-cyan-50">ДАТА</div>
                        <div class="bb text-pink-600">Бюджет без НДС</div>
                        <div class="bb">Просмотры</div>
                        <div class="bb">Клики</div>
                    </div>

                    <div class="bg-white my-4">
                        <div class="bb">Регистраций всего</div>
                        <div class="bb">Реклама</div>
                        <div class="bb">База</div>
                        <div class="bb">Без канала</div>
                        <div class="bb">с 18:00 до 10:00</div>
                        <div class="bb">с 10:00 до 18:00</div>
                        <div v-if="!s.compact" class="bb text-slate-400">Конверсия посадки</div>
                        <div v-if="!s.compact" class="text-slate-400">Цена лида CPA</div>
                    </div>

                    <div class="bg-white my-2">
                        <div class="bb">Bебинар 1 всего</div>
                        <!-- <div v-if="!s.compact" class="bb">На эфире</div>
                    <div v-if="!s.compact" class="bb">На повторе</div>
                    <div v-if="!s.compact" class="text-slate-400">Доходимость</div> -->
                    </div>

                    <div class="bg-white my-2">
                        <div class="bb">Bебинар 2 всего</div>
                        <!-- <div v-if="!s.compact" class="bb">На эфире</div>
                    <div v-if="!s.compact" class="bb">На повторе</div>
                    <div v-if="!s.compact" class="bb text-slate-400">Переходимость</div>
                    <div v-if="!s.compact" class="text-slate-400">Конверсия в заказ</div> -->
                        <div class="bb">Заказов</div>
                    </div>

                    <div class="bg-white my-2">
                        <div class="bb">Bебинар 3 всего</div>
                        <!-- <div v-if="!s.compact" class="bb">На эфире</div>
                    <div v-if="!s.compact" class="bb">На повторе</div>
                    <div v-if="!s.compact" class="bb text-slate-400">Переходимость</div>
                    <div v-if="!s.compact" class="text-slate-400">Конверсия в заказ</div> -->
                        <div class="bb">Заказов</div>
                    </div>

                    <div class="bg-white my-4">
                        <div class="bb font-bold">Заказов всего</div>
                        <div v-if="!s.compact" class="bb text-slate-400">% от регистраций</div>
                        <div class="bb">Заказов с хвоста</div>
                        <!-- <div v-if="!s.compact" class="bb">% с хвоста</div> -->

                        <div class="bb">Средний чек</div>
                        <div class="font-bold bg-cyan-50">Заказов на сумму</div>
                    </div>

                    <div class="bg-white my-2">
                        <div class="bb">Оплат всего</div>
                        <div v-if="!s.compact" class="bb text-slate-400">% от регистраций</div>
                        <!-- <div v-if="!s.compact" class="bb text-slate-400">Конверсия в оплату</div> -->
                        <div class="font-bold bg-cyan-50">Оплат на сумму</div>
                    </div>

                    <div class="bg-white">
                        <div class="font-bold">ROMI</div>
                    </div>
                </div>

                <div v-if="funnel" class="w-[calc(100vw-368px)] flex gap-2 overflow-x-scroll snap-x scroll-pl-2 scroll-smooth pl-2 pr-4">
                    <template v-for="day in funnel" :key="day.date">
                        <template v-if="day?.date">
                            <div
                                class="tbl snap-start w-28 h-fit shrink-0 rounded overflow-hidden text-sm text-slate-500 text-right group"
                                :class="{
                                    'border !border-cyan-300': [6, 7].includes(moment(day.date).isoWeekday()),
                                    'border !border-teal-300': moment(day.date).format('Y-M-D') == moment().format('Y-M-D'),
                                    'border !border-slate-300 ': !day.date && day.name,
                                }"
                            >
                                <div class="bg-white/50 group-hover:bg-white">
                                    <template v-if="day.date">
                                        <div
                                            class="flex justify-end items-center !h-8 text-slate-400 bg-cyan-50"
                                            :class="{
                                                ' !text-cyan-500 bg-cyan-50': [6, 7].includes(moment(day.date).isoWeekday()),
                                                ' !text-teal-500 bg-teal-100': moment(day.date).format('Y-M-D') == moment().format('Y-M-D'),
                                            }"
                                        >
                                            {{ moment(day.date).format("D") }}&nbsp;{{ month[parseInt(moment(day.date).format("M"))] }}
                                        </div>
                                    </template>

                                    <template v-if="!day.date && day.name">
                                        <div class="flex justify-end items-center !h-8 text-slate-400">{{ day.name }}</div>
                                    </template>

                                    <!-- <div
                                        class="flex justify-between items-center font-bold text-cyan-500 hover:text-cyan-400 cursor-pointer"
                                        @click="getClients({ srt: day.date, end: day.date })"
                                    >
                                        <IconClick :size="3" />{{ parseInt(day.spent * 1.2).toLocaleString() }}
                                    </div> -->

                                    <div class="bb text-pink-600">{{ parseInt(day.spent).toLocaleString() }}</div>
                                    <div class="bb">{{ parseInt(day.views).toLocaleString() }}</div>
                                    <div class="bb">{{ parseInt(day.clicks).toLocaleString() }}</div>
                                </div>

                                <div class="bg-white/50 group-hover:bg-white my-4">
                                    <div class="bb">{{ day.regs }}</div>
                                    <div class="bb">{{ day.regs_ad }}</div>
                                    <div class="bb">{{ day.regs - day.regs_ad - day.regs_none }}</div>
                                    <div class="bb">{{ day.regs_none }}</div>
                                    <div class="bb">{{ day.regs18to9 }}</div>
                                    <div class="bb">{{ day.regs10to17 }}</div>
                                    <div v-if="!s.compact" class="bb text-slate-400">{{ parseInt(day.cr_regs) }}%</div>
                                    <div v-if="!s.compact" class="text-slate-400">{{ Math.round(day.cpa).toLocaleString() }}</div>
                                </div>

                                <div class="bg-white/50 group-hover:bg-white my-2">
                                    <div class="bb">{{ day.web1 }}</div>
                                    <!-- <div v-if="!s.compact" class="bb">{{ day.web1.live }}</div>
                        <div v-if="!s.compact" class="bb">{{ day.web1.repeat }}</div>
                        <div v-if="!s.compact" class="text-slate-400">{{ parseFloat(day.web1.come).toFixed(2) }}%</div> -->
                                </div>

                                <div class="bg-white/50 group-hover:bg-white my-2">
                                    <div class="bb">{{ day.web2 }}</div>
                                    <!-- <div v-if="!s.compact" class="bb">{{ day.web2.live }}</div>
                        <div v-if="!s.compact" class="bb">{{ day.web2.repeat }}</div>
                        <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.web2.come).toFixed(2) }}%</div>
                        <div v-if="!s.compact" class="text-slate-400">{{ parseFloat(day.web2.orders_cr).toFixed(2) }}%</div> -->
                                    <div class="bb">{{ day.orderc2 || 0 }}</div>
                                </div>

                                <div class="bg-white/50 group-hover:bg-white my-2">
                                    <div class="bb">{{ day.web3 }}</div>
                                    <!-- <div v-if="!s.compact" class="bb">{{ day.web3.live }}</div>
                        <div v-if="!s.compact" class="bb">{{ day.web3.repeat }}</div>
                        <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.web3.come).toFixed(2) }}%</div>
                        <div v-if="!s.compact" class="text-slate-400">{{ parseFloat(day.web3.orders_cr).toFixed(2) }}%</div> -->
                                    <div class="bb">{{ day.orderc3 || 0 }}</div>
                                </div>

                                <div class="bg-white/50 group-hover:bg-white my-4">
                                    <div
                                        class="flex justify-between items-center bb font-bold cursor-pointer hover:text-cyan-500"
                                        @click="getOrders({ srt: day.date, end: day.date })"
                                    >
                                        <IconClick :size="3" /> {{ day.orderc || 0 }}
                                    </div>
                                    <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.cpo).toFixed(2) }}%</div>

                                    <div class="bb">{{ day.ordercend || 0 }}</div>
                                    <!-- <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.ordersDozim_cr).toFixed(2) }}%</div> -->

                                    <div class="bb">{{ parseInt(day.avg).toLocaleString() }}</div>
                                    <div class="font-bold bg-cyan-50 text-cyan-500">{{ parseInt(day.orders).toLocaleString() }}</div>
                                </div>

                                <div class="bg-white/50 group-hover:bg-white my-2">
                                    <div
                                        class="bb flex justify-between items-center cursor-pointer hover:text-cyan-500"
                                        @click="getPays({ srt: day.date, end: day.date })"
                                    >
                                        <IconClick :size="3" />{{ day.paymentc || 0 }}
                                    </div>
                                    <!-- <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.pay_cr).toFixed(2) }}%</div> -->
                                    <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.pay_10).toFixed(2) }}%</div>
                                    <div class="font-bold bg-cyan-50 text-cyan-500">{{ parseInt(day.payments).toLocaleString() }}</div>
                                </div>

                                <div class="bg-white/50 group-hover:bg-white">
                                    <div class="font-bold">{{ parseFloat(day.romi).toFixed(2) }}%</div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>

            <div class="w-full h-[calc(100vh-100px)] border border-white relative mt-20">
                <div class="w-full">
                    <div class="absolute left-[10%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.spent">
                        <div class="title9 mb-1">РАСХОД</div>
                        <div class="box myflex p-2">
                            <div class="num1">{{ day?.spent?.toLocaleString() }}</div>
                        </div>
                        <div class="title9 mt-1">{{ plan?.spent?.toLocaleString() }}</div>
                    </div>

                    <div class="absolute left-[20%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.regs">
                        <div class="title9 mb-1">ЛИДЫ</div>
                        <div class="box myflex p-2">
                            <div class="num1">{{ day?.regs?.toLocaleString() }}</div>
                        </div>
                        <div class="title9 mt-1">{{ plan?.regs?.toLocaleString() }}</div>
                    </div>

                    <div class="absolute left-[30%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.clicks">
                        <div class="title9 mb-1">КЛИКИ</div>
                        <div class="box myflex p-2">
                            <div class="num1">{{ day?.clicks?.toLocaleString() }}</div>
                        </div>
                        <div class="title9 mt-1">{{ plan?.clicks?.toLocaleString() ?? "нет плана" }}</div>
                    </div>

                    <div class="absolute left-[40%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.cr_regs">
                        <div class="title9 mb-1">КОНВЕРСИЯ ЛЕНДА</div>
                        <div class="box myflex p-2">
                            <div class="num1">{{ parseInt(day?.cr_regs).toLocaleString() }}%</div>
                        </div>
                        <div class="title9 mt-1">{{ plan?.cr_regs?.toLocaleString() ?? "нет плана" }}</div>
                    </div>

                    <div class="absolute left-[60%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.web1">
                        <div class="title9 mb-1">ВЕБ 1</div>
                        <div class="box myflex p-2">
                            <div class="num1">{{ day?.web1?.toLocaleString() }}</div>
                        </div>
                        <div class="title9 mt-1">{{ plan?.web1?.toLocaleString() }}</div>
                    </div>
                </div>

                <div v-if="Object.keys(averages).length > 0" class="w-full">
                    <div class="absolute left-[10%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.avg_spent">
                        <div class="text-slate-400 latolight">{{ averages?.spent?.toLocaleString() }}</div>
                    </div>

                    <div class="absolute left-[20%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.avg_regs">
                        <div class="text-slate-400 latolight">{{ averages?.regs?.toLocaleString() }}</div>
                    </div>

                    <div class="absolute left-[30%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.avg_clicks">
                        <div class="text-slate-400 latolight">{{ averages?.clicks?.toLocaleString() }}</div>
                    </div>

                    <div class="absolute left-[40%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.avg_cr_regs">
                        <div class="text-slate-400 latolight">{{ averages?.cr_regs?.toLocaleString() }}</div>
                    </div>

                    <div class="absolute left-[60%] -translate-x-1/2 -translate-y-1/2 z-20 duration-500" :style="factStyle.web1">
                        <div class="text-slate-400 latolight">{{ averages?.clicks?.toLocaleString() }}</div>
                    </div>
                </div>

                <div class="absolute z-30 top-4 left-4 flex items-center gap-2">
                    <div class="btn-xs btn-blue" @click="daysToShow = 0">Сегодня</div>
                    <div class="btn-xs btn-blue" @click="daysToShow = 1">Вчера</div>
                    <div class="btn-xs btn-blue" @click="daysToShow = 2">Позавчера</div>
                </div>

                <div class="absolute z-30 top-4 right-4 flex items-center gap-2">
                    <div class="btn-xs btn-blue" @click="daysToCount = 0">Нет</div>
                    <div class="btn-xs btn-blue" @click="daysToCount = 2">2</div>
                    <div class="btn-xs btn-blue" @click="daysToCount = 7">7</div>
                    <div class="btn-xs btn-blue" @click="daysToCount = 14">14</div>
                    <div class="btn-xs btn-blue" @click="daysToCount = 30">30</div>
                    <!-- <div class="btn-xs btn-blue" @click="daysToCount = 90">90</div> -->
                    <!-- <div class="btn-xs btn-blue" @click="daysToCount = 365">365</div> -->
                </div>

                <div class="w-full h-fit absolute z-10 top-1/2 -translate-y-8">
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1920 200"
                        stroke="rgb(34 211 238)"
                        stroke-width="3"
                    >
                        <defs>
                            <linearGradient id="oceanGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop offset="0%" style="stop-color: #22d3ee; stop-opacity: 0.25" />
                                <stop offset="60%" style="stop-color: #22d3ee; stop-opacity: 0" />
                            </linearGradient>
                        </defs>

                        <path
                            fill="url(#oceanGradient)"
                            d="M-4.5,21.2c35,0,115.6-34.6,252.8-11.2s147.3,31.2,335.4,11.6S847.8,55,1042.7,33.2s291.5,16.5,443.9-11.4 c186.4-34.1,339.3-0.1,437.8-0.2l100.1,252.9l-2149.8,10.2L-4.5,21.2z"
                        >
                            <animate
                                attributeName="d"
                                dur="20s"
                                repeatCount="indefinite"
                                values="
                M-4.5,50c35,0,115.6-10,252.8,13.4s147.3,31.2,335.4,11.6S847.8,55,1042.7,33.2s291.5,16.5,443.9-11.4 c186.4-34.1,339.3-0.1,437.8-0.2l100.1,252.9l-2149.8,10.2L-4.5,21.2z;
                M-4.5,50c35,0,95.6-24.6,232.8-11.2s147.3,31.2,335.4,21.6S847.8,65,1042.7,43.2s291.5,26.5,443.9-1.4 c186.4-34.1,339.3-0.1,437.8-0.2l100.1,252.9l-2149.8,10.2L-4.5,21.2z;
                M-4.5,50c35,0,105.6-14.6,242.8-1.2s147.3,21.2,335.4,11.6S847.8,75,1042.7,53.2s291.5,26.5,443.9-1.4 c186.4-34.1,339.3-0.1,437.8-0.2l100.1,252.9l-2149.8,10.2L-4.5,21.2z;
                M-4.5,50c35,0,115.6-34.6,252.8-11.2s147.3,31.2,335.4,11.6S847.8,85,1042.7,63.2s291.5,26.5,443.9-1.4 c186.4-34.1,339.3-0.1,437.8-0.2l100.1,252.9l-2149.8,10.2L-4.5,21.2z;
                M-4.5,50c35,0,115.6-10,252.8,13.4s147.3,31.2,335.4,11.6S847.8,55,1042.7,33.2s291.5,16.5,443.9-11.4 c186.4-34.1,339.3-0.1,437.8-0.2l100.1,252.9l-2149.8,10.2L-4.5,21.2z"
                            />
                        </path>
                    </svg>
                </div>
                <div class="absolute left-0 w-full h-px bg-white" style="top: 10%"></div>
                <div class="absolute left-0 w-full h-px bg-white" style="top: 20%"></div>
                <div class="absolute left-0 w-full h-px bg-white" style="top: 30%"></div>
                <div class="absolute left-0 w-full h-px bg-white" style="top: 40%"></div>
                <div class="absolute left-0 w-full h-px bg-white" style="top: 50%"></div>
                <div class="absolute left-0 w-full h-px bg-white" style="top: 60%"></div>
                <div class="absolute left-0 w-full h-px bg-white" style="top: 70%"></div>
                <div class="absolute left-0 w-full h-px bg-white" style="top: 80%"></div>
                <div class="absolute left-0 w-full h-px bg-white" style="top: 90%"></div>
            </div>

            <!-- <div class="w-full my-4">{{ plan }}</div>
            <div class="w-full my-4">{{ averages }}</div> -->
        </div>
    </div>

    <div
        v-if="s.popup == 'orders'"
        class="fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] h-fit w-5/6 rounded z-50 p-4 bg-white overflow-y-scroll"
    >
        <div class="grid grid-cols-9 items-center bg-white gap-4 p-1 text-xs text-slate-400 border-b border-slate-200 uppercase">
            <div># ЗАКАЗА</div>
            <div>USER ID</div>
            <div class="col-span-3">НАЗВАНИЕ</div>
            <div class="text-right pr-2">ЦЕНА</div>
            <div class="grid grid-cols-5 items-center gap-2 text-center col-span-3">
                <div>source</div>
                <div>medium</div>
                <div>campaign</div>
                <div>content</div>
                <div>term</div>
            </div>
        </div>

        <div v-for="order in orders" :key="order">
            <div class="grid grid-cols-9 items-center gap-2 border-b border-slate-200 py-1 px-2 rounded text-xs hover:bg-cyan-100/50">
                <div class="flex flex-col justify-center">
                    <div># {{ order.number }}</div>
                    <div>{{ moment(order.create_at).format("DD.MM HH:mm") }}</div>
                </div>
                <div class="text-sm">{{ order.user_id }}</div>
                <div class="text-sm col-span-3">{{ order.title }}</div>
                <div class="text-sm text-right pr-2">{{ order.amount }}</div>
                <div class="grid grid-cols-5 items-center gap-2 text-center col-span-3">
                    <div>{{ order.utm_source }}</div>
                    <div>{{ order.utm_medium }}</div>
                    <div>{{ order.utm_campaign }}</div>
                    <div>{{ order.utm_content }}</div>
                    <div>{{ order.utm_term }}</div>
                </div>
            </div>
        </div>
    </div>

    <div
        v-if="s.popup == 'pays'"
        class="fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] h-fit w-5/6 rounded z-50 p-4 bg-white overflow-y-scroll"
    >
        <div class="grid grid-cols-12 items-center bg-white gap-4 p-1 text-xs text-slate-400 border-b border-slate-200 uppercase">
            <div># ЗАКАЗА</div>
            <div class="col-span-2">USER</div>
            <div class="col-span-3">НАЗВАНИЕ</div>
            <div class="text-right pr-2">ЦЕНА</div>
            <div class="text-right pr-2">КОМИССИЯ</div>
            <div class="text-right pr-2">ПОЛУЧЕНО</div>
            <div class="grid grid-cols-5 items-center gap-2 text-center col-span-3">
                <div>source</div>
                <div>medium</div>
                <div>campaign</div>
                <div>content</div>
                <div>term</div>
            </div>
        </div>

        <div v-for="pay in pays" :key="pay">
            <div class="grid grid-cols-12 items-center gap-2 border-b border-slate-200 py-1 px-2 rounded text-xs hover:bg-cyan-100/50">
                <div class="flex flex-col justify-center">
                    <div># {{ pay.order_id }}</div>
                    <div>{{ moment(pay.create_at).format("DD.MM HH:mm") }}</div>
                </div>
                <div class="flex flex-col justify-center col-span-2">{{ pay.user_id }} {{ pay.email }}</div>
                <div class="text-sm col-span-3">{{ pay.title }}</div>
                <div class="text-sm text-right pr-2">{{ pay }} {{ pay.currency }}</div>
                <div class="text-sm text-right pr-2">{{ pay.commission }} {{ pay.currency }}</div>
                <div class="text-sm text-right pr-2">{{ pay.amount }} {{ pay.currency }}</div>
                <div class="grid grid-cols-5 items-center gap-2 text-center col-span-3">
                    <div>{{ pay.utm_source }}</div>
                    <div>{{ pay.utm_medium }}</div>
                    <div>{{ pay.utm_campaign }}</div>
                    <div>{{ pay.utm_content }}</div>
                    <div>{{ pay.utm_term }}</div>
                </div>
            </div>
        </div>
    </div>

    <div
        v-if="s.popup == 'clients'"
        class="fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] h-fit w-5/6 rounded z-50 p-4 bg-white overflow-y-scroll"
    >
        <div class="text-center my-4 text-slate-400 uppercase">Расход на рекламу по каждому кабинету (без НДС)</div>
        <div class="grid grid-cols-11 items-center bg-white gap-4 p-1 mt-4 text-xs text-slate-400 border-b border-slate-200">
            <div>КАНАЛ</div>
            <div>АГЕНТСТВО</div>
            <div class="col-span-2">ID</div>
            <div class="col-span-2">КАБИНЕТ</div>
            <div class="text-right">БЮДЖЕТ</div>
            <div class="text-right">ПОКАЗЫ</div>
            <div class="text-right">КЛИКИ</div>
            <div class="text-right text-slate-400">CPM</div>
            <div class="text-right text-slate-400">CPA</div>
        </div>

        <div v-for="item in clients" :key="item.id" class="grid grid-cols-11 items-center bg-white gap-4 p-1 text-sm border-b border-slate-200">
            <a v-if="item.channel_id == 1" class="text-2xl text-cyan-600 font-bold cursor-pointer"><IVk /></a>
            <a v-if="item.channel_id == 2" class="text-2xl text-cyan-600 font-bold cursor-pointer p-1"><IVkAds :size="6" /></a>
            <a v-if="item.channel_id == 3" class="text-2xl text-blue-500 font-bold cursor-pointer"><IVk /></a>
            <a v-if="item.channel_id == 4" class="text-2xl text-cyan-600 font-bold cursor-pointer p-1"><IFb :size="6" /></a>
            <a v-if="item.channel_id == 5" class="text-2xl text-cyan-600 font-bold cursor-pointer p-1"><IYa :size="6" /></a>

            <div>{{ item.label }}</div>
            <div class="col-span-2 text-slate-400">{{ item.id }}</div>
            <div class="col-span-2">{{ item.name }}</div>
            <div class="text-right tabular-nums">{{ parseInt(item.spent).toLocaleString() }} ₽</div>
            <div class="text-right tabular-nums">{{ parseInt(item.views).toLocaleString() }}</div>
            <div class="text-right tabular-nums">{{ parseInt(item.clicks).toLocaleString() }}</div>
            <div v-if="item.views > 0" class="text-right tabular-nums text-slate-400">
                {{ parseInt((item.spent * 1000) / item.views)?.toLocaleString() }} ₽
            </div>
            <div v-else class="text-right tabular-nums text-slate-400">-</div>
            <div v-if="item.clicks > 0" class="text-right tabular-nums text-slate-400">
                {{ parseInt(item.spent / item.clicks)?.toLocaleString() }} ₽
            </div>
            <div v-else class="text-right tabular-nums text-slate-400">-</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

import FilterComponent from "@/components/Filter.vue";

export default {
    name: "DaysPage",

    components: { FilterComponent },

    data() {
        return {
            moment: moment,
            daysToShow: 1, // какой день показать на РнП (0: сегодня, 1: вчера, ...)
            daysToCount: 30, // рассичтать средний показатель за X дней
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "month", "profile"]),
        ...mapState(useClientStore, ["dashboard", "dashboardAll", "plan", "getData", "updateData", "getOrders", "getPays", "getClients"]),

        funnel() {
            if (!this.dashboard) return [];

            const adChannels = ["vk", "vkads", "market", "book", "yandex", "google", "tg", "tgads"];

            // временно названия для канала для фильтра
            let filetredCannel = null;
            if (this.s.channel_id) {
                if (this.s.channel_id == 1) filetredCannel = "vk";
                if (this.s.channel_id == 2) filetredCannel = "vkads";
                if (this.s.channel_id == 3) filetredCannel = "market";
                if (this.s.channel_id == 4) filetredCannel = "book";
                if (this.s.channel_id == 5) filetredCannel = "yandex";
                if (this.s.channel_id == 7) filetredCannel = "google";
                if (this.s.channel_id == 8) filetredCannel = "tg";
                if (this.s.channel_id == 9) filetredCannel = "tgads";
                if (this.s.channel_id == 10) filetredCannel = "youtube";
                if (this.s.channel_id == 100) filetredCannel = "base";
            }

            let empty = {
                spent: 0,
                views: 0,
                clicks: 0,
                regs: 0,
                regs_ad: 0,
                regs_none: 0,
                regs18to9: 0,
                regs10to17: 0,
                cr_regs: 0,
                cpa: 0,
                web1: 0,
                web2: 0,
                web3: 0,
                orderc: 0,
                orders: 0,
                orderc2: 0,
                orderc3: 0,
                ordercend: 0,
                cpo: 0,
                avg: 0,
                paymentc: 0,
                payments: 0,
                pay_cr: 0,
                pay_10: 0,
                romi: 0,
            };

            return this.dashboard.map((day, index, arr) => {
                const totals = { ...empty };
                totals.date = day.date;

                for (const channel in day.channels) {
                    // Фильтрация по каналу, если задано условие
                    if (filetredCannel && filetredCannel != channel) continue;

                    for (const key in day.channels[channel]) {
                        if (!totals[key]) totals[key] = 0;
                        totals[key] += parseInt(day.channels[channel][key]) || 0;

                        // Суммирование регистраций за первые часы сегодняшнего дня
                        if (key.startsWith("regs") && parseInt(key.slice(4)) >= 0 && parseInt(key.slice(4)) <= 9) {
                            totals.regs18to9 += parseInt(day.channels[channel][key]) || 0;
                        }

                        // Условие для суммирования регистраций за другие часы
                        if (key.startsWith("regs") && parseInt(key.slice(4)) >= 10 && parseInt(key.slice(4)) <= 17) {
                            totals.regs10to17 += parseInt(day.channels[channel][key]) || 0;
                        }

                        // Условие для суммирования регистраций из рекламы, если channel_id в массиве
                        if (adChannels.includes(channel) && key == "regs") {
                            totals.regs_ad += parseInt(day.channels[channel][key]) || 0;
                        }

                        // Условие для суммирования регистраций без канала
                        if (channel == "none" && key == "regs") {
                            totals.regs_none += parseInt(day.channels[channel][key]) || 0;
                        }
                    }
                }

                // Суммирование регистраций за последние часы вчерашнего дня
                if (index > 0) {
                    const previousDay = arr[index - 1];
                    for (const channel in previousDay.channels) {
                        if (filetredCannel && filetredCannel != channel) continue;

                        for (const key in previousDay.channels[channel]) {
                            if (key.startsWith("regs") && parseInt(key.slice(4)) >= 18 && parseInt(key.slice(4)) <= 23) {
                                totals.regs18to9 += parseInt(previousDay.channels[channel][key]) || 0;
                            }
                        }
                    }
                }

                if (totals.spent && totals.regs) {
                    totals.cr_regs = parseInt((totals.regs_ad / totals.clicks) * 100);
                    totals.cpa = parseInt(totals.spent / totals.regs);
                    totals.cpo = parseFloat(totals.orderc / totals.regs).toFixed(2);
                    totals.avg = parseFloat(totals.orders / totals.orderc).toFixed(2);
                    totals.pay_cr = parseFloat(totals.payments / totals.orders).toFixed(2);
                    totals.pay_10 = parseFloat(totals.payments / totals.regs).toFixed(2);

                    totals.romi = ((totals.payments - totals.spent) / totals.spent) * 100;
                }

                return totals;
            });
        },

        day() {
            if (!this.funnel) return {};

            return this.funnel[this.funnel.length - 1 - this.daysToShow];
        },

        averages() {
            if (this.daysToCount === 0) return {};

            const recentDays = this.funnel.slice(-(this.daysToCount + 1), -1); // Получаем последние N дней не считая сегодня

            const totalsSum = {
                spent: 0,
                views: 0,
                clicks: 0,
                regs: 0,
                cr_regs: 0,
                web1: 0,
                web2: 0,
                web3: 0,
                orderc: 0,
                orders: 0,
                paymentc: 0,
                payments: 0,
            };

            recentDays.forEach((day) => {
                Object.keys(totalsSum).forEach((key) => {
                    totalsSum[key] += day[key] || 0; // Суммируем значения
                });
            });

            const averages = {};
            recentDays.length &&
                Object.keys(totalsSum).forEach((key) => {
                    averages[key] = parseInt(totalsSum[key] / recentDays.length); // Вычисляем средние значения
                });

            return averages;
        },

        factStyle() {
            let avg = this.averages;

            function calculatePosition(actual, plan) {
                if (!plan || plan == 0) return "50%";
                return `${Math.min(Math.max(50 - (actual / plan - 1) * 50, 0), 100)}%`;
            }

            let spent = calculatePosition(this.day?.spent, this.plan.spent);
            let regs = calculatePosition(this.day?.regs, this.plan.regs);
            let clicks = calculatePosition(this.day?.clicks, this.plan.clicks);
            let cr_regs = calculatePosition(this.day?.cr_regs, this.plan.cr_regs);
            let web1 = calculatePosition(this.day?.web1, this.plan.web1);

            let avg_spent = calculatePosition(avg?.spent, this.plan.spent || this.day?.spent);
            let avg_regs = calculatePosition(avg?.regs, this.plan.regs || this.day?.regs);
            let avg_clicks = calculatePosition(avg?.clicks, this.plan.clicks || this.day?.clicks);
            let avg_cr_regs = calculatePosition(avg?.cr_regs, this.plan.cr_regs || this.day?.cr_regs);
            let avg_web1 = calculatePosition(avg?.web1, this.plan.web1 || this.day?.web1);

            return {
                spent: { top: spent },
                regs: { top: regs },
                clicks: { top: clicks },
                cr_regs: { top: cr_regs },
                web1: { top: web1 },

                avg_spent: { top: avg_spent },
                avg_regs: { top: avg_regs },
                avg_clicks: { top: avg_clicks },
                avg_cr_regs: { top: avg_cr_regs },
                avg_web1: { top: avg_web1 },
            };
        },

        // funnel() {
        //     if (!this.days) return {};

        //     let data = [];
        //     let week = {
        //         date: null,
        //         web1: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
        //         web2: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
        //         web3: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
        //         views: 0,
        //         clicks: 0,
        //         spent_nds: 0,
        //         regs_ad: 0,
        //         regs: 0,
        //         regs10: 0,
        //         ordersDozim: 0,
        //         orders: 0,
        //         orders_sum: 0,
        //         pays: 0,
        //         pays_sum: 0,
        //         romi: 0,
        //     };

        //     this.days.forEach((day) => {
        //         if (this.s.days) data.push(day);

        //         week.views = parseInt(week.views) + parseInt(day.views);
        //         week.clicks = parseInt(week.clicks) + parseInt(day.clicks);
        //         week.spent_nds = parseFloat(week.spent_nds + day.spent_nds);
        //         week.regs_ad = parseInt(week.regs_ad) + parseInt(day.regs_ad);
        //         week.regs = parseInt(week.regs) + parseInt(day.regs);
        //         week.regs10 = parseInt(week.regs10) + parseInt(day.regs10);

        //         week.web1 = parseInt(week.web1) + parseInt(day.web1);
        //         week.web1.live = parseInt(week.web1.live) + parseInt(day.web1.live);
        //         week.web1.repeat = parseInt(week.web1.repeat) + parseInt(day.web1.repeat);
        //         week.web1.come = parseInt(week.web1.come) + parseInt(day.web1.come);

        //         week.web2 = parseInt(week.web2) + parseInt(day.web2);
        //         week.web2.live = parseInt(week.web2.live) + parseInt(day.web2.live);
        //         week.web2.repeat = parseInt(week.web2.repeat) + parseInt(day.web2.repeat);
        //         week.web2.come = parseInt(week.web2.come) + parseInt(day.web2.come);
        //         week.web2.orders = parseInt(week.web2.orders) + parseInt(day.web2.orders);

        //         week.web3 = parseInt(week.web3) + parseInt(day.web3);
        //         week.web3.live = parseInt(week.web3.live) + parseInt(day.web3.live);
        //         week.web3.repeat = parseInt(week.web3.repeat) + parseInt(day.web3.repeat);
        //         week.web3.come = parseInt(week.web3.come) + parseInt(day.web3.come);
        //         week.web3.orders = parseInt(week.web3.orders) + parseInt(day.web3.orders);

        //         week.ordersDozim = parseInt(week.ordersDozim) + parseInt(day.ordersDozim);

        //         week.orders = parseInt(week.orders) + parseInt(day.orders);
        //         week.orders_sum = parseInt(week.orders_sum) + parseInt(day.orders_sum);

        //         week.pays = parseInt(week.pays) + parseInt(day.pays);
        //         week.pays_sum = parseInt(week.pays_sum) + parseInt(day.pays_sum);

        //         week.romi = parseInt(week.romi) + parseInt(day.romi);

        //         // итог за неделю
        //         if (moment(day.date).isoWeekday() == 7) {
        //             week.name = "НЕДЕЛЯ " + moment(day.date).isoWeek();
        //             week.cpm = parseFloat((week.spent_nds / week.views) * 1000).toFixed(2);
        //             week.ctr = parseFloat(week.clicks / (week.views / 100)).toFixed(2);
        //             week.cpc = parseInt(week.spent_nds / week.clicks);
        //             week.cpa = parseInt(week.spent_nds / week.regs10);

        //             week.web1.come = parseFloat(week.web1.come / 7).toFixed(2);
        //             week.web2.come = parseFloat(week.web2.come / 7).toFixed(2);
        //             week.web3.come = parseFloat(week.web3.come / 7).toFixed(2);

        //             week.web2.orders_cr = parseFloat((week.web2.orders / week.web2.live) * 100).toFixed(2);
        //             week.web3.orders_cr = parseFloat((week.web3.orders / week.web3.live) * 100).toFixed(2);

        //             week.ordersDozim_cr = (week.ordersDozim / (week.web2.orders + week.web3.orders)) * 100;

        //             week.cpo = parseFloat(week.orders / week.regs10).toFixed(2);
        //             week.avg = parseFloat(week.orders_sum / week.orders).toFixed(2);
        //             week.pay_cr = parseFloat(week.pays / week.orders).toFixed(2);
        //             week.pay_10 = parseFloat(week.pays / week.regs10).toFixed(2);

        //             week.romi = parseFloat(week.romi / 7).toFixed(2);

        //             data.push(week);

        //             week = {
        //                 date: null,
        //                 web1: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
        //                 web2: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
        //                 web3: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
        //                 views: 0,
        //                 clicks: 0,
        //                 spent_nds: 0,
        //                 regs_ad: 0,
        //                 regs: 0,
        //                 regs10: 0,
        //                 ordersDozim: 0,
        //                 orders: 0,
        //                 orders_sum: 0,
        //                 pays: 0,
        //                 pays_sum: 0,
        //                 romi: 0,
        //             };
        //         }
        //     });

        //     return data;
        // },
    },

    methods: {
        update() {
            this.updateData();
        },
    },

    mounted() {
        this.getData();
    },
};
</script>

<style>
.tbl div div {
    padding: 2px 8px;
    overflow: hidden;
    /* border-bottom: #eee solid 1px; */
}
.bb {
    border-bottom: #eee solid 1px;
}
</style>
