<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent />

            <div class="btn btn-blue py-1 px-3 my-1" @click="(this.s.select = null), this.setSettings(this.s), updateData()">ПОЛУЧИТЬ</div>
            <div class="btn py-1 px-3 my-1 text-xs" @click="clearSettings(), updateData()">ОЧИСТИТЬ</div>
        </div>

        <div class="w-full">
            <h2 class="w-full text-center text-slate-400 text-3xl font-bold">РАСХОДЫ ПО МЕСЯЦАМ</h2>

            <div class="mb-4 flex flex-wrap justify-start items-center gap-2 text-slate-500">
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData" value="spent" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData === 'spent' }">Расход</div>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData" value="views" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData === 'views' }">Просмотры</div>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData" value="clicks" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData === 'clicks' }">Клики</div>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData" value="CPM" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData === 'CPM' }">CPM</div>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData" value="CPC" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData === 'CPC' }">CPC</div>
                </label>
                <!-- <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData" value="ads" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData === 'ads' }">Объявления</div>
                </label> -->
            </div>

            <div class="grid grid-cols-6 gap-4 border-y border-slate-200 p-2 sticky top-12 bg-white z-10">
                <div class="text-slate-400">Дата</div>
                <div class="flex items-center gap-2 justify-self-end text-slate-400">
                    <div class="text-blue-500"><IVk :size="5" /></div>
                    vk
                </div>
                <div class="flex items-center gap-2 justify-self-end text-slate-400">
                    <div class="text-blue-600 p-1"><IVkAds :size="4" /></div>
                    vkads
                </div>
                <div class="flex items-center gap-2 justify-self-end text-slate-400">
                    <div class="text-pink-600"><IVkCamp :size="5" /></div>
                    market
                </div>
                <div class="flex items-center gap-2 justify-self-end text-slate-400">
                    <div><IFb :size="5" /></div>
                    book
                </div>
                <div class="flex items-center gap-2 justify-self-end text-slate-400">
                    <div class="p-1"><IYa :size="4" /></div>
                    yandex
                </div>
            </div>

            <div v-for="(dateData, date, index) in monthly" :key="date" class="py-px w-full group hover:py-1 relative duration-500">
                <template v-if="index === 0 || moment(date).format('YYYY') !== moment(Object.keys(monthly)[index - 1]).format('YYYY')">
                    <h2 class="font-bold m-2">{{ moment(date).format("YYYY") }}</h2>
                </template>

                <div class="grid grid-cols-6 items-center gap-4 bg-white/50 text-slate-500 hover:bg-cyan-50 hover:text-cyan-600 p-2 w-full rounded">
                    <h2>{{ month[parseInt(moment(date).format("MM"))] }}</h2>
                    <div v-for="(platformData, platform) in dateData" :key="platform" class="text-right tabular-nums">
                        <p v-if="selectedData === 'views'">{{ parseInt(platformData.views).toLocaleString() }}</p>
                        <p v-if="selectedData === 'clicks'">{{ parseInt(platformData.clicks).toLocaleString() }}</p>
                        <p v-if="selectedData === 'spent'">{{ parseInt(platformData.spent).toLocaleString() }}</p>
                        <p v-if="selectedData === 'CPM'">{{ calculateCPM(platformData) }}</p>
                        <p v-if="selectedData === 'CPC'">{{ calculateCPC(platformData) }}</p>
                        <p v-if="selectedData === 'ads'">{{ parseInt(platformData.ads).toLocaleString() }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useOwnerStore } from "@/store/owner";
import moment from "moment";

import FilterComponent from "../components/Filter.vue";

export default {
    name: "OwnerMonth",

    components: { FilterComponent },

    data() {
        return {
            selectedData: "spent",
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "month", "profile", "setPeriod", "setMonth", "setSettings", "clearSettings", "setChannel"]),
        ...mapState(useOwnerStore, ["monthly", "getMonthly"]),
    },

    methods: {
        updateData() {
            this.getMonthly();
        },

        calculateCPM(platformData) {
            const views = parseInt(platformData.views);
            const spent = parseInt(platformData.spent);
            if (views === 0) return 0;
            return ((spent / views) * 1000).toFixed(2);
        },

        calculateCPC(platformData) {
            const clicks = parseInt(platformData.clicks);
            const spent = parseInt(platformData.spent);
            if (clicks === 0) return 0;
            return (spent / clicks).toFixed(2);
        },
    },

    mounted() {
        this.getMonthly();
    },
};
</script>
