<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <polygon points="0 0 28 0 28 28 0 28"></polygon>
        <path
            d="M9.02631579,4.88 C5.42193111,4.88 2.5,7.80193111 2.5,11.4063158 C2.5,15.0191061 3.94497846,16.8273664 9.99322968,21.5315618 L12.7721188,23.69292 C13.4943397,24.2546474 14.5056603,24.2546474 15.2278812,23.69292 L18.0067703,21.5315618 C24.0550215,16.8273664 25.5,15.0191061 25.5,11.4063158 C25.5,7.80193111 22.5780689,4.88 18.9736842,4.88 C17.1021743,4.88 15.4359157,5.73037017 14,7.36654489 C12.5640843,5.73037017 10.8978257,4.88 9.02631579,4.88 Z M9.02631579,6.88 C10.5454982,6.88 11.914878,7.73751701 13.1775126,9.56334572 L13.3831345,9.86068453 C13.6187323,10.20137 14.0859021,10.2865603 14.4265875,10.0509625 C14.5009662,9.99952663 14.5654296,9.93506325 14.6168655,9.86068453 L14.8224874,9.56334572 L14.8224874,9.56334572 C16.085122,7.73751701 17.4545018,6.88 18.9736842,6.88 C21.4734994,6.88 23.5,8.90650061 23.5,11.4063158 C23.5,14.1865911 22.3625441,15.6100146 16.7788891,19.9528574 L14,22.1142156 L11.2211109,19.9528574 C5.63745588,15.6100146 4.5,14.1865911 4.5,11.4063158 C4.5,8.90650061 6.52650061,6.88 9.02631579,6.88 Z"
            id="↳-Icon-Color"
            fill="currentColor"
            fill-rule="nonzero"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "IVkLike",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
