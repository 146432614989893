<template>
    <div class="bg-white rounded p-4 mb-4">
        <div class="flex justify-between items-center gap-4">
            <div>ОТЧЕТ ПО ГРУППАМ</div>
            <div class="btn btn-blue gap-2" @click="exportGroups()">СКАЧАТЬ <IcoBig name="export" /></div>
        </div>
    </div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-12 items-center text-xs text-slate-400 gap-4 py-2 px-4 border-b border-slate-200 uppercase">
            <div>КАНАЛ</div>
            <div class="col-span-2">КАБИНЕТ</div>
            <div>ID</div>
            <div class="col-span-2">НАЗВАНИЕ</div>
            <div>ID КАМПАНИИ</div>
            <div>ГЕО</div>
            <div class="col-span-2">ПОЛ</div>
            <div>ВОЗРАСТ</div>
            <div>СТАТУС</div>
        </div>

        <RecycleScroller class="h-[calc(100vh-200px)]" :items="report" :item-size="48" key-field="id" v-slot="{ item }">
            <div
                class="h-12 overflow-hidden grid grid-cols-12 items-center gap-4 px-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-700"
            >
                <div v-if="item.channel_id == 1" class="text-cyan-600"><IVk :size="8" /></div>
                <div v-if="item.channel_id == 2" class="text-cyan-600 p-1"><IVkAds :size="6" /></div>
                <div v-if="item.channel_id == 3" class="text-pink-600 p-1"><IVkCamp :size="6" /></div>
                <div v-if="item.channel_id == 4" class=""><IFb :size="8" /></div>
                <div v-if="item.channel_id == 5" class="p-1"><IYa :size="6" /></div>

                <div class="overflow-hidden col-span-2">{{ item.client_name }}</div>
                <div class="overflow-hidden">{{ item.id }}</div>
                <div class="overflow-hidden col-span-2">{{ item.name }}</div>
                <div class="overflow-hidden">{{ item.campaign_id }}</div>
                <div class="overflow-hidden">{{ item.geo.join(", ") }}</div>
                <div class="overflow-hidden col-span-2">{{ item.sex.join(", ") }}</div>
                <div class="overflow-hidden">{{ convertAge(item.age) }}</div>
                <div class="overflow-hidden">{{ item.status }}</div>
            </div>
        </RecycleScroller>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useManagerStore } from "@/store/manager";

import moment from "moment";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { utils, writeFileXLSX } from "xlsx";

export default {
    name: "ManagerReportGroup",

    components: { RecycleScroller },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "profile"]),
        ...mapState(useManagerStore, ["report", "getReportGroup"]),
    },

    methods: {
        convertAge(arr) {
            let result = [];
            let start = arr[0];
            let end = arr[0];

            for (let i = 1; i < arr.length; i++) {
                if (arr[i] === end + 1) {
                    end = arr[i];
                } else {
                    if (start === end) {
                        result.push(start.toString());
                    } else {
                        result.push(start + "-" + end);
                    }
                    start = end = arr[i];
                }
            }

            if (start === end) {
                result.push(start.toString());
            } else {
                result.push(start + "-" + end);
            }

            return result.join(", ");
        },

        exportGroups() {
            let wscols = [
                { wch: 10 }, // КАНАЛ
                { wch: 15 }, // ID
                { wch: 15 }, // ID КАБИНЕТА
                { wch: 20 }, // КАБИНЕТ
                { wch: 15 }, // ID КАМПАНИИ
                { wch: 20 }, // UTM_CAMPAIGN
                { wch: 20 }, // НАЗВАНИЕ
                { wch: 20 }, // ГЕО
                { wch: 20 }, // ПОЛ
                { wch: 20 }, // ВОЗРАСТ
                { wch: 50 }, // ИНТЕРЕСЫ
                { wch: 50 }, // СЕГМЕНТ+
                { wch: 50 }, // СЕГМЕНТ-
                { wch: 50 }, // ОПИСАНИЕ
                { wch: 10 }, // СТАТУС
            ];

            let list = [];
            this.report.forEach((item) => {
                let object = {
                    channel_id: item.channel_id,
                    id: item.id,
                    client_id: item.client_id,
                    client_name: item.client_name,
                    campaign_id: item.campaign_id,
                    utm_campaign: item.utm_campaign,
                    name: item.name,
                    geo: item.geo,
                    sex: item.sex,
                    age: item.age,
                    interests: item.interests,
                    segmentsPlus: item.segmentsPlus,
                    segmentsMinus: item.segmentsMinus,
                    objective: item.objective,
                    status: item.status,
                };

                if (item.channel_id == 1) object.channel_id = "Vk";
                if (item.channel_id == 2) object.channel_id = "Vkads";
                if (item.channel_id == 3) object.channel_id = "Vk Market";
                if (item.channel_id == 4) object.channel_id = "Facebook";
                if (item.channel_id == 5) object.channel_id = "Yandex";

                if (item.geo) object.geo = JSON.stringify(item.geo.join(", "));
                if (item.sex) object.sex = JSON.stringify(item.sex.join(", "));
                if (item.age) object.age = JSON.stringify(this.convertAge(item.age));
                if (item.interests) object.interests = JSON.stringify(item.interests.join(", "));
                if (item.segmentsPlus) object.segmentsPlus = JSON.stringify(item.segmentsPlus.join(", "));
                if (item.segmentsMinus) object.segmentsMinus = JSON.stringify(item.segmentsMinus.join(", "));

                list.push(object);
            });

            let worksheet = utils.json_to_sheet(list);
            worksheet = utils.sheet_add_aoa(
                worksheet,
                [
                    [
                        "КАНАЛ",
                        "ID",
                        "ID КАБИНЕТА",
                        "КАБИНЕТ",
                        "ID КАМПАНИИ",
                        "UTM_CAMPAIGN",
                        "НАЗВАНИЕ",
                        "ГЕО",
                        "ПОЛ",
                        "ВОЗРАСТ",
                        "ИНТЕРЕСЫ",
                        "СЕГМЕНТ+",
                        "СЕГМЕНТ-",
                        "ОПИСАНИЕ",
                        "СТАТУС",
                    ],
                ],
                {
                    origin: "A1",
                }
            );
            worksheet["!cols"] = wscols;
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "groups");
            writeFileXLSX(workbook, "groups.xlsx", { compression: true });
        },
    },

    mounted() {
        this.getReportGroup();
    },
};
</script>
