<template>
    <div class="col-span-4">
        <div class="flex flex-col min-h-60 bg-white rounded p-8">
            <span>НАСТРОЙКИ</span>
            <span class="title">СОЗДАЙ ПРОЕКТЫ</span>
            <span class="title">ДОБАВЬ КАБИНЕТЫ и ТОКЕНЫ</span>
            <span class="title">ДОБАВЬ ТАРГЕТОЛОГОВ (имя, фото, utm)</span>
            <span class="title">СКАЧАЙ СТАТИСТИКУ</span>

            <span class="title">ДОБАВЬ GETCOURSE и ТОКЕН</span>
            <span class="title">СКАЧАЙ СТАТИСТИКУ</span>
            <span class="title">УКАЖИ ТИПЫ EVENT</span>
            <span class="title"></span>
        </div>

        <div class="min-h-60 bg-white rounded p-8">
            <span class="title">КАБИНЕТЫ</span>

            <div class="grid grid-cols-10 items-center bg-white gap-4 p-1 mt-4 text-xs text-slate-500 border-b border-slate-200">
                <div>КАНАЛ</div>
                <div>АГЕНСТВО</div>
                <div class="col-span-2">ID</div>
                <div class="col-span-2">КАБИНЕТ</div>
                <div class="text-right">БЮДЖЕТ</div>
                <div class="text-right">ПОКАЗЫ</div>
                <div class="text-right">КЛИКИ</div>
                <div class="text-right">ОБЪЯВЛЕНИЯ</div>
            </div>

            <div
                v-for="item in cabinets.clients"
                :key="item.id"
                class="grid grid-cols-10 items-center bg-white gap-4 p-1 text-sm border-b border-slate-200"
            >
                <a
                    v-if="item.channel_id == 1"
                    class="text-2xl text-cyan-600 font-bold cursor-pointer"
                    target="_blank"
                    :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                >
                    <IVk />
                </a>

                <a
                    v-if="item.channel_id == 2"
                    class="text-2xl text-cyan-600 font-bold cursor-pointer p-1"
                    target="_blank"
                    href="https://ads.vk.com/hq/dashboard"
                >
                    <IVkAds :size="6" />
                </a>

                <a
                    v-if="item.channel_id == 3"
                    class="text-2xl text-blue-500 font-bold cursor-pointer"
                    target="_blank"
                    :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                >
                    <IVk />
                </a>

                <a
                    v-if="item.channel_id == 4"
                    class="text-2xl text-cyan-600 font-bold cursor-pointer p-1"
                    target="_blank"
                    href="https://ads.vk.com/hq/dashboard"
                >
                    <IFb :size="6" />
                </a>
                <a
                    v-if="item.channel_id == 5"
                    class="text-2xl text-cyan-600 font-bold cursor-pointer p-1"
                    target="_blank"
                    href="https://ads.vk.com/hq/dashboard"
                >
                    <IYa :size="6" />
                </a>

                <div>{{ item.label }}</div>

                <div class="col-span-2 text-slate-500">{{ item.id }}</div>

                <div class="col-span-2">{{ item.name }}</div>

                <div class="text-right">{{ item.spent.toLocaleString() }} ₽</div>

                <div class="text-right text-slate-500">{{ item.views.toLocaleString() }}</div>

                <div class="text-right text-slate-500">{{ item.clicks.toLocaleString() }}</div>

                <div class="text-right text-slate-500">{{ item.ads_active }}</div>
            </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 min-h-60 bg-white rounded p-8">
            <div class="flex flex-col justify-center items-center">
                <span class="title">БЮДЖЕТ</span>
                <span class="num text-3xl md:text-5xl">{{ 1 }}</span>
            </div>

            <div class="flex flex-col justify-center items-center">
                <span class="title">ПРОДАЖИ</span>
                <span class="num text-3xl md:text-5xl">{{ 1 }}</span>
            </div>

            <div class="flex flex-col justify-center items-center">
                <span class="title">РЕЗУЛЬТАТ</span>
                <span class="num text-3xl md:text-5xl"> {{ 1 }}x</span>
                <span class="title"> ROI {{ 1 }} % </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAnalyticsStore } from "@/store/analytics";

import moment from "moment";

export default {
    name: "AdsPage",

    data() {
        return {
            currentUser: [],
            month: { 1: "Янв", 2: "Фев", 3: "Мар", 4: "Апр", 5: "Май", 6: "Июн", 7: "Июл", 8: "Авг", 9: "Сен", 10: "Окт", 11: "Ноя", 12: "Дек" },
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "profile", "setPeriod", "setMonth", "setSettings"]),
        ...mapState(useAnalyticsStore, ["cabinets", "getAds"]),
    },

    mounted() {
        this.getAds();
    },
};
</script>

<style>
.funnel-step {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: white;
    font-weight: bold;
}
</style>
