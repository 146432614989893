<template>
    <div class="w-full" v-for="item in mo" :key="item">
        {{ item.date }}
        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">КАБИЕНТЫ</div>
            <div class="bg-white p-1">{{ item.cabinets[1] }}</div>
            <div class="bg-white p-1">{{ item.cabinets[2] }}</div>
            <div class="bg-white p-1">{{ item.cabinets[3] }}</div>
            <div class="bg-white p-1">{{ item.cabinets[4] }}</div>
            <div class="bg-white p-1">{{ item.cabinets[5] }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">КЛИЕНТЫ</div>
            <div class="bg-white p-1">{{ item.clients[1] }}</div>
            <div class="bg-white p-1">{{ item.clients[2] }}</div>
            <div class="bg-white p-1">{{ item.clients[3] }}</div>
            <div class="bg-white p-1">{{ item.clients[4] }}</div>
            <div class="bg-white p-1">{{ item.clients[5] }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">КАМПАНИИ</div>
            <div class="bg-white p-1">{{ item.campaigns[1] }}</div>
            <div class="bg-white p-1">{{ item.campaigns[2] }}</div>
            <div class="bg-white p-1">{{ item.campaigns[3] }}</div>
            <div class="bg-white p-1">{{ item.campaigns[4] }}</div>
            <div class="bg-white p-1">{{ item.campaigns[5] }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">ГРУППЫ</div>
            <div class="bg-white p-1">{{ item.groups[1] }}</div>
            <div class="bg-white p-1">{{ item.groups[2] }}</div>
            <div class="bg-white p-1">{{ item.groups[3] }}</div>
            <div class="bg-white p-1">{{ item.groups[4] }}</div>
            <div class="bg-white p-1">{{ item.groups[5] }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">ОБЪЯВЛЕНИЯ</div>
            <div class="bg-white p-1">{{ item.ads[1] }}</div>
            <div class="bg-white p-1">{{ item.ads[2] }}</div>
            <div class="bg-white p-1">{{ item.ads[3] }}</div>
            <div class="bg-white p-1">{{ item.ads[4] }}</div>
            <div class="bg-white p-1">{{ item.ads[5] }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">ТЕКСТЫ</div>
            <div class="bg-white p-1">{{ item.text[1] }}</div>
            <div class="bg-white p-1">{{ item.text[2] }}</div>
            <div class="bg-white p-1">{{ item.text[3] }}</div>
            <div class="bg-white p-1">{{ item.text[4] }}</div>
            <div class="bg-white p-1">{{ item.text[5] }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">КАРТИНКИ</div>
            <div class="bg-white p-1">{{ item.pictures[1] }}</div>
            <div class="bg-white p-1">{{ item.pictures[2] }}</div>
            <div class="bg-white p-1">{{ item.pictures[3] }}</div>
            <div class="bg-white p-1">{{ item.pictures[4] }}</div>
            <div class="bg-white p-1">{{ item.pictures[5] }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">UTM</div>
            <div class="bg-white p-1">{{ item.utm[1] }}</div>
            <div class="bg-white p-1">{{ item.utm[2] }}</div>
            <div class="bg-white p-1">{{ item.utm[3] }}</div>
            <div class="bg-white p-1">{{ item.utm[4] }}</div>
            <div class="bg-white p-1">{{ item.utm[5] }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">РАСХОД</div>
            <div class="bg-white p-1">{{ parseInt(item.spent[1]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.spent[2]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.spent[3]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.spent[4]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.spent[5]).toLocaleString() }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">ПРОСМОТРЫ</div>
            <div class="bg-white p-1">{{ parseInt(item.views[1]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.views[2]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.views[3]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.views[4]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.views[5]).toLocaleString() }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 text-right">
            <div class="bg-white p-1 col-span-2 text-left">КЛИКИ</div>
            <div class="bg-white p-1">{{ parseInt(item.clicks[1]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.clicks[2]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.clicks[3]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.clicks[4]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.clicks[5]).toLocaleString() }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 mt-8 text-right">
            <div class="bg-white p-1 col-span-2 text-left">РЕГИСТРАЦИИ</div>
            <div class="bg-white p-1">{{ parseInt(item.reg[6]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.reg[14]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.reg[16]).toLocaleString() }}</div>
        </div>

        <div class="w-full grid grid-cols-7 gap-1 mb-1 mt-8 text-right">
            <div class="bg-white p-1 col-span-2 text-left">ГЕТКУРС</div>
            <div class="bg-white p-1">{{ parseInt(item.getCourse["deal"]).toLocaleString() }}</div>
            <div class="bg-white p-1">{{ parseInt(item.getCourse["payment"]).toLocaleString() }}</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAdminStore } from "@/store/admin";

export default {
    name: "AdminMonitor",

    data() {
        return {
            mo: [
                {
                    date: "2024-05-14",
                    cabinets: { 1: 8, 2: 2, 3: 1, 4: 1, 5: 25, 6: 1, 14: 1, 15: 1 },
                    clients: { 1: 3315, 2: 854, 3: 118, 4: 11, 5: 25 },
                    campaigns: { 1: 118210, 2: 11648, 3: 562, 4: 122, 5: 225 },
                    groups: { 2: 83463, 5: 2344 },
                    ads: { 1: 6400146, 2: 146013, 3: 32925, 4: 556, 5: 41076 },
                    text: { 1: 5851829, 2: 146013, 3: 5750, 4: 388, 5: 41076 },
                    pictures: { 1: 14821, 2: 10510, 3: 87, 4: 44, 5: 0 },
                    utm: { 1: 813620, 2: 135769, 3: 11718, 4: 21, 5: 39863 },
                    spent: { 1: 556563985, 2: 117162827, 3: 99899979, 4: 29496097, 5: 24938474 },
                    views: { 1: 1473683087, 2: 1103958841, 3: 1350139209, 4: 83497367, 5: 105645608 },
                    clicks: { 1: 5582171, 2: 5197751, 3: 987808, 4: 1701153, 5: 928070 },
                    reg: { 6: 390326, 14: 60848, 16: 1111 },
                    getCourse: { payment: 4731, deal: 184429 },
                    clients_active_week: 3739,
                    clients_active_day: 358,
                    clients_active_hours: 216,
                    clients_active_new: 5,
                    queue_wait: 0,
                    queue_errors: 4,
                    queue_done: 2512,
                    cabinetsDiff: { 1: 8, 2: 2, 3: 1, 4: 1, 5: 25, 6: 1, 14: 1, 15: 1 },
                    clientsDiff: { 1: 3315, 2: 854, 3: 118, 4: 11, 5: 25 },
                    campaignsDiff: { 1: 118210, 2: 11648, 3: 562, 4: 122, 5: 225 },
                    groupsDiff: { 2: 83463, 5: 2344 },
                    adsDiff: { 1: 6400146, 2: 146013, 3: 32925, 4: 556, 5: 41076 },
                    textDiff: { 1: 5851829, 2: 146013, 3: 5750, 4: 388, 5: 41076 },
                    utmDiff: { 1: 813620, 2: 135769, 3: 11718, 4: 21, 5: 39863 },
                    spentDiff: { 1: "55656398560", 2: "11716282736", 3: "9989997900", 4: "2949609795", 5: "2493847447" },
                    viewsDiff: { 1: "1473683087", 2: "1103958841", 3: "1350139209", 4: "83497367", 5: "105645608" },
                    clicksDiff: { 1: "5582171", 2: "5197751", 3: "987808", 4: "1701153", 5: "928070" },
                    regDiff: { 6: 390326, 14: 60848, 16: 1111 },
                    getCourseDiff: { payment: 4731, deal: 184429 },
                    picturesDiff: { 1: 14821, 2: 10510, 3: 87, 4: 44, 5: 0 },
                },
            ],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useAdminStore, ["monitor", "getMonitor"]),
    },

    mounted() {
        // this.getMonitor();
    },
};
</script>
