import { defineStore } from "pinia";
import { size } from "../store_utils/localstorage";
import moment from "moment";

export const useMainStore = defineStore("main", {
    state: () => ({
        alert: {},
        s: {
            findme: null,
            noProject: false,
            project_id: null,
            project_name: null,
            channel_id: null,
            module_id: null,
            webinar_names: {},
            compact: true,
            days: true,
            nds: true,
            status: null,
            month: moment().startOf("month").format("YYYY-MM-DD"),
            start: null,
            end: null,
            utms: { source: [], medium: [], campaign: [], content: [], term: [] },
            filter: { spent: true, regs: true, webs: true, orders: true, pays: true },
            popup: null,
            clear: null,
            view: null,
            select: null,
            request: false,
            activeMenu: null,
            theme: "light",
            sortBy: "romi",
            sortDesc: true,
        },

        popupHistory: [],

        filter: {},

        roles: {
            An: "Админ",
            Or: "Собственник",
            Bo: "Бюджетный отдел",
            Lr: "Юрист",
            Pm: "Менеджер",
            Tg: "Таргетолог",
            Cl: "Клиент",
        },

        month: {
            1: "Январь",
            2: "Февраль",
            3: "Март",
            4: "Апрель",
            5: "Май",
            6: "Июнь",
            7: "Июль",
            8: "Август",
            9: "Сентябрь",
            10: "Октябрь",
            11: "Ноябрь",
            12: "Декабрь",
        },
        monthMin: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
        weekday: ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        routes: [],

        targetologs: {
            0: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a0.png" },
            4: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a1.png", utm: "fps3" },
            8: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a2.png", utm: "ft1" },
            9: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a3.png", utm: "fps9" },
            10: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a4.png", utm: "tst5" },
            12: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a5.png", utm: "tst6" },
            13: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a6.png", utm: "tst1" },
            14: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a7.png", utm: "tst2" },
            15: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a8.png", utm: "ft4" },
            16: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a9.png", utm: "fps10" },
            17: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a10.png", utm: "f32" },
            18: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a11.png", utm: "fps6" },
            19: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a12.png", utm: "ft5" },
            21: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a13.png", utm: "f2t2" },
            22: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a18.png", utm: "f2t10" },
            23: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a15.png", utm: "fp5" },
            24: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a16.png", utm: "f2t3" },
            25: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a17.png", utm: "fp1" },
            29: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a14.png", utm: "fp3" },
            57: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a19.png", utm: "ft6" },
            91: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a20.png", utm: "f17" },
            93: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a21.png", utm: "f4" },
            110: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a22.png", utm: "fmt3" },
            119: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a23.png", utm: "fmt12" },
            122: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a24.png", utm: "fmt15" },
            127: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a25.png", utm: "fmt20" },
            128: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a26.png", utm: "fmt21" },
            136: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a27.png", utm: "fmt29" },
            137: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a28.png", utm: "f2t5" },
            171: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a29.png", utm: "tst4" },
            178: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a30.png", utm: "fmt51" },
            181: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a31.png", utm: "fmt55" },
            191: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a32.png", utm: "fmt66" },
            204: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a33.png", utm: "fmt73" },
            246: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a34.png", utm: "fmt81" },
            360: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a35.png", utm: "fp12" },
            361: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a36.png", utm: "krs4" },
            410: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a37.png", utm: "tgf2" },
            411: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a38.png", utm: "tgf3" },
            412: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a39.png", utm: "mp3" },
            413: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a40.png", utm: "art3f" },
            414: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a41.png", utm: "art4f" },
            415: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a42.png", utm: "art6f" },
            416: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a43.png", utm: "tgaf4" },
            417: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a44.png", utm: "art2f" },
            418: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a45.png", utm: "rsia_3" },
            419: { pic: "https://statapi.timekraken.ru/uploads/users/mini/a46.png", utm: "rsia_4" },
        },
    }),

    actions: {
        // Получить размер занимаемых данных в localStorage
        getLocalStorageSize() {
            return size();
        },

        // загрузить настройки из localStorage
        loadData() {
            const s = localStorage.getItem("s");
            if (s && s !== "undefined") {
                this.s.request = false;
                this.s = JSON.parse(s);
                if (!this.s.month) this.s.month = moment().startOf("month").format("YYYY-MM-DD");
                if (!this.s.start) this.s.start = moment().startOf("month").format("YYYY-MM-DD");
                if (!this.s.end) this.s.end = moment().format("YYYY-MM-DD");
            }
        },

        setAlert(alert) {
            this.alert = alert;
        },

        setSettings(s) {
            // console.log("s", s);
            this.s = s;
            localStorage.setItem("s", JSON.stringify(s));
        },

        clearSettings() {
            this.s.findme = null;
            this.s.project_id = null;
            this.s.project_name = null;
            this.s.noProject = false;
            this.s.channel_id = null;
            this.s.module_id = null;
            this.s.webinar_names = {};
            this.s.compact = false;
            this.s.days = true;
            this.s.nds = true;
            this.s.status = null;
            this.s.month = moment().startOf("month").format("YYYY-MM-DD");
            this.s.start = moment().startOf("month").format("YYYY-MM-DD");
            this.s.end = moment().format("YYYY-MM-DD");
            this.s.select = null;
            this.s.utms = { source: [], medium: [], campaign: [], content: [], term: [] };
            this.s.filter = { spent: true, regs: true, webs: true, orders: true, pays: true };
            this.s.clear = null;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setCompact() {
            this.s.compact = !this.s.compact;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setSDays() {
            this.s.days = !this.s.days;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setSNds() {
            this.s.nds = !this.s.nds;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setRequest(status) {
            this.s.request = status;
        },

        setPopup(popup) {
            if (this.s.popup) this.popupHistory.push(this.s.popup);
            this.s.popup = popup;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        closePopup() {
            if (this.popupHistory.length > 0) {
                this.s.popup = this.popupHistory.pop();
            } else {
                this.s.popup = null;
            }
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setActiveMemu(active) {
            this.s.activeMenu = active;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setView(view) {
            this.s.view = view;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setMonth(month) {
            let year = moment().format("YYYY");
            if (this.s.start) year = moment(this.s.start).format("YYYY");
            let date = moment(year + "-" + month + "-01").format("YYYY-MM-DD");
            this.s.month = month;
            this.s.start = moment(date).format("YYYY-MM-01");
            this.s.end = moment(date).endOf("month").format("YYYY-MM-DD");
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setPeriod(data) {
            this.s.month = null;
            this.s.start = data.start;
            this.s.end = data.end;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setSort(sortBy) {
            if (this.s.sortBy == sortBy) this.s.sortDesc = !this.s.sortDesc;
            this.s.sortBy = sortBy;
            localStorage.setItem("s", JSON.stringify(this.s));
            // console.log("sort", this.s.sortBy, this.s.sortDesc);
        },

        setProjectId(project = false) {
            if (!project) {
                this.s.project_id = null;
                this.s.project_name = null;
            } else {
                this.s.project_id = project.id;
                this.s.project_name = project.name;
            }
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        toggleNoProject() {
            this.s.noProject = !this.s.noProject;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setChannel(id = false) {
            if (id) {
                this.s.channel_id = id;
            } else {
                this.s.channel_id = null;
            }
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setFilter(filter) {
            this.filter = filter;
        },

        // setWebinar(name) {
        //     if (!this.s.webinar_names.includes(name)) {
        //         this.s.webinar_names.push(name);
        //     } else {
        //         const index = this.s.webinar_names.indexOf(name);
        //         if (index !== -1) this.s.webinar_names.splice(index, 1);
        //     }
        //     localStorage.setItem("s", JSON.stringify(this.s));
        // },

        // Вебинары
        addWebinarGroup(group_name) {
            this.s.webinar_names[group_name] = [];
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        delWebinarGroup(group_name) {
            delete this.s.webinar_names[group_name];
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setWebinar(group, name) {
            if (!(group in this.s.webinar_names)) {
                this.$set(this.s.webinar_names, group, []);
            }
            if (!this.s.webinar_names[group].includes(name)) {
                this.s.webinar_names[group].push(name);
            } else {
                const index = this.s.webinar_names[group].indexOf(name);
                if (index !== -1) this.s.webinar_names[group].splice(index, 1);
            }
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setTheme(theme) {
            this.s.theme = theme;
            localStorage.setItem("s", JSON.stringify(this.s));
        },
    },
});
