<template>
    <div class="bg-white rounded p-8 pt-4">
        <h2 class="mb-4 text-slate-400">Аккаунты</h2>

        <div class="grid grid-cols-8 items-center text-xs text-slate-400 gap-4 py-2 px-4 border-b border-slate-200 uppercase">
            <div>ID</div>
            <div class="col-span-4">Название</div>
            <div>Кабинеты</div>
            <div>Пользователи</div>
            <div class="text-right tabular-nums">Открут</div>
        </div>

        <div
            v-for="account in list"
            :key="account.id"
            @click="setAdminAccount(account), setAdminMenu('Clients')"
            class="grid grid-cols-8 items-center gap-4 py-2 px-4 border-b border-slate-200 uppercase cursor-pointer"
        >
            {{ account.id }}
            <div class="col-span-4">{{ account.name }}</div>
            <div>{{ account.clients.length }}</div>
            <div>{{ account.users.length }}</div>
            <div class="text-right tabular-nums">{{ account.spent.toLocaleString() }} ₽</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAdminStore } from "@/store/admin";

import moment from "moment";

export default {
    name: "AccountsComponent",

    props: {
        accounts: {
            type: Array,
        },
    },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s"]),
        ...mapState(useAdminStore, ["adminAccount", "setAdminAccount", "setAdminMenu"]),

        list() {
            if (!this.accounts) return {};

            const result = this.accounts.map((account) => {
                const totalSpent = account.clients.reduce((sum, client) => sum + parseInt(client.spent), 0);
                account.spent = totalSpent;
                return account;
            });

            return result;
        },
    },
};
</script>
