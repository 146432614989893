<template>
    <div class="flex items-center cursor-pointer group relative -mt-2">
        <span class="text-cyan-500 text-lg">
            <IcoBig name="i" :size="3" />
        </span>
        <span
            class="absolute z-20 bottom-full left-1/2 transform -translate-x-1/2 w-fit min-w-40 max-w-80 bg-cyan-500 text-white text-xs py-1 px-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 mb-2"
        >
            {{ tip }}
        </span>
    </div>
</template>

<script>
export default {
    name: "ToolTip",

    props: {
        tip: {
            type: String,
            required: true,
        },
    },
};
</script>
