import { useProfileStore as profileStore } from "@/store/profile";

const authService = {
    async isLogged(to) {
        // console.log("Current URL:", to.path);

        // Проверяем, авторизован ли пользователь             // console.log("Нет профиля, получаем...");
        if (!profileStore().profile?.id || !profileStore().menu || !localStorage.getItem("AUTH")) await profileStore().getProfile();

        // Проверяем снова, авторизован ли пользователь после получения профиля
        if (!profileStore().profile?.id || !profileStore().menu || !localStorage.getItem("AUTH")) return false;

        // Проверяем, если путь - один из перечисленных, то считаем пользователя авторизованным
        if (["/", "/login", "/logout", "/profile"].includes(to.path)) return true;

        // Проверяем, есть ли у пользователя разрешение на текущий маршрут
        const hasPermission = profileStore().menu.some((item) => item.url === to.path);

        if (hasPermission) return true;

        return false;
    },
};

export default authService;
