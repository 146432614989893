<template>
    <h2 class="w-full text-center text-slate-400 text-3xl">НАСТРОЙКИ ПРОЕКТА</h2>

    <div class="w-full flex justify-center items-center gap-4 mt-4 text-slate-400 text-sm cursor-pointer">
        <div :class="{ 'text-cyan-500': s.view == 'info' }" class="hover:text-cyan-500" @click="setView('info')">Инфо</div>
        <div :class="{ 'text-cyan-500': s.view == 'channels' }" class="hover:text-cyan-500" @click="setView('channels')">Каналы</div>
        <div :class="{ 'text-cyan-500': s.view == 'convertUtm' }" class="hover:text-cyan-500" @click="setView('convertUtm')">Конвертор UTM</div>
        <div :class="{ 'text-cyan-500': s.view == 'genUtm' }" class="hover:text-cyan-500" @click="setView('genUtm')">Генератор UTM</div>
        <div :class="{ 'text-cyan-500': s.view == 'hands' }" class="hover:text-cyan-500" @click="setView('hands')">Расход</div>
        <div :class="{ 'text-cyan-500': s.view == 'events' }" class="hover:text-cyan-500" @click="setView('events')">События</div>
        <div :class="{ 'text-cyan-500': s.view == 'team' }" class="hover:text-cyan-500" @click="setView('team')">Команда</div>
    </div>

    <template v-if="s.view == 'info'">
        <div class="text-center text-2xl text-slate-600">
            <div class="text-xs text-slate-100" v-if="settings?.info?.client_type == 'person'">Физическое лицо</div>
            <div class="text-xs text-slate-100" v-if="settings?.info?.client_type == 'individual'">Индивидуальный предприниматель</div>
            <div class="text-xs text-slate-100" v-if="settings?.info?.client_type == 'legal'">Юридическое лицо</div>
            {{ settings?.info?.name }}
            <div class="w-full text-center text-xs text-slate-100">{{ moment(settings?.info?.date).format("DD.MM.YYYY HH:mm:ss") }}</div>
        </div>

        <div class="flex flex-col p-4 text-center text-sm text-slate-600">
            <div class="grid grid-cols-3 border-b border-slate-200 mb-4">
                <div class="flex flex-col items-start text-left mb-4 col-span-2">
                    <label class="w-full text-xs text-slate-400">Полное юридическое наименование</label>
                    {{ settings?.info?.client_name }}
                </div>

                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">Сокращенное наименование</label>
                    {{ settings?.info?.client_short }}
                </div>

                <div class="flex flex-col items-start text-left mb-4 col-span-2">
                    <label class="w-full text-xs text-slate-400">Адрес</label>
                    {{ settings?.info?.address }}
                </div>
                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">Email</label>
                    {{ settings?.info?.email }}
                </div>

                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">ИНН</label>
                    {{ settings?.info?.inn }}
                </div>

                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">ОГРН/ОГРНИП</label>
                    {{ settings?.info?.ogrn }}
                </div>

                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">Телефон</label>
                    {{ settings?.info?.phone }}
                </div>
            </div>

            <!-- <div class="grid grid-cols-4 border-b border-slate-200 pb-4 mb-4 gap-4">
                <div class="col-span-4 text-left">Контактное лицо</div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Контактное лицо</label>
                    {{ settings?.info?.contact_name }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Контактный телефон</label>
                    {{ settings?.info?.contact_phone }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Контактный email</label>
                    {{ settings?.info?.contact_email }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Контактный telegram</label>
                    {{ settings?.info?.contact_tl }}
                </div>
            </div>

             <div
                v-if="
                    settings?.info?.subagent_type || settings?.info?.subagent_name || settings?.info?.subagent_inn || settings?.info?.subagent_phone
                "
                class="grid grid-cols-4 border-b border-slate-200 pb-4 mb-4 gap-4"
            >
                <div class="col-span-4 text-left">Субагент</div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Тип субагента</label>
                    <div v-if="settings?.info?.subagent_type == 'person'">Физическое лицо</div>
                    <div v-if="settings?.info?.subagent_type == 'individual'">Индивидуальный предприниматель</div>
                    <div v-if="settings?.info?.subagent_type == 'legal'">Юридическое лицо</div>
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Название субагента</label>
                    {{ settings?.info?.subagent_name }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Инн субагента</label>
                    {{ settings?.info?.subagent_inn }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Телефон субагента</label>
                    {{ settings?.info?.subagent_phone }}
                </div>
            </div>

            <div v-else class="text-left border-b border-slate-200 pb-4 mb-4">Субагента нет</div>

            <div class="grid grid-cols-4 border-b border-slate-200 pb-4 mb-4 gap-4">
                <div class="col-span-4 text-left">Контракт</div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Номер контракта</label>
                    {{ settings?.info?.contract_number }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Дата контракта</label>
                    {{ settings?.info?.contract_date }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">ОКВЭД</label>
                    {{ settings?.info?.okved }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">НДС</label>
                    <div v-if="settings?.info?.with_vat == 2">Без НДС</div>
                    <div v-if="settings?.info?.with_vat == 3">С НДС</div>
                </div>

                <div class="flex flex-col items-start text-left col-span-2">
                    <label class="w-full text-xs text-slate-400">Тип контракта</label>
                    {{ settings?.info?.contract_type }}
                </div>

                <div class="flex flex-col items-start text-left col-span-2">
                    <label class="w-full text-xs text-slate-400">Предмет договора</label>
                    {{ settings?.info?.contract_object }}
                </div>
            </div> -->
        </div>

        <!-- v-if="['Or', 'Bs', 'Lr'].includes(profile.role)" -->
        <!-- @click="editProject(info)" -->
        <!-- <div class="flex justify-center px-8 py-4 w-full"><div class="btn btn-blue text-sm py-2 px-4">Редактировать</div></div> -->
    </template>

    <template v-if="s.view == 'channels'">
        <!-- <h2 class="w-full text-center text-slate-400 text-xl mt-8 mb-4">РАСХОД</h2> -->

        <div @click="setPopup('CreateChannel')" class="flex justify-end items-center gap-2 text-teal-500 cursor-pointer">
            <IcoBig name="plus" :size="5" />Создать
        </div>

        <div
            v-for="item in settings?.channels"
            :key="item"
            class="w-full rounded-xl relative my-1 text-slate-500 text-sm group hover:my-2 duration-500"
        >
            <div class="bg-white/50 h-10 rounded-xl grid grid-cols-11 justify-center items-center gap-4 hover:bg-white">
                <div>{{ item.channel_id }}</div>
            </div>
        </div>
    </template>

    <template v-if="s.view == 'hands'">
        <!-- <h2 class="w-full text-center text-slate-400 text-xl mt-8 mb-4">РАСХОД</h2> -->

        <div @click="setPopup('CreateHandStat')" class="flex justify-end items-center gap-2 text-teal-500 cursor-pointer">
            <IcoBig name="plus" :size="5" />Создать
        </div>

        <template v-if="settings?.hands">
            <RecycleScroller class="h-[calc(100vh-110px)]" :items="settings?.hands" :item-size="44" key-field="id" v-slot="{ item }">
                <div class="w-full rounded-xl h-10 relative my-1 text-slate-500 text-sm group hover:my-2 duration-500">
                    <div class="bg-white/50 h-10 rounded-xl grid grid-cols-11 justify-center items-center gap-4 hover:bg-white">
                        <div class="flex items-center gap-2 pl-2">
                            {{ moment(item.date).format("DD.MM") }}

                            <div class="text-cyan-600">
                                <div v-if="item.channel_id == 1"><IVk :size="6" /></div>
                                <div v-if="item.channel_id == 2"><IVkAds :size="6" /></div>
                                <div v-if="item.channel_id == 3"><IVkCamp :size="6" /></div>
                                <div v-if="item.channel_id == 4"><IFb :size="6" /></div>
                                <div v-if="item.channel_id == 5"><IYa :size="6" /></div>
                                <div v-if="item.channel_id == 9"><ITgads :size="6" /></div>
                                <div v-if="item.channel_id == 10"><IYoutube :size="6" /></div>
                                <div v-if="item.channel_id == 100"><IBase :size="6" /></div>
                            </div>
                        </div>

                        <!-- <div class="flex items-center gap-1">
                                <img
                                    :src="targetologs[item.tg_id]?.pic"
                                    class="shrink-0 h-8 mt-2 opacity-50 group-hover:h-[40px] group-hover:mt-0 group-hover:opacity-100 duration-500"
                                />
                                <span class="text-xs">{{ targetologs[item.tg_id]?.utm }}</span>
                            </div> -->

                        <div>#{{ item.id }}</div>

                        <div>{{ item.user_id }}</div>

                        <div class="latobold text-right text-cyan-500">{{ parseInt(item.spent).toLocaleString() }}</div>

                        <div class="col-span-4 h-6 overflow-hidden">{{ item.name }}</div>
                    </div>
                </div>
            </RecycleScroller>
        </template>
    </template>

    <template v-if="s.view == 'events'">
        <!-- <h2 class="w-full text-center text-slate-400 text-xl mt-8 mb-4">СОБЫТИЯ</h2> -->

        <div class="flex justify-between items-center gap-4 h-12 my-4 px-4 bg-white rounded">
            <div class="flex gap-2">
                <div class="count h-8">{{ parseInt(allEvents?.length).toLocaleString() }}</div>
            </div>

            <div class="flex items-center gap-2 text-cyan-600 cursor-pointer">
                <div @click="filters.status = null" class="cursor-pointer">ВСЕ</div>
                <div @click="filters.status = 1" class="w-4 h-4 rounded-full !bg-teal-500 cursor-pointer"></div>
                <div @click="filters.status = 0" class="w-4 h-4 rounded-full !bg-slate-400 cursor-pointer"></div>
            </div>

            <!-- <button @click="clearFilters()" class="bg-slate-400 text-white py-1 px-2 text-xs rounded">Очистить</button> -->
        </div>

        <div v-for="event in allEvents" :key="event.id" class="w-full rounded-xl relative my-1 text-slate-500 text-sm group hover:my-2 duration-500">
            <div class="bg-white/50 h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 px-4 hover:bg-white">
                <div>#{{ event.id }}</div>
                <div class="col-span-6">{{ event.title }}</div>
                <div>
                    <div v-if="event.type_id == 1">Регистрация</div>
                    <div v-if="event.type_id == 2">Вебинар</div>
                    <div v-if="event.type_id == 3">Продукт</div>
                </div>
                <div>{{ event.sum }}</div>
                <div class="flex items-center gap-2">
                    <div
                        class="w-2 h-2 shrink-0 rounded-full"
                        :class="{ '!bg-teal-500': event.status == 1, '!bg-slate-200': event.status == 0 }"
                    ></div>
                    <div v-if="event.status == 0">Выключено</div>
                    <div v-if="event.status == 1">Активно</div>
                </div>
            </div>
            <template v-if="event.items && event.items.length > 0">
                <div
                    v-for="subItem in event.items"
                    :key="subItem.id"
                    class="w-full rounded-xl h-10 relative my-1 text-slate-500 text-sm group hover:my-2 duration-500 pl-10"
                >
                    <div class="bg-white/50 h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 px-4 hover:bg-white">
                        <div>#{{ subItem.id }}</div>
                        <div class="col-span-6">{{ subItem.title }}</div>
                        <div>
                            <div v-if="event.type_id == 1">Регистрация</div>
                            <div v-if="event.type_id == 2">Вебинар</div>
                            <div v-if="event.type_id == 3">Продукт</div>
                        </div>
                        <div>{{ event.sum }}</div>
                        <div class="flex items-center gap-2">
                            <div
                                class="w-2 h-2 shrink-0 rounded-full"
                                :class="{ '!bg-teal-500': event.status == 1, '!bg-slate-200': event.status == 0 }"
                            ></div>
                            <div v-if="event.status == 0">Выключено</div>
                            <div v-if="event.status == 1">Активно</div>
                        </div>
                    </div>
                </div>
            </template>

            <!-- <div v-if="(event.items = 1 && event.items.length)" class="pl-10">
                    <RecycleScroller :items="event.items" :item-size="44" key-field="id" v-slot="{ subItem }">
                        <template v-if="subItem">
                            <div class="w-full rounded-xl h-10 relative my-1 text-slate-500 text-sm group hover:my-2 duration-500 pl-10">
                                <div class="bg-gray-100 h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 hover:bg-gray-200">
                                    <div>{{ subItem.id }}</div>
                                    <div class="col-span-6">{{ subItem.title }}</div>
                                    <div>{{ subItem.type_id }}</div>
                                    <div>{{ subItem.sum }}</div>
                                    <div>{{ subItem.status }}</div>
                                </div>
                            </div>
                        </template>
                    </RecycleScroller>
                </div> -->
        </div>
    </template>

    <template v-if="s.view == 'targetologs'">
        <h2 class="w-full text-center text-slate-400 text-xl mt-8 mb-4">ТАРГЕТОЛОГИ</h2>

        <template v-if="settings?.targetologs">
            <RecycleScroller class="h-[calc(100vh-110px)]" :items="settings?.targetologs" :item-size="44" key-field="id" v-slot="{ item }">
                <div class="w-full rounded-xl h-10 relative my-1 text-slate-500 text-sm group hover:my-2 duration-500">
                    <div class="bg-white/50 h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 hover:bg-white">
                        <div>{{ item }}</div>
                    </div>
                </div>
            </RecycleScroller>
        </template>
    </template>

    <template v-if="s.view == 'convertUtm'">
        <h2 class="w-full text-center text-slate-400 text-xl mt-8 mb-4">UTM</h2>

        <div class="mt-8 mb-2 text-slate-400">utm_source</div>
        <div class="w-full flex flex-wrap items-center gap-1 mb-1 text-xs">
            <div v-for="item in settings?.utms?.source" :key="item" class="bg-slate-50 py-1 px-2 rounded cursor-pointer">
                {{ item }}
            </div>
        </div>

        <div class="mt-8 mb-2 text-slate-400">utm_medium</div>
        <div class="w-full flex flex-wrap items-center gap-1 mb-1 text-xs">
            <div v-for="item in settings?.utms?.medium" :key="item" class="bg-slate-50 py-1 px-2 rounded cursor-pointer">
                {{ item }}
            </div>
        </div>

        <div class="mt-8 mb-2 text-slate-400">utm_campaign</div>
        <div class="w-full flex flex-wrap items-center gap-1 mb-1 text-xs">
            <div v-for="item in settings?.utms?.campaign" :key="item" class="bg-slate-50 py-1 px-2 rounded cursor-pointer">
                {{ item }}
            </div>
        </div>

        <div class="mt-8 mb-2 text-slate-400">utm_content</div>
        <div class="w-full flex flex-wrap items-center gap-1 mb-1 text-xs">
            <div v-for="item in settings?.utms?.content" :key="item" class="bg-slate-50 py-1 px-2 rounded cursor-pointer">
                {{ item }}
            </div>
        </div>

        <div class="mt-8 mb-2 text-slate-400">utm_term</div>
        <div class="w-full flex flex-wrap items-center gap-1 mb-1 text-xs">
            <div v-for="item in settings?.utms?.term" :key="item" class="bg-slate-50 py-1 px-2 rounded cursor-pointer">
                {{ item }}
            </div>
        </div>
    </template>

    <template v-if="s.popup == 'CreateHandStat'"><CreateHandStat /></template>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import CreateHandStat from "../components/CreateHandStat.vue";

export default {
    name: "ClientSettings",

    components: { RecycleScroller, CreateHandStat },

    data() {
        return {
            moment: moment,
            hand: {},
            filters: {
                date: "",
                channel_id: null,
                tg_id: null,
                event_id: null,
                minSum: null,
                maxSum: null,
                status: null,
            },
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "setView"]),
        ...mapState(useClientStore, ["settings", "getProjectSettings"]),

        allEvents() {
            if (!this.settings?.events) return [];

            // Фильтрация по статусу
            const filteredEvents = this.settings.events.filter((item) => {
                return this.filters.status === null || item.status === this.filters.status;
            });

            // Получаем корневые элементы (события с parent_id = 0)
            let data = filteredEvents.filter((item) => item.parent_id === 0);

            // Добавляем дочерние элементы к корневым элементам
            data.forEach((rootItem) => {
                rootItem.items = filteredEvents.filter((item) => item.parent_id === rootItem.id);
            });

            return data;
        },

        allUtms() {
            if (!this.settings?.utms) return [];
            let data = [];

            if (this.settings?.utms?.source) data.push(...this.settings?.utms?.source);
            if (this.settings?.utms?.medium) data.push(...this.settings?.utms?.medium);
            if (this.settings?.utms?.campaign) data.push(...this.settings?.utms?.campaign);
            if (this.settings?.utms?.content) data.push(...this.settings?.utms?.content);
            if (this.settings?.utms?.term) data.push(...this.settings?.utms?.term);

            return data;
        },
    },

    methods: {
        clearFilters() {
            this.filters = { date: "", channel_id: null, event_id: null, tg_id: null, minSum: null, maxSum: null, status: null };
            this.tgDropdown = false;
        },
    },

    mounted() {
        this.getProjectSettings();
    },
};
</script>
