<template>
    <div class="min-h-[500px] flex justify-center items-center">
        <div class="flex flex-col justify-center max-w-80">
            <h1 class="flex flex-col slide">
                <!-- <span class="animated-gradient-text text-[86px] latobold">Statle</span> -->

                <div class="flex gap-2 relative text-lg">
                    <div class="flex gap-px">
                        <span class="text-cyan-400">Stat</span>
                        <span class="text-slate-400/80">istics</span>
                    </div>
                    <transition name="slide" mode="out-in">
                        <div class="flex gap-px" :key="currentIndex">
                            <span class="text-slate-400/80">{{ dic[currentIndex] }}</span>
                            <span class="text-cyan-400">le</span>
                        </div>
                    </transition>
                </div>

                <span
                    class="text-[86px] latobold text-cyan-400"
                    style="
                        background: linear-gradient(to top right, #00cae9, #a5f3fc);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    "
                    >Statle</span
                >
                <span class="text-xl text-slate-500">Your key to data-driven success</span>
            </h1>

            <h2 class="text-slate-400 mt-4">
                Integrate data from over 20 sources and build dynamic visualizations to make smarter decisions every day
            </h2>
        </div>

        <!-- style="background-image: radial-gradient(at 25% 25%, rgb(249 168 212), rgb(255 76 152))" -->
        <!-- style="background-image: radial-gradient(at 25% 25%, rgb(131 238 255), rgb(36 194 223))" -->

        <div class="w-60 h-60 relative">
            <div
                @click="popup = 'login'"
                class="absolute flex justify-center items-center rounded-full cursor-pointer opacity-80 bg-pink-300 text-pink-600 w-60 h-60"
                ref="circle1"
            >
                Log In
            </div>
            <div
                class="absolute flex justify-center items-center rounded-full cursor-pointer opacity-80 left-32 bg-cyan-300 text-cyan-600 w-32 h-32"
                ref="circle2"
            >
                Sign Up
            </div>
            <div
                class="absolute flex justify-center items-center rounded-full cursor-pointer opacity-80 top-40 left-32 bg-teal-300 w-24 h-24"
                ref="circle3"
            >
                <!-- Empty -->
            </div>
        </div>
    </div>

    <div v-if="popup == 'login'" class="w-full h-full fixed top-0 left-0 z-1 flex justify-center items-center backdrop-blur-sm">
        <LoginFrom />
    </div>
</template>

<script>
import LoginFrom from "../components/Login.vue";

export default {
    name: "MainPage",

    components: { LoginFrom },

    data() {
        return {
            popup: null,
            dic: ["Reliab", "Flexib", "Measurab", "Usab", "Accessib", "Scaleab", "Understandab", "Comparab", "Handleab", "Predictab"],
            // dic: ["Tab", "Examp", "Sca", "Cyc", "Samp", "Princip", "Variab", "Modu", "Ru", "Ta", "Puzz", "Schedu", "Bund", "Ro", "Hand", "Mirac"],
            currentIndex: 0,
        };
    },

    methods: {
        changeText() {
            this.currentIndex = (this.currentIndex + 1) % this.dic.length;
        },

        startAnimation() {
            const circles = [this.$refs.circle1, this.$refs.circle2, this.$refs.circle3];

            const generateTranslateValue = () => (Math.random() > 0.5 ? 1 : -1) * Math.random() * 50 + "px";

            const setCirclePosition = (circle, isInitial = false) => {
                if (!circle) return;
                const translateX = generateTranslateValue();
                const translateY = generateTranslateValue();
                circle.style.transition = "transform 5s linear";
                circle.style.transform = `translate(${translateX}, ${translateY})`;

                if (isInitial) {
                    setTimeout(() => {
                        circle.style.transform = `translate(${generateTranslateValue()}, ${generateTranslateValue()})`;
                    }, 50);
                }
            };

            this.$nextTick(() => circles.forEach((circle) => setCirclePosition(circle, true)));
            setInterval(() => circles.forEach((circle) => setCirclePosition(circle)), 5000);
        },
    },

    mounted() {
        setInterval(this.changeText, 2000);
        this.startAnimation();
    },
};
</script>

<style scoped>
/* @keyframes gradientAnimation {
    0%,
    10% {
        background-position: 0% 50%;
    }
    30%,
    40% {
        background-position: 100% 50%;
    }
    60%,
    70% {
        background-position: 200% 50%;
    }
    90%,
    100% {
        background-position: 0% 50%;
    }
}

.animated-gradient-text {
    background: linear-gradient(135deg, #22d3ee, #a5f3fc, #f472b6, #5eead4, #22d3ee);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientAnimation 10s ease infinite;
} */

.slide-enter-active,
.slide-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}
.slide-enter-from,
.slide-leave-to {
    opacity: 0;
}
.slide-enter-from {
    transform: translateY(-25%); /* Начало анимации сверху */
}
.slide-leave-to {
    transform: translateY(25%); /* Конец анимации вниз */
}
</style>
