import { defineStore } from "pinia";
import API from "../services/api";
import router from "@/services/router";
import { useMainStore as mainStore } from "./main";

export const useManagerStore = defineStore("manager", {
    state: () => ({
        cabinets: [],
        projects: [],
        project: {},
        clients: [],
        targetologs: [],
        report: [],
    }),

    actions: {
        // получаем все проекты агентства
        async getProjects() {
            const res = await API.GET("manager/project/list");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.projects = res.data.projects;
        },

        // получаем данные из HUB
        async getProject() {
            if (!mainStore().s.project_id) return null;
            const res = await API.GET("manager/project/one", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                project_id: mainStore().s.project_id,
            });
            this.project = res.data.project;
        },

        pickProject(project) {
            mainStore().setProjectId(project);
            this.project = project;
            router.push("/manager/project");
        },

        // получаем всех клиентов агентства
        async getClients() {
            const res = await API.GET("manager/client/list");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.clients = res.data.clients;
        },

        // получаем всех таргетологов агентства
        async getTargetologs() {
            const res = await API.GET("manager/stat/targetologs", { srt: mainStore().s.start, end: mainStore().s.end });
            this.targetologs = res.data.targetologs;
        },

        // получаем отчет групп
        async getReportGroup() {
            const res = await API.GET("manager/report/group", { project_id: mainStore().s.project_id });
            console.log("getReportGroup", res.data);
            this.report = res.data.report;
        },

        async addClient(client) {
            console.log("addClient", this.adminAccount.id, client);
            if (!this.adminAccount.id) return;

            const formData = new FormData();
            formData.append("account_id", this.adminAccount.id);
            formData.append("client_id", client.id);
            formData.append("cabinet_id", client.cabinet_id);
            formData.append("channel_id", client.channel_id);
            formData.append("label", client.label);
            formData.append("name", client.name);

            const res = await API.POST("account/admin/add-client", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.admin = res.data.admin;
        },

        // добавить клиентов в проект
        async addProjectToClients(data) {
            // Извлекаем только поля id и channel_id из каждого элемента
            const clientsData = data.clients.map((client) => ({ id: client.id, channel_id: client.channel_id }));

            let formData = new FormData();
            formData.append("project_id", data.project_id);
            formData.append("clients", JSON.stringify(clientsData));

            const res = await API.POST("hub/add-project-to-clients", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.projects = res.data.projects;
            this.clients = res.data.clients;
            router.push("/admin/clients");
        },

        async unProjectClient(data) {
            // Извлекаем только поля id и channel_id из каждого элемента
            const clientsData = data.clients.map((client) => ({ id: client.id, channel_id: client.channel_id }));

            let formData = new FormData();
            formData.append("clients", JSON.stringify(clientsData));

            const res = await API.POST("hub/un-project-clients", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.projects = res.data.projects;
            this.clients = res.data.clients;
            router.push("/admin/clients");
        },
    },
});
