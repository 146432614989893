<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
            d="M23.4,6.3L12.3,1c-0.2-0.1-0.4-0.1-0.6,0L0.6,6.3C0.4,6.4,0.2,6.6,0.2,6.9c0,0.3,0.1,0.5,0.4,0.6l11.1,5.3
			c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l11.1-5.3c0.2-0.1,0.4-0.3,0.4-0.6C23.8,6.6,23.6,6.4,23.4,6.3z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
            d="M12,17.5c-0.1,0-0.3,0-0.4-0.1L0.5,12.1c-0.4-0.2-0.6-0.7-0.4-1.2c0.2-0.4,0.7-0.6,1.2-0.4L12,15.6l10.8-5.1
			c0.4-0.2,1,0,1.2,0.4c0.2,0.4,0,1-0.4,1.2l-11.1,5.3C12.3,17.5,12.1,17.5,12,17.5z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
            d="M12,21.9c-0.1,0-0.3,0-0.4-0.1L0.5,16.6c-0.4-0.2-0.6-0.7-0.4-1.2C0.3,15,0.8,14.8,1.2,15L12,20.1L22.7,15
			c0.4-0.2,1,0,1.2,0.4c0.2,0.4,0,1-0.4,1.2l-11.1,5.3C12.3,21.9,12.1,21.9,12,21.9z"
        />
    </svg>
</template>

<script>
export default {
    name: "IBase",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
