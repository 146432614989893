// Функция для обработки ключей
export function processKeys(data) {
    const keyMap = {
        ch: "channel_id",
        ct: "client_id",
        sp: "spent",
        vi: "views",
        cl: "clicks",
        re: "regs",
        r0: "regs0",
        r1: "regs1",
        r2: "regs2",
        r3: "regs3",
        r4: "regs4",
        r5: "regs5",
        r6: "regs6",
        r7: "regs7",
        r8: "regs8",
        r9: "regs9",
        r10: "regs10",
        r11: "regs11",
        r12: "regs12",
        r13: "regs13",
        r14: "regs14",
        r15: "regs15",
        r16: "regs16",
        r17: "regs17",
        r18: "regs18",
        r19: "regs19",
        r20: "regs20",
        r21: "regs21",
        r22: "regs22",
        r23: "regs23",
        w1: "web1",
        w2: "web2",
        w3: "web3",
        w4: "web4",
        w5: "web5",
        w6: "web6",
        oc: "orderc",
        os: "orders",
        oc2: "orderc2",
        os2: "orders2",
        oc3: "orderc3",
        os3: "orders3",
        oce: "ordercend",
        ose: "ordersend",
        pc: "paymentc",
        ps: "payments",
    };

    return process(data, keyMap);
}

function renameKeys(obj, keyMap) {
    const newObj = {};
    for (const key in obj) {
        const newKey = keyMap[key] || key; // Если ключа нет в keyMap, оставляем его без изменений
        newObj[newKey] = obj[key];
    }
    return newObj;
}

function process(data, keyMap) {
    for (const day in data) {
        for (const section in data[day]) {
            if (section != "date") {
                for (const item in data[day][section]) {
                    data[day][section][item] = renameKeys(data[day][section][item], keyMap);
                }
            }
        }
    }
    return data;
}
