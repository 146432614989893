<template>
    <div class="fixed top-0 left-0 w-full h-full z-50 p-4 bg-slate-100">
        <div class="text-xl text-center text-slate-400">СПИСОК ПОКУПАТЕЛЕЙ</div>
        <div class="absolute top-2 right-2 text-slate-400 hover:text-slate-500 cursor-pointer" @click="closePopup()">
            <IcoBig name="close" :size="6" />
        </div>

        <div class="flex justify-between items-center gap-4 h-12 my-4">
            <div class="flex gap-2">
                <div class="count h-8">{{ parseInt(total.count).toLocaleString() }}</div>
            </div>

            <input v-model="filters.date" class="input h-8 w-2/12" type="date" placeholder="Дата" />

            <div class="flex items-center gap-2 h-8 w-3/12 text-cyan-600 cursor-pointer">
                <div @click="filters.channel_id = null">ВСЕ</div>
                <div @click="filters.channel_id = 1"><IVk :size="6" /></div>
                <div @click="filters.channel_id = 2"><IVkAds :size="6" /></div>
                <div @click="filters.channel_id = 3"><IVkCamp :size="6" /></div>
                <div @click="filters.channel_id = 4"><IFb :size="6" /></div>
                <div @click="filters.channel_id = 5"><IYa :size="6" /></div>
                <div @click="filters.channel_id = 9"><ITgads :size="6" /></div>
                <div @click="filters.channel_id = 10"><IYoutube :size="6" /></div>
                <div @click="filters.channel_id = 100"><IBase :size="6" /></div>
            </div>

            <div class="relative group w-8 h-8 shrink-0 cursor-pointer" v-if="uniqueTgIds.length">
                <img
                    v-if="parseInt(filters.tg_id)"
                    :src="targetologs[filters.tg_id]?.pic"
                    class="w-8 h-8 shrink-0"
                    @click="tgDropdown = !tgDropdown"
                />
                <img v-else :src="targetologs[0]?.pic" class="w-8 h-8 shrink-0" @click="tgDropdown = !tgDropdown" />
                <div v-if="tgDropdown" class="absolute top-8 -left-20 w-60 z-40 h-fit bg-white p-2 grid grid-cols-4">
                    <template v-for="(tgId, index) in uniqueTgIds" :key="index">
                        <div v-if="tgId > 0" @click="(tgDropdown = false), (filters.tg_id = tgId)">
                            <img :src="targetologs[tgId]?.pic" class="w-12 h-12 aspect-square cursor-pointer" :alt="tgId" />
                        </div>
                    </template>
                </div>
            </div>

            <input v-model="filters.find" class="input h-8 w-6/12" type="text" placeholder="Имя, email" />

            <select v-model="filters.country" class="input py-1 h-8 w-3/5">
                <option value="">ВСЕ</option>
                <option v-for="country in uniqueCountries" :key="country" :value="country">{{ country }}</option>
            </select>
            <button @click="clearFilters" class="bg-slate-400 text-white py-1 px-2 text-xs rounded">Очистить</button>
        </div>

        <div class="grid grid-cols-12 items-center gap-4 px-4 text-xs text-slate-400">
            <span>РЕГИСТРАЦИЯ</span>
            <span>КАНАЛ</span>
            <!-- <span>ID</span> -->
            <span class="col-span-3">ФИО</span>
            <span class="col-span-3">EMAIL</span>
            <span>СТРАНА</span>
            <span class="grid grid-cols-2"><span>РЕГ</span> <span>ВЕБ</span></span>
            <span class="text-center">ЗАКАЗЫ</span>
            <span class="text-center">ОПЛАТЫ</span>
        </div>

        <template v-if="usersF">
            <RecycleScroller class="h-[calc(100vh-110px)]" :items="usersF" :item-size="44" key-field="id" v-slot="{ item }">
                <div @click="pickUser(item)" class="w-full rounded-xl h-10 relative my-1 text-slate-500 group hover:my-2 duration-500">
                    <div class="bg-white/50 h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 hover:bg-white">
                        <div class="flex items-center gap-2 px-2">
                            <div class="flex items-center gap-2 px-2 latolight">{{ moment(item.date).format("DD.MM") }}</div>

                            <div class="grid grid-cols-2 items-center text-cyan-600">
                                <div v-if="item.channel_id == 1"><IVk :size="6" /></div>
                                <div v-if="item.channel_id == 2"><IVkAds :size="6" /></div>
                                <div v-if="item.channel_id == 3"><IVkCamp :size="6" /></div>
                                <div v-if="item.channel_id == 4"><IFb :size="6" /></div>
                                <div v-if="item.channel_id == 5"><IYa :size="6" /></div>
                                <div v-if="item.channel_id == 9"><ITgads :size="6" /></div>
                                <div v-if="item.channel_id == 10"><IYoutube :size="6" /></div>
                                <div v-if="item.channel_id == 100"><IBase :size="6" /></div>
                            </div>
                        </div>

                        <div class="flex items-center gap-1">
                            <img
                                :src="targetologs[item.tg_id]?.pic"
                                class="shrink-0 h-8 mt-2 opacity-50 group-hover:h-[40px] group-hover:mt-0 group-hover:opacity-100 duration-500"
                            />
                            <span class="text-xs">{{ targetologs[item.tg_id]?.utm }}</span>
                        </div>

                        <!-- <div class="latolight">{{ item.id }}</div> -->

                        <div class="flex flex-col col-span-3">{{ item.name }} {{ item.secondname }}</div>

                        <div class="col-span-3">{{ item.email }}</div>

                        <div class="latolight">{{ item.country }}</div>

                        <div class="grid grid-cols-2 items-center latolight">
                            <div>{{ item.reg }}</div>
                            <div>{{ item.web }}</div>
                        </div>

                        <div class="grid grid-cols-2 items-center text-cyan-600">
                            <div v-if="item.orderc > 0">{{ item.orderc }}</div>
                            <div v-else>-</div>
                            <div v-if="item.orders > 0">{{ item.orders }}</div>
                            <div v-else>-</div>
                        </div>

                        <div class="grid grid-cols-2 items-center text-teal-600">
                            <div v-if="item.paymentc > 0">{{ item.paymentc }}</div>
                            <div v-else>-</div>
                            <div v-if="item.payments > 0">{{ item.payments }}</div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
            </RecycleScroller>
        </template>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
    name: "UserList",

    components: { RecycleScroller },

    // props: {
    //     users: {
    //         type: Array,
    //     },
    // },

    data() {
        return {
            moment: moment,

            filters: {
                date: "",
                channel_id: null,
                tg_id: null,
                country: null,
                find: null,
                email: null,
                name: null,
            },
            tgDropdown: false,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "targetologs", "closePopup"]),
        ...mapState(useClientStore, ["users", "pickUser", "getUsers"]),

        uniqueCountries() {
            const countriesSet = new Set();

            this.users.forEach((item) => {
                if (item.country) {
                    countriesSet.add(item.country);
                }
            });

            return Array.from(countriesSet).sort();
        },

        uniqueTgIds() {
            const tgIdsSet = new Set();
            this.users.forEach((order) => {
                tgIdsSet.add(order.tg_id);
            });
            return Array.from(tgIdsSet);
        },

        usersF() {
            return this.users.filter((item) => {
                const dateMatch = !this.filters.date || item.date === this.filters.date;
                const channelIdMatch = !this.filters.channel_id || item.channel_id === this.filters.channel_id;
                const tgIdMatch = !this.filters.tg_id || item.tg_id === this.filters.tg_id;

                const countryMatch = !this.filters.country || item.country === this.filters.country;

                const findMatch =
                    !this.filters.find ||
                    (item.id && item.id.toString().indexOf(this.filters.find) !== -1) ||
                    (item.name && item.name.toLowerCase().includes(this.filters.find.toLowerCase())) ||
                    (item.email && item.email.toLowerCase().includes(this.filters.find.toLowerCase()));

                return dateMatch && channelIdMatch && tgIdMatch && countryMatch && findMatch;
            });
        },

        total() {
            const count = this.usersF.length;

            return { count };
        },
    },

    watch: {
        filters: {
            handler() {},
            deep: true,
        },
    },

    methods: {
        clearFilters() {
            this.filters = { date: "", channel_id: null, tg_id: null, country: null, find: null };
            this.tgDropdown = false;
        },
    },

    mounted() {
        this.getUsers();
    },
};
</script>
