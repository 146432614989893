<template>
    <form class="max-w-md w-full p-4">
        <!-- <div v-if="picture" class="relative group">
            <div class="absolute top-0 right-0 text-slate-500 group-hover:text-cyan-400" @click="removeUsername()"><IconClose /></div>
            <img class="w-full h-full object-cover" :src="'https://api.ulangroup.ru/users/' + picture" />
        </div> -->

        <input
            :class="{ '!hidden': usernameSaved }"
            type="text"
            class="text-slate-500 shadow-2xl bg-white outline-0 w-full px-5 h-12 mb-4 text-sm rounded"
            v-model="username"
            placeholder="Username"
        />

        <input
            type="password"
            autocomplete="on"
            class="text-slate-500 shadow-2xl bg-white outline-0 w-full px-5 h-12 mb-4 text-sm rounded"
            v-model.trim="password"
            placeholder="Password"
            @keyup.enter.stop="login({ username: username, password: password })"
        />

        <div class="mx-auto block shadow-2xl bg-cyan-500 hover:bg-cyan-400 rounded px-5 py-4 h-12 cursor-pointer">
            <div
                class="text-sm font-latobold leading-none uppercase text-white text-center cursor-pointer"
                @click="login({ username: username, password: password })"
            >
                Log in
            </div>
        </div>
    </form>
</template>

<script>
import { mapState } from "pinia";
import { useProfileStore } from "@/store/profile";

export default {
    name: "LoginForm",

    computed: {
        ...mapState(useProfileStore, ["login"]),
    },
    data() {
        return {
            username: "",
            usernameSaved: "",
            password: "",
            picture: "",
        };
    },

    methods: {
        removeUsername() {
            this.username = null;
            this.usernameSaved = null;
            localStorage.removeItem("picture");
            localStorage.removeItem("username");
        },
    },

    mounted() {
        if (localStorage.getItem("picture") && localStorage.getItem("username")) {
            this.username = localStorage.getItem("username");
            this.usernameSaved = localStorage.getItem("username");
            this.picture = localStorage.getItem("picture");
        }
    },
};
</script>
