<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent />

            <div class="btn btn-blue py-1 px-3 my-1" @click="(this.s.select = null), this.setSettings(this.s), update()">ПОЛУЧИТЬ</div>
            <div class="btn py-1 px-3 my-1 text-xs" @click="clearSettings(), update()">ОЧИСТИТЬ</div>
        </div>

        <div class="w-5/6 m-auto">
            <div v-for="tg in targetologs" :key="tg" class="w-full rounded-xl h-24 overflow-hidden relative pt-2 text-slate-500 group hover:my-1 duration-500">
                <div class="absolute bottom-0 z-20">
                    <img
                        v-if="tg.picture"
                        :src="'https://api.timekraken.ru/uploads/users/u' + tg.id + '.png'"
                        :alt="tg.name"
                        class="w-full h-[88px] object-contain group-hover:h-24 duration-300"
                    />
                    <img
                        v-else
                        :src="'https://api.timekraken.ru/uploads/users/u0.png'"
                        :alt="tg.name"
                        class="w-full h-[88px] object-contain group-hover:h-24 duration-300 opacity-30"
                    />
                </div>

                <div class="bg-white rounded-xl h-20 grid grid-cols-9 justify-center items-center gap-2 mt-4">
                    <div></div>
                    <div class="text-sm font-light text-center text-wrap">{{ tg.secondname }} {{ tg.name }}</div>

                    <div class="flex justify-end items-center gap-1 text-cyan-500 col-span-2">
                        <span class="text-3xl group-hover:text-4xl duration-300 font-bold">{{ parseInt(tg.spent).toLocaleString() }}</span>
                        <sup>₽</sup>
                    </div>

                    <div class="text-center">
                        <div class="text-sm font-light leading-3 mt-2">{{ parseInt(tg.ads).toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">ОБЪЯВЛЕНИЯ</span>
                    </div>

                    <div class="text-center">
                        <div class="text-sm font-light leading-3 mt-2">{{ parseInt(tg.views).toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">ПРОСМОТРЫ</span>
                    </div>

                    <div class="text-center">
                        <div class="text-sm font-light leading-3 mt-2">{{ parseInt(tg.clicks).toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">КЛИКИ</span>
                    </div>

                    <div class="text-center">
                        <div class="text-sm font-light leading-3 mt-2">{{ parseInt(tg.regs).toLocaleString() }}</div>
                        <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                    </div>

                    <div class="text-center">
                        <div class="text-sm font-light leading-3 mt-2">
                            {{ parseInt(parseInt(tg.spent) / parseInt(tg.regs)).toLocaleString() }}
                        </div>
                        <span class="text-[8px] text-slate-400">CPL</span>
                    </div>
                </div>

                <!-- <div class="flex flex-col items-center col-span-2">
                                <div>{{ tg.name }} {{ tg.secondname.slice(0, 1) }}.</div>
                            </div>

                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(tg.spent).toLocaleString() }} ₽
                            </span>
                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(tg.views).toLocaleString() }}<IconEye :size="3" />
                            </span>
                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(tg.clicks).toLocaleString() }}<IconClick :size="3" />
                            </span>
                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(tg.regs).toLocaleString() }}<IconClick :size="3" />
                            </span> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useOwnerStore } from "@/store/owner";
import moment from "moment";

import FilterComponent from "../components/Filter.vue";

export default {
    name: "OwnerTargetologs",

    components: { FilterComponent },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "profile", "setPeriod", "setMonth", "setSettings", "clearSettings", "setChannel"]),
        ...mapState(useOwnerStore, ["targetologs", "getTargetologs"]),
    },

    methods: {
        update() {
            this.getTargetologs();
        },
    },

    mounted() {
        this.getTargetologs();
    },
};
</script>
