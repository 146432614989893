<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            stroke-width="1"
            fill-rule="evenodd"
            fill="currentColor"
            d="M12 5.553c2.028-1.207 4.172-1.629 6.96-1.548l.127.003c.58.016 1.142.03 1.646.3.425.228.8.613 1.015 1.045.148.297.204.596.229.89.023.27.023.59.023.946v8.057c0 .57 0 1.049-.03 1.436-.03.4-.096.793-.282 1.16-.287.569-.685.97-1.251 1.264-.375.195-.797.263-1.22.297-.422.033-.955.04-1.603.047h-.033c-2.015.023-3.517.384-4.954 1.302a.897.897 0 0 1-.627.243.899.899 0 0 1-.627-.243c-1.437-.918-2.939-1.28-4.954-1.302h-.033c-.648-.008-1.18-.014-1.603-.047-.424-.034-.845-.102-1.22-.297a2.768 2.768 0 0 1-1.251-1.265c-.186-.367-.252-.76-.283-1.159C2 16.295 2 15.817 2 15.246V7.19c0-.355 0-.676.023-.946.025-.294.08-.593.23-.89a2.469 2.469 0 0 1 1.014-1.045c.504-.27 1.067-.284 1.646-.3l.128-.003c2.786-.08 4.93.341 6.959 1.548Zm-.912 13.003c-1.414-.635-2.912-.886-4.648-.905-.69-.008-1.16-.014-1.513-.042-.358-.029-.486-.075-.534-.1a.978.978 0 0 1-.475-.48c-.03-.058-.07-.178-.094-.483-.023-.311-.024-.72-.024-1.334v-7.99c0-.399 0-.642.016-.827.015-.17.038-.22.047-.24a.675.675 0 0 1 .254-.26c.082-.044.182-.069.976-.092 2.54-.073 4.327.307 5.995 1.301v11.452Zm1.824 0c1.414-.635 2.912-.886 4.648-.905.69-.008 1.16-.014 1.513-.042.358-.029.486-.075.534-.1a.978.978 0 0 0 .475-.48c.03-.058.07-.178.094-.483.023-.311.024-.72.024-1.334v-7.99c0-.399 0-.642-.017-.827-.014-.17-.037-.22-.046-.24a.675.675 0 0 0-.254-.26c-.082-.044-.182-.069-.976-.092-2.54-.073-4.327.307-5.995 1.301v11.452Zm-7.561-8.69a.9.9 0 0 1 .931-.867 8.3 8.3 0 0 1 2.499.444.9.9 0 1 1-.562 1.71 6.517 6.517 0 0 0-2.001-.356.9.9 0 0 1-.867-.931Zm13.298 0a.9.9 0 0 1-.867.93 6.514 6.514 0 0 0-2.001.357.9.9 0 0 1-.562-1.71 8.3 8.3 0 0 1 2.499-.444.9.9 0 0 1 .931.867ZM5.351 13.864a.9.9 0 0 1 .931-.867 8.3 8.3 0 0 1 2.499.444.9.9 0 1 1-.562 1.71 6.51 6.51 0 0 0-2.001-.355.9.9 0 0 1-.867-.932Zm13.298 0a.9.9 0 0 1-.867.931 6.51 6.51 0 0 0-2.001.356.9.9 0 0 1-.562-1.71 8.3 8.3 0 0 1 2.499-.444.9.9 0 0 1 .931.867Z"
        />
    </svg>
</template>

<script>
export default {
    name: "IVkBook",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
