import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";

export const useAnalyticsStore = defineStore("analytics", {
    state: () => ({
        dashboard: [],

        finances: [], // финансовые данные / продажи

        actions: [], // регистрации и другие действия пользователей
        graf: [], // график

        cabinets: [], // список кабинетов AD - для настройки и контроля

        days: [], // статистика по дням
        webinars: [], // статистика по вебинарам
        lastUpdate: [], // дата последнего обновления данных из каждого коннектора
        orders: [], // список заказов
        pays: [], // список оплат
        // фильтр
        utms: [], // список Utm в проекте
        events: [], // список Utm в проекте
        products: [], // список Utm в проекте

        hub: {}, // HUB
        // hub: { main: {}, project: {}, monthly: {}, daily: {} }, // HUB
    }),

    actions: {
        // получаем данные для ФИЛЬТРА
        async getUtms() {
            const res = await API.GET("data/filter", { project_id: mainStore().s.project });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            // console.log("getUtms", res.data.utms);
            this.utms = res.data.utms;
            this.webs = res.data.webinars;
            this.events = res.data.events;
            this.products = res.data.products;
        },

        // получаем все данные
        async getDashboard() {
            // mainStore().setRequest(true);
            const res = await API.GET("data/dashboard", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                channel_id: mainStore().s.channel_id,
                utms: mainStore().s.utms,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            console.log("dashboard", res.data);
            // this.finances = res.data.finances;
            // this.ads = res.data.ads;
            // this.actions = res.data.actions;
            // this.graf = res.data.graf;
            // this.utms = res.data.utms;
            this.filter = res.data.filter;
            this.dashboard = res.data.dashboard;
            // mainStore().setRequest(false);
        },

        // получаем данные по дням
        async getDays() {
            const res = await API.GET("analytics/days", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                channel_id: mainStore().s.channel_id,
                utms: mainStore().s.utms,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.days = res.data.days;
            this.lastUpdate = res.data.lastUpdate;
        },

        // получаем данные по дням
        async getWebinars() {
            // mainStore().setRequest(true);

            const res = await API.GET("data/webinars", { srt: mainStore().s.start, end: mainStore().s.end, project_id: mainStore().s.project });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.webinars = res.data.webinars;

            // mainStore().setRequest(false);
        },

        // получаем данные из HUB
        async getHubMain() {
            // mainStore().setRequest(true);
            const res = await API.GET("agency/stat/main", { srt: mainStore().s.start, end: mainStore().s.end });
            this.hub.main = res.data.main;
            this.hub.cabinets = res.data.cabinets;
            this.hub.filter = res.data.filter;
            // mainStore().setRequest(false);
        },

        // получаем данные из HUB
        async getHubProjects() {
            const res = await API.GET("agency/stat/projects", { srt: mainStore().s.start, end: mainStore().s.end });
            this.hub.projects = res.data.projects;
        },

        // получаем данные из HUB
        async getHubMonthly() {
            const res = await API.GET("agency/stat/monthly", { srt: mainStore().s.start, end: mainStore().s.end });
            this.hub.monthly = res.data.monthly;
            this.hub.daily = res.data.daily;
        },

        // получаем заказы
        async getOrders(data) {
            const res = await API.GET("analytics/orders", {
                srt: data.srt,
                end: data.end,
                channel_id: mainStore().s.channel_id,
                utms: mainStore().s.utms,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            mainStore().setPopup("orders");
            this.orders = res.data.orders;
        },

        // получаем оплаты
        async getPays(data) {
            const res = await API.GET("analytics/pays", {
                srt: data.srt,
                end: data.end,
                channel_id: mainStore().s.channel_id,
                utms: mainStore().s.utms,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            mainStore().setPopup("pays");
            this.pays = res.data.pays;
        },

        // // получаем заказы
        // async getClients(data) {
        //     const res = await API.GET("analytics/clients", { srt: data.srt, end: data.end });

        //     if (res.data.alert) mainStore().setAlert(res.data.alert);
        //     mainStore().setPopup("clients");
        //     this.clients = res.data.clients;
        // },

        // Фильтр по UTM
        setUtmSource(utm) {
            const index = this.utmsFilter.source.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.source.push(utm);
            } else {
                this.utmsFilter.source.splice(index, 1);
            }
        },

        setUtmMedium(utm) {
            const index = this.utmsFilter.medium.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.medium.push(utm);
            } else {
                this.utmsFilter.medium.splice(index, 1);
            }
        },

        setUtmCampaign(utm) {
            const index = this.utmsFilter.campaign.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.campaign.push(utm);
            } else {
                this.utmsFilter.campaign.splice(index, 1);
            }
        },

        setUtmContent(utm) {
            const index = this.utmsFilter.content.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.content.push(utm);
            } else {
                this.utmsFilter.content.splice(index, 1);
            }
        },

        setUtmTerm(utm) {
            const index = this.utmsFilter.term.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.term.push(utm);
            } else {
                this.utmsFilter.term.splice(index, 1);
            }
        },
    },
});
