<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <polygon points="0 0 28 0 28 28 0 28"></polygon>
        <path
            d="M16.060288,20.3541248 C16.3580307,20.0935998 16.7402144,19.95 17.1358455,19.95 L21.5833333,19.95 C22.7534344,19.95 23.45,19.2534344 23.45,18.0833333 L23.45,8.75 C23.45,7.57989897 22.7534344,6.88333331 21.5833333,6.88333331 L7.58333333,6.88333331 C6.41323231,6.88333331 5.71666664,7.57989897 5.71666664,8.75 L5.71666664,18.0833333 C5.71666664,19.2534344 6.41323231,19.95 7.58333333,19.95 L10.4988333,19.95 C11.3146883,20.1853117 11.3146883,20.1853117 11.55,21.0011667 L11.55,24.3011457 L16.060288,20.3541248 Z M10.3075,26.2879166 C9.75682135,25.8749076 9.45000003,25.146207 9.45000003,24.5 L9.45000003,22.05 L7.58333333,22.05 C5.25343436,22.05 3.61666669,20.4132323 3.61666669,18.0833333 L3.61666669,8.75 C3.61666669,6.42010103 5.25343436,4.78333336 7.58333333,4.78333336 L21.5833333,4.78333336 C23.9132323,4.78333336 25.55,6.42010103 25.55,8.75 L25.55,18.0833333 C25.55,20.4132323 23.9132323,22.05 21.5833333,22.05 L17.3111885,22.05 L12.9656834,25.8523169 C12.1106999,26.690914 11.1116951,26.891063 10.3075,26.2879166 Z"
            id="↳-Icon-Color"
            fill="currentColor"
            fill-rule="nonzero"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "IVkComment",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
