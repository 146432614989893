<template>
    <div
        v-if="view?.includes('date')"
        @click="setSelect('calendar')"
        class="w-full myflex flex-col box hover:text-slate-600 hover:bg-white text-sm cursor-pointer group relative rounded-xl px-2 py-6 mb-4 duration-300"
    >
        <!-- <span class="absolute top-1 left-2 text-[9px] latolight">ДАТЫ</span> -->
        <span class="text-cyan-500 latoregular leading-4" v-if="s.start">{{ moment(s.start).format("DD.MM.YYYY") }}</span>
        <span class="text-cyan-500 latoregular leading-4" v-if="s.end">{{ moment(s.end).format("DD.MM.YYYY") }}</span>
    </div>

    <div
        v-if="s.select == 'calendar'"
        class="absolute z-40 top-4 left-36 w-72 text-slate-500 text-sm bg-white/90 cursor-pointer rounded-xl p-4 -mt-4 mb-4"
    >
        <div
            class="btn btn-xs mb-4"
            :class="{ '!bg-cyan-500 !text-white': s.start == '2020-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2020-01-01', end: moment().format('YYYY-MM-DD') }), update(), setSelect('')"
        >
            ЗА ВСЕ ВРЕМЯ
        </div>

        <div class="grid grid-cols-6 gap-2 mb-4">
            <div
                class="btn btn-xs col-span-2"
                :class="{
                    '!bg-cyan-500 !text-white':
                        s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
                }"
                @click="
                    setPeriod({
                        start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                        end: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    }),
                        update(),
                        setSelect('')
                "
            >
                ВЧЕРА
            </div>
            <div
                class="btn btn-xs col-span-2"
                :class="{
                    '!bg-cyan-500 !text-white':
                        s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
                }"
                @click="
                    setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') }),
                        update(),
                        setSelect('')
                "
            >
                7 ДНЕЙ
            </div>
            <div
                class="btn btn-xs col-span-2"
                :class="{
                    '!bg-cyan-500 !text-white':
                        s.start == moment().subtract(30, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
                }"
                @click="
                    setPeriod({ start: moment().subtract(30, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') }),
                        update(),
                        setSelect('')
                "
            >
                30&nbsp;ДНЕЙ
            </div>
        </div>

        <div class="grid grid-cols-6 gap-2 mb-4">
            <div
                class="btn btn-xs col-span-2"
                :class="{
                    '!bg-cyan-500 !text-white': (s.start == '2022-01-01' && s.end == '2022-12-31') || moment(s.start).format('YYYY') == '2022',
                }"
                @click="setPeriod({ start: '2022-01-01', end: '2022-12-31' }), update(), setSelect('')"
            >
                2022
            </div>
            <div
                class="btn btn-xs col-span-2"
                :class="{
                    '!bg-cyan-500 !text-white': (s.start == '2023-01-01' && s.end == '2023-12-31') || moment(s.start).format('YYYY') == '2023',
                }"
                @click="setPeriod({ start: '2023-01-01', end: '2023-12-31' }), update(), setSelect('')"
            >
                2023
            </div>
            <div
                class="btn btn-xs col-span-2"
                :class="{
                    '!bg-cyan-500 !text-white': (s.start == '2024-01-01' && s.end == '2024-12-31') || moment(s.start).format('YYYY') == '2024',
                }"
                @click="setPeriod({ start: '2024-01-01', end: '2024-12-31' }), update(), setSelect('')"
            >
                2024
            </div>
        </div>

        <div class="grid grid-cols-6 gap-2 mb-4">
            <template v-for="(value, key) in month" :key="key">
                <div
                    class="btn btn-xs shrink-0 col-span-2"
                    :class="{ '!bg-cyan-500 !text-white': s.month == key.padStart(2, '0') }"
                    @click="setMonth(key.padStart(2, '0')), update(), setSelect('')"
                >
                    {{ value }}
                </div>
            </template>
        </div>

        <div class="grid grid-cols-6 gap-2 mb-2">
            <div class="btn btn-xs shrink-0 col-span-3">
                <input type="date" class="input p-1 h-4 text-xs col-span-6 bg-slate-100" @change="setSettings(s)" v-model="s.start" />
            </div>
            <div class="btn btn-xs shrink-0 col-span-3">
                <input type="date" class="input p-1 h-4 text-xs col-span-6 bg-slate-100" @change="setSettings(s)" v-model="s.end" />
            </div>
        </div>

        <div class="btn btn-blue py-1 px-3 my-1" @click="(this.s.select = null), this.setSettings(this.s), update()">ПОЛУЧИТЬ</div>
    </div>
    <!-- @click="setSelect('channel')" -->
    <!-- КАНАЛ -->
    <div
        v-if="view?.includes('channel')"
        class="w-full flex flex-col justify-center items-center gap-2 bg-white/50 hover:border-slate-300/0 text-slate-500 hover:text-slate-600 text-sm latolight hover:bg-white cursor-pointer relative rounded-xl p-2 py-6 mb-4 duration-300"
    >
        <!-- <span class="absolute top-1 left-2 text-[9px] latolight">КАНАЛЫ</span> -->
        <span class="absolute top-[2px] right-2 text-xs text-slate-400" @click="setChannel(null), update()">очистить</span>

        <div class="grid grid-cols-3 justify-center items-center gap-2 text-slate-300">
            <div :class="{ '!text-cyan-500': s.channel_id == 1 }" @click="setChannel(1), update()"><IVk :size="6" /></div>
            <div :class="{ '!text-cyan-500': s.channel_id == 2 }" @click="setChannel(2), update()"><IVkAds :size="6" /></div>
            <div :class="{ '!text-cyan-500': s.channel_id == 3 }" @click="setChannel(3), update()"><IVkCamp :size="6" /></div>
            <div :class="{ '!text-cyan-500': s.channel_id == 4 }" @click="setChannel(4), update()"><IFb :size="6" /></div>
            <div :class="{ '!text-cyan-500': s.channel_id == 5 }" @click="setChannel(5), update()"><IYa :size="6" /></div>
            <div :class="{ '!text-cyan-500': s.channel_id == 9 }" @click="setChannel(9), update()"><ITgads :size="6" /></div>
            <div :class="{ '!text-cyan-500': s.channel_id == 10 }" @click="setChannel(10), update()"><IYoutube :size="6" /></div>
            <div :class="{ '!text-cyan-500': s.channel_id == 100 }" @click="setChannel(100), update()"><IBase :size="6" /></div>
        </div>
    </div>

    <div
        v-if="view?.includes('compact')"
        class="w-full aspect-square myflex flex-col items-start box text-sm latolight hover:bg-white cursor-pointer relative rounded-xl p-2 mb-4"
    >
        <div class="relative flex justify-end items-center group p-1 text-xs text-slate-500 cursor-pointer" @click="setCompact()">
            <input
                type="checkbox"
                :checked="s.compact"
                class="absolute z-0 left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
            />
            <span
                class="w-8 h-5 flex items-center flex-shrink-0 mr-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-cyan-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
            ></span>
            Компактно
        </div>

        <div class="relative flex justify-end items-center group p-1 text-xs text-slate-500 cursor-pointer" @click="setSDays()">
            <input
                type="checkbox"
                :checked="s.days"
                class="absolute z-0 left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
            />
            <span
                class="w-8 h-5 flex items-center flex-shrink-0 mr-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-cyan-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
            ></span>
            Дни
        </div>

        <div class="relative flex justify-end items-center group p-1 text-xs text-slate-500 cursor-pointer" @click="setSNds()">
            <input
                type="checkbox"
                :checked="s.nds"
                class="absolute z-0 left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
            />
            <span
                class="w-8 h-5 flex items-center flex-shrink-0 mr-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-cyan-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
            ></span>
            НДС 20%
        </div>
    </div>

    <div
        v-if="view?.includes('utms')"
        @click="setPopup('filterUtm')"
        class="w-full flex flex-col justify-center items-center gap-2 bg-white/50 hover:border-slate-300/0 text-slate-500 hover:text-slate-600 text-sm latolight hover:bg-white cursor-pointer relative rounded-xl p-2 py-4 mb-4 duration-300"
    >
        <span class="absolute top-1 left-2 text-[9px] latolight">UTM</span>

        <div class="w-full flex flex-col items-center gap-1 my-1">
            <div v-for="item in allUtms" :key="item" class="latobold text-cyan-500 leading-4">
                {{ item }}
            </div>
            <!-- <div class="latobold text-cyan-500 leading-4">test</div> -->
        </div>
    </div>

    <div
        v-if="view?.includes('tgs')"
        @click="setPopup('filterTgs')"
        class="w-full aspect-square flex flex-col justify-center items-center gap-2 bg-white/50 hover:border-slate-300/0 text-slate-500 hover:text-slate-600 text-sm latolight hover:bg-white cursor-pointer relative rounded-xl p-2 mb-4 duration-300"
    >
        <span class="absolute top-1 left-2 text-[9px] latolight">ТАРГЕТОЛОГИ</span>

        <div class="w-full flex flex-wrap items-center gap-1 my-1 text-xs">
            <div v-for="item in s.tgs" :key="item.id" class="bg-slate-50 py-1 px-2 rounded cursor-pointer">
                {{ item }}
            </div>
        </div>
    </div>

    <!-- <div class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300">
        <div class="flex flex-wrap justify-start items-center gap-2 text-slate-500">
            <template v-for="cabinet in hub.cabinets" :key="cabinet">
                <div class="btn p-1 bg-white text-xs text-slate-500 uppercase">{{ cabinet.name }}</div>
            </template>
        </div>
    </div> -->

    <!-- <div
        @click="setSelect('project')"
        class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="text-[9px] text-slate-400">ВОРОНКА</div>

        <template v-for="p in projects" :key="p.id">
            <div v-if="p.id == s.project_id">{{ p.name }}</div>
        </template>
    </div>

    <div
        v-if="s.select == 'project'"
        class="absolute z-50 w-full  bg-white/50  text-slate-500 text-sm bg-white cursor-pointer rounded-b p-2 -mt-[18px] mb-4"
    >
        <div class="text-[9px] text-slate-400">ВЫБРАТЬ</div>

        <div @click="setPro(p.id)" v-for="p in projects" :key="p.id" class="py-1 rounded text-sm cursor-pointer duration-300">
            <div class="hover:text-cyan-500" :class="{ '!text-cyan-600': p.id == s.project_id }">{{ p.name }}</div>
        </div>
    </div> -->

    <!-- <div class="mb-8">
        <div class="title mb-2">ПРОЕКТ</div>
        <select v-model="s.project_id" @change="setProject(s.project_id)" class="input bg-white h-9 px-3 py-2 rounded text-sm">
            <option value="0" disabled>Проект</option>
            <template v-for="p in projects" :key="p.id">
                <option v-if="p.id == s.project_id" selected :value="p.id">{{ p.name }}</option>
                <option v-else :value="p.id">{{ p.name }}</option>
            </template>
        </select>
    </div> -->

    <!-- <div class="grid grid-cols-5 justify-start items-center gap-2 mb-8">
        <div
            v-for="item in lastUpdate"
            :key="item"
            class="flex flex-col justify-center items-center bg-cyan-400 text-white aspect-square p-2 rounded"
            :class="{ 'bg-cyan-400': item.date == moment().format('YYYY-MM-DD'), ' bg-amber-400': item.date < moment().format('YYYY-MM-DD') }"
        >
            <div class="font-bold text-xs">{{ item.label }}</div> -->
    <!-- <div v-if="item.date" class="text-xs">
                        {{ moment(item.date).format("DD") }} {{ month[parseInt(moment(item.date).format("MM"))] }}
                    </div>
                    <div v-else class="text-xs">-</div> -->
    <!-- </div>
    </div> -->

    <!-- <div class="mb-8">
        <div class="title mb-2">ТАРГЕТОЛОГ</div>
        <div class="flex items-center justify-between gap-2 text-slate-500 text-sm uppercase">
            <div class="bg-slate-100 rounded-full w-10 h-10 overflow-hidden cursor-pointer">
                <img src="https://api.timekraken.ru/uploads/users/u7.png" alt="" />
            </div>
            <div class="bg-slate-100 rounded-full w-10 h-10 overflow-hidden cursor-pointer">
                <img src="https://api.timekraken.ru/uploads/users/u9.png" alt="" />
            </div>
            <div class="bg-slate-100 rounded-full w-10 h-10 overflow-hidden cursor-pointer">
                <img src="https://api.timekraken.ru/uploads/users/u6.png" alt="" />
            </div>
        </div>
    </div> -->

    <!-- :class="{ '!text-cyan-600': p.id == s.project_id }" -->

    <!-- <d5v
        @click="setSelect('source')"
        class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="text-[9px] text-slate-400 uppercase">utm source</div>

        <div class="flex items-center gap-2">
            <template v-for="utm in s.utms?.source" :key="utm">
                <div class="btn p-1">{{ utm }}</div>
            </template>
        </div>
    </div>

    <div
        v-if="s.select == 'source'"
        class="absolute z-50 w-full  bg-white/50  text-slate-500 text-sm bg-white cursor-pointer rounded-b p-2 -mt-[18px] mb-4"
    >
        <div class="text-[9px] text-slate-400 uppercase">ВЫБРАТЬ</div>

        <div @click="setSource(utm)" v-for="utm in allUtms?.source" :key="utm" class="py-1 rounded text-sm cursor-pointer duration-300">
            <div class="hover:text-slate-600">{{ utm }}</div>
        </div>
    </div>

    <div
        @click="setSelect('medium')"
        class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="text-[9px] text-slate-400 uppercase">utm medium</div>

        <div class="flex items-center gap-2">
            <template v-for="utm in s.utms?.medium" :key="utm">
                <div class="btn p-1">{{ utm }}</div>
            </template>
        </div>
    </div>

    <div
        v-if="s.select == 'medium'"
        class="absolute z-50 w-full  bg-white/50  text-slate-500 text-sm bg-white cursor-pointer rounded-b p-2 -mt-[18px] mb-4"
    >
        <div class="text-[9px] text-slate-400 uppercase">ВЫБРАТЬ</div>

        <div @click="setMedium(utm)" v-for="utm in allUtms?.medium" :key="utm" class="py-1 rounded text-sm cursor-pointer duration-300">
            <div class="hover:text-slate-600">{{ utm }}</div>
        </div>
    </div>

    <div
        @click="setSelect('campaign')"
        class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="text-[9px] text-slate-400 uppercase">utm campaign</div>

        <div class="flex items-center gap-2">
            <template v-for="utm in s.utms?.campaign" :key="utm">
                <div class="btn p-1">{{ utm }}</div>
            </template>
        </div>
    </div>

    <div
        v-if="s.select == 'campaign'"
        class="absolute z-50 w-full  bg-white/50  text-slate-500 text-sm bg-white cursor-pointer rounded-b p-2 -mt-[18px] mb-4"
    >
        <div class="text-[9px] text-slate-400 uppercase">ВЫБРАТЬ</div>

        <div @click="setCampaign(utm)" v-for="utm in allUtms?.campaign" :key="utm" class="py-1 rounded text-sm cursor-pointer duration-300">
            <div class="hover:text-slate-600">{{ utm }}</div>
        </div>
    </div>

    <div
        @click="setSelect('content')"
        class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="text-[9px] text-slate-400 uppercase">utm content</div>

        <div class="flex items-center gap-2">
            <template v-for="utm in s.utms?.content" :key="utm">
                <div class="btn p-1">{{ utm }}</div>
            </template>
        </div>
    </div>

    <div
        v-if="s.select == 'content'"
        class="absolute z-50 w-full  bg-white/50  text-slate-500 text-sm bg-white cursor-pointer rounded-b p-2 -mt-[18px] mb-4"
    >
        <div class="text-[9px] text-slate-400 uppercase">ВЫБРАТЬ</div>

        <div @click="setContent(utm)" v-for="utm in allUtms?.content" :key="utm" class="py-1 rounded text-sm cursor-pointer duration-300">
            <div class="hover:text-slate-600">{{ utm }}</div>
        </div>
    </div>

    <div
        @click="setSelect('term')"
        class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="text-[9px] text-slate-400 uppercase">utm term</div>

        <div class="flex items-center gap-2">
            <template v-for="utm in s.utms?.term" :key="utm">
                <div class="btn p-1">{{ utm }}</div>
            </template>
        </div>
    </div>

    <div
        v-if="s.select == 'term'"
        class="absolute z-50 w-full  bg-white/50  text-slate-500 text-sm bg-white cursor-pointer rounded-b p-2 -mt-[18px] mb-4"
    >
        <div class="text-[9px] text-slate-400 uppercase">ВЫБРАТЬ</div>

        <div @click="setTerm(utm)" v-for="utm in allUtms?.term" :key="utm" class="py-1 rounded text-sm cursor-pointer duration-300">
            <div class="hover:text-slate-600">{{ utm }}</div>
        </div>
    </div>

    <div
        @click="setSelect('webinar')"
        class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="text-[9px] text-slate-400 uppercase">ВЕБИНАРЫ</div>

        <div class="flex items-center gap-2">
            <template v-for="utm in s.utms?.term" :key="utm">
                <div class="btn p-1">{{ utm }}</div>
            </template>
        </div>
    </div>

    <div
        v-if="s.select == 'webinar'"
        class="absolute z-50 w-full  bg-white/50  text-slate-500 text-sm bg-white cursor-pointer rounded-b p-2 -mt-[18px] mb-4"
    >
        <div class="text-[9px] text-slate-400 uppercase">ВЫБРАТЬ</div>

        <div @click="setTerm(utm)" v-for="utm in allUtms?.term" :key="utm" class="py-1 rounded text-sm cursor-pointer duration-300">
            <div class="hover:text-slate-600">{{ utm }}</div>
        </div>
    </div>

    <div
        @click="setSelect('event')"
        class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="text-[9px] text-slate-400 uppercase">РЕГИСТРАЦИЯ НА СОБЫТИЕ</div>

        <div class="flex items-center gap-2">
            <template v-for="utm in s.utms?.term" :key="utm">
                <div class="btn p-1">{{ utm }}</div>
            </template>
        </div>
    </div>

    <div
        v-if="s.select == 'event'"
        class="absolute z-50 w-full  bg-white/50  text-slate-500 text-sm bg-white cursor-pointer rounded-b p-2 -mt-[18px] mb-4"
    >
        <div class="text-[9px] text-slate-400 uppercase">ВЫБРАТЬ</div>

        <div @click="setTerm(utm)" v-for="utm in allUtms?.term" :key="utm" class="py-1 rounded text-sm cursor-pointer duration-300">
            <div class="hover:text-slate-600">{{ utm }}</div>
        </div>
    </div>

    <div
        @click="setSelect('product')"
        class=" bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="text-[9px] text-slate-400 uppercase">ТОВАРЫ</div>

        <div class="flex items-center gap-2">
            <template v-for="utm in s.utms?.term" :key="utm">
                <div class="btn p-1">{{ utm }}</div>
            </template>
        </div>
    </div>

    <div
        v-if="s.select == 'product'"
        class="absolute z-50 w-full  bg-white/50  text-slate-500 text-sm bg-white cursor-pointer rounded-b p-2 -mt-[18px] mb-4"
    >
        <div class="text-[9px] text-slate-400 uppercase">ВЫБРАТЬ</div>

        <div @click="setTerm(product)" v-for="product in products" :key="product" class="py-1 rounded text-sm cursor-pointer duration-300">
            <div class="hover:text-slate-600">{{ product }}</div>
        </div>
    </div>

    <div
        class="flex justify-between items-center  bg-white/50  text-slate-600 text-sm hover:bg-white cursor-pointer rounded p-2 mb-4 duration-300"
    >
        <div class="relative flex justify-end items-center group p-1 text-xs text-slate-400 cursor-pointer" @click="setCompact()">
            <input
                type="checkbox"
                :checked="s.compact"
                class="absolute z-0 left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
            />
            <span
                class="w-8 h-5 flex items-center flex-shrink-0 mr-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-cyan-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
            ></span>
            Компактно
        </div>

        <div class="relative flex justify-end items-center group p-1 text-xs text-slate-400 cursor-pointer" @click="setSDays()">
            <input
                type="checkbox"
                :checked="s.days"
                class="absolute z-0 left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
            />
            <span
                class="w-8 h-5 flex items-center flex-shrink-0 mr-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-cyan-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
            ></span>
            Дни
        </div>
    </div> -->

    <div class="text-[9px] text-slate-300 hover:text-cyan-500 text-center cursor-pointer duration-500" @click="clearSettings(), update()">
        ОЧИСТИТЬ
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAccountStore } from "@/store/account";
import { useAnalyticsStore } from "@/store/analytics";

import moment from "moment";

export default {
    name: "FilterComponent",

    emits: ["update"],

    props: {
        view: {
            type: Array,
        },
    },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, [
            "s",
            "filter",
            "month",
            "setPeriod",
            "setMonth",
            "setSettings",
            "setCompact",
            "setSDays",
            "setSNds",
            "setProject",
            "setChannel",
            "clearSettings",
            "setPopup",
        ]),
        ...mapState(useAccountStore, ["projects", "events"]),
        ...mapState(useAnalyticsStore, [
            "utms",
            "webs",
            "events",
            "products",
            "utmsFilter",
            "getUtms",
            "lastUpdate",
            // "setUtmSource",
            // "setUtmMedium",
            // "setUtmCampaign",
            // "setUtmContent",
            // "setUtmTerm",
        ]),

        allUtms() {
            if (!this.s.utms) return [];
            let data = [];

            data.push(...this.s.utms.source);
            data.push(...this.s.utms.medium);
            data.push(...this.s.utms.campaign);
            data.push(...this.s.utms.content);
            data.push(...this.s.utms.term);

            return data;
        },
    },

    methods: {
        setSelect(name) {
            console.log("name", name);
            if (this.s.select != name) {
                this.s.select = name;
            } else {
                this.s.select = null;
            }
            this.setSettings(this.s);
        },

        setCalendar(id) {
            this.setProject(id);
            this.s.select = null;
            this.setSettings(this.s);
        },

        setPro(id) {
            this.setProject(id);
            this.s.select = null;
            this.setSettings(this.s);
        },

        update() {
            this.$emit("update");
        },
    },

    mounted() {},
};
</script>
