import { defineStore } from "pinia";
import API from "../services/api";
import router from "@/services/router";
import { useMainStore as mainStore } from "./main";

export const useAdminStore = defineStore("admin", {
    state: () => ({
        admin: [],
        adminMenu: "",
        adminAccount: {},
        monitor: [],
        roadmap: [],
        cabinets: [],
        projects: [],
        project: {},
        clients: [],
        users: [],
        permissions: [],
        targetologs: [],
    }),

    actions: {
        // получаем все данные
        async getAdmin() {
            const res = await API.GET("account/admin/list");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.admin = res.data.admin;
        },

        // млриторинг
        async getMonitor() {
            const res = await API.GET("account/admin/monitor");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.monitor = res.data.monitor;
        },

        // получаем Roadmap
        async getRoadmap() {
            const res = await API.GET("account/admin/roadmap");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.roadmap = res.data.roadmap;
        },

        // получаем все проекты агентства
        async getProjects() {
            const res = await API.GET("agency/project/list");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.projects = res.data.projects;
        },

        // получаем все кабинеты агентства
        async getCabinets() {
            const res = await API.GET("agency/cabinet/list");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.cabinets = res.data.cabinets;
        },

        // получаем всех клиентов агентства
        async getClients() {
            const res = await API.GET("agency/client/list");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.clients = res.data.clients;
        },

        // получаем всех пользователей агентства
        async getUsers() {
            const res = await API.GET("agency/user/list");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.users = res.data.users;
            this.permissions = res.data.permissions;
        },

        // получаем всех таргетологов агентства
        async getTargetologs() {
            const res = await API.GET("agency/user/targetologs");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.targetologs = res.data.targetologs;
        },

        pickProject(project) {
            mainStore().setProjectId(project);
            this.project = project;
            console.log("pickProject", project);
            router.push("/admin/project");
        },

        setAdminMenu(menu) {
            this.adminMenu = menu;
        },

        setAdminAccount(account) {
            this.adminAccount = account;
        },

        async addClient(client) {
            console.log("addClient", this.adminAccount.id, client);
            if (!this.adminAccount.id) return;

            const formData = new FormData();
            formData.append("account_id", this.adminAccount.id);
            formData.append("client_id", client.id);
            formData.append("cabinet_id", client.cabinet_id);
            formData.append("channel_id", client.channel_id);
            formData.append("label", client.label);
            formData.append("name", client.name);

            const res = await API.POST("account/admin/add-client", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.admin = res.data.admin;
        },

        // добавить клиентов в проект
        async addProjectToClients(data) {
            // Извлекаем только поля id и channel_id из каждого элемента
            const clientsData = data.clients.map((client) => ({ id: client.id, channel_id: client.channel_id }));

            let formData = new FormData();
            formData.append("project_id", data.project_id);
            formData.append("clients", JSON.stringify(clientsData));

            const res = await API.POST("hub/add-project-to-clients", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.projects = res.data.projects;
            this.clients = res.data.clients;
            router.push("/admin/clients");
        },

        async unProjectClient(data) {
            // Извлекаем только поля id и channel_id из каждого элемента
            const clientsData = data.clients.map((client) => ({ id: client.id, channel_id: client.channel_id }));

            let formData = new FormData();
            formData.append("clients", JSON.stringify(clientsData));

            const res = await API.POST("hub/un-project-clients", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.projects = res.data.projects;
            this.clients = res.data.clients;
            router.push("/admin/clients");
        },
    },
});
