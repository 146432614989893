// Функция для обработки каналов
export function processChannels(channels) {
    const keyMap = {
        1: "vk",
        2: "vkads",
        3: "market",
        4: "book",
        5: "yandex",
        6: "metrika",
        7: "google",
        8: "tg",
        9: "tgads",
        10: "youtube",
        100: "base",
    };
    return transformKeys(channels, keyMap);
}

// Функция для преобразования ключей в объекте
function transformKeys(obj, keyMap) {
    const result = {};
    for (const key in obj) {
        const newKey = keyMap[key] || key;
        result[newKey] = obj[key];
    }
    return result;
}
