<template>
    <div class="min-h-60 bg-white rounded p-4">
        <div class="flex justify-center items-center gap-4">
            <input type="text" class="input" v-model="id" />
            <div
                class="text-teal-600 cursor-pointer"
                @click="addClient({ id: id, cabinet_id: 15210968, channel_id: 2, label: 'FAQ', name: 'Получаем данные...' })"
            >
                <IcoBig name="plus" :size="5" />
            </div>
        </div>

        <div class="grid grid-cols-11 items-center bg-white gap-4 p-2 mt-4 text-xs text-slate-500 border-b border-slate-200">
            <div class="col-span-2">КАНАЛ ID</div>
            <div class="text-center">АГЕНСТВО</div>
            <div class="col-span-3">КАБИНЕТ</div>
            <div class="text-right">БЮДЖЕТ</div>
            <div class="text-right">ПОКАЗЫ</div>
            <div class="text-right">КЛИКИ</div>
            <div class="text-right">ОБЪЯВЛЕНИЯ</div>
        </div>

        <div
            v-for="item in clients"
            :key="item.id"
            class="grid grid-cols-11 items-center bg-white gap-4 p-2 text-sm border-b border-slate-200 relative"
        >
            <div class="flex justify-start items-center gap-2 text-slate-500 col-span-2">
                <div>
                    <a
                        v-if="item.channel_id == 1"
                        class="text-2xl text-cyan-600 block font-bold cursor-pointer"
                        target="_blank"
                        :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                    >
                        <IVk />
                    </a>

                    <a
                        v-if="item.channel_id == 2"
                        class="text-2xl text-cyan-600 block font-bold cursor-pointer p-1"
                        target="_blank"
                        href="https://ads.vk.com/hq/dashboard"
                    >
                        <IVkAds :size="6" />
                    </a>

                    <a
                        v-if="item.channel_id == 3"
                        class="text-2xl text-blue-500 block font-bold cursor-pointer"
                        target="_blank"
                        :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                    >
                        <IVk />
                    </a>

                    <a
                        v-if="item.channel_id == 4"
                        class="text-2xl text-cyan-600 block font-bold cursor-pointer p-1"
                        target="_blank"
                        href="https://ads.vk.com/hq/dashboard"
                    >
                        <IFb :size="6" />
                    </a>
                    <a
                        v-if="item.channel_id == 5"
                        class="text-2xl text-cyan-600 block font-bold cursor-pointer p-1"
                        target="_blank"
                        href="https://ads.vk.com/hq/dashboard"
                    >
                        <IYa :size="6" />
                    </a>
                </div>

                {{ item.id }}
            </div>

            <div class="text-center">{{ item.label }}</div>

            <div class="col-span-3">{{ item.name }}</div>

            <div class="text-right tabular-nums">{{ item.spent?.toLocaleString() }} ₽</div>

            <div class="text-right text-slate-500">{{ item.views?.toLocaleString() }}</div>

            <div class="text-right text-slate-500">{{ item.clicks?.toLocaleString() }}</div>

            <div class="text-right text-slate-500">{{ item.ads_all }}</div>

            <div class="absolute top-2 right-2 text-sky-800 cursor-pointer" @click="parseClientCampaigns(item.id)">
                <IconDown :size="5" />
            </div>
        </div>

        <div class="grid grid-cols-11 items-center bg-slate-100 gap-4 px-2 py-3 text-sm border-b border-slate-200 relative">
            <div class="flex justify-start items-center gap-2 col-span-2 text-slate-500">ИТОГО</div>

            <div></div>

            <div class="col-span-3">Количество: {{ total.count }}</div>

            <div class="text-right tabular-nums">{{ total.spent?.toLocaleString() }} ₽</div>

            <div class="text-right text-slate-500">{{ total.views?.toLocaleString() }}</div>

            <div class="text-right text-slate-500">{{ total.clicks?.toLocaleString() }}</div>

            <div class="text-right text-slate-500">{{ total.ads_active }}</div>
        </div>
    </div>

    <!-- <div class="mb-8">
        <div class="title mb-2">КАНАЛ</div>
        <div class="flex flex-wrap justify-start items-center gap-2 text-slate-500">
            <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': !s.channel }" @click="setChannel(null), getDays()">ВСЕ</div>
            <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': s.channel == '1' }" @click="setChannel('1'), getDays()">VK</div>
            <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': s.channel == '2' }" @click="setChannel('2'), getDays()">ADS</div>
            <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': s.channel == '3' }" @click="setChannel('3'), getDays()">MP</div>
            <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': s.channel == '4' }" @click="setChannel('4'), getDays()">FB</div>
            <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': s.channel == '5' }" @click="setChannel('5'), getDays()">YA</div>
            <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': s.channel == '6' }" @click="setChannel('6'), getDays()">GG</div>
            <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': s.channel == '7' }" @click="setChannel('7'), getDays()">TG</div>
        </div>
    </div> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAdminStore } from "@/store/admin";
import { useAccountStore } from "@/store/account";

import moment from "moment";

export default {
    name: "ClientsComponent",

    props: {
        clients: {
            type: Array,
        },
    },

    data() {
        return {
            moment: moment,
            id: "",
        };
    },

    computed: {
        ...mapState(useMainStore, ["s"]),
        ...mapState(useAdminStore, ["addClient"]),
        ...mapState(useAccountStore, ["parseClientCampaigns"]),

        total() {
            if (!this.clients) return {};

            let data = { count: 0, views: 0, clicks: 0, spent: 0, cpm: 0, cpc: 0 };

            this.clients.forEach((client) => {
                data.count++;
                data.views = parseInt(data.views) + parseInt(0 + client.views);
                data.clicks = parseInt(data.clicks) + parseInt(0 + client.clicks);
                data.spent = parseInt(data.spent) + parseInt(0 + client.spent);
            });

            data.cpm = Math.round((data.spent / data.views) * 1000);
            data.cpc = Math.round(data.spent / data.clicks);

            return data;
        },
    },
};
</script>
