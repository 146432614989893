import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";

export const useFunnelStore = defineStore("funnel", {
    state: () => ({
        funnel: [],
    }),

    actions: {
        // получаем все данные
        async getFunnel() {
            const res = await API.GET("funnel/data");
            console.log("getFunnel", res.data);

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.funnel = res.data.funnel;
        },
    },
});
