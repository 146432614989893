import { defineStore } from "pinia";
import API from "../services/api";
import router from "@/services/router";
import { useMainStore as mainStore } from "./main";
import moment from "moment";

export const useOwnerStore = defineStore("owner", {
    state: () => ({
        stat: [], // общая статистика
        cabinets: [], // статистика по кабинетам
        projects: [], // статистика по проектам
        project: [], // статистика по проекту
        graf: [], // график по месяцам
        monthly: [], // статистика по месяцам
        targetologs: [], // статистика по таргетологам
    }),

    actions: {
        async getStat() {
            // mainStore().setRequest(true);
            const res = await API.GET("owner/stat/stat", { srt: mainStore().s.start, end: mainStore().s.end });
            this.stat = res.data.stat;
            this.filter = res.data.filter;
            // mainStore().setRequest(false);
        },

        async getCabinets() {
            const res = await API.GET("owner/stat/cabinets", { srt: mainStore().s.start, end: mainStore().s.end });
            this.cabinets = res.data.cabinets;
        },

        async getProjects() {
            const res = await API.GET("owner/stat/projects", { srt: mainStore().s.start, end: mainStore().s.end });
            this.projects = res.data.projects;
        },

        async getProject() {
            console.log("getProject", mainStore().s.project_id);

            if (!mainStore().s.project_id) return null;
            const res = await API.GET("owner/stat/project", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                project_id: mainStore().s.project_id,
            });
            this.project = res.data.project;
            console.log("this.project", this.project);
        },

        pickProject(project) {
            mainStore().setProjectId(project);
            this.project = project;
            router.push("/owner/project");
        },

        async getMonthly() {
            let srt = mainStore().s.start;
            let end = mainStore().s.end;

            // Если выбран период менее 1 года
            if (Math.abs(moment(end).diff(moment(srt), "years")) < 1) srt = moment(end).subtract(1, "year").format("YYYY-MM-DD");

            const res = await API.GET("owner/stat/monthly", { srt: srt, end: end });
            this.monthly = res.data.monthly;
        },

        async getTargetologs() {
            const res = await API.GET("owner/stat/targetologs", { srt: mainStore().s.start, end: mainStore().s.end });
            this.targetologs = res.data.targetologs;
        },

        //.
        //.
        //.

        //.
        //.
        //.

        //.
        //.
        //.

        // получаем данные для ФИЛЬТРА
        async getUtms() {
            const res = await API.GET("data/filter", { project_id: mainStore().s.project });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            // console.log("getUtms", res.data.utms);
            this.utms = res.data.utms;
            this.webs = res.data.webinars;
            this.events = res.data.events;
            this.products = res.data.products;
        },

        // получаем все данные
        async getDashboard() {
            // mainStore().setRequest(true);
            const res = await API.GET("data/dashboard", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                channel_id: mainStore().s.channel_id,
                utms: mainStore().s.utms,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            console.log("dashboard", res.data);
            // this.finances = res.data.finances;
            // this.ads = res.data.ads;
            // this.actions = res.data.actions;
            // this.graf = res.data.graf;
            // this.utms = res.data.utms;
            this.filter = res.data.filter;
            this.dashboard = res.data.dashboard;
            // mainStore().setRequest(false);
        },

        // получаем данные по дням
        async getDays() {
            const res = await API.GET("owner/days", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                channel_id: mainStore().s.channel_id,
                utms: mainStore().s.utms,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.days = res.data.days;
            this.lastUpdate = res.data.lastUpdate;
        },

        // получаем данные по дням
        async getWebinars() {
            // mainStore().setRequest(true);

            const res = await API.GET("data/webinars", { srt: mainStore().s.start, end: mainStore().s.end, project_id: mainStore().s.project });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.webinars = res.data.webinars;

            // mainStore().setRequest(false);
        },

        // получаем заказы
        async getOrders(data) {
            const res = await API.GET("owner/orders", {
                srt: data.srt,
                end: data.end,
                channel_id: mainStore().s.channel_id,
                utms: mainStore().s.utms,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            mainStore().setPopup("orders");
            this.orders = res.data.orders;
        },

        // получаем оплаты
        async getPays(data) {
            const res = await API.GET("owner/pays", {
                srt: data.srt,
                end: data.end,
                channel_id: mainStore().s.channel_id,
                utms: mainStore().s.utms,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            mainStore().setPopup("pays");
            this.pays = res.data.pays;
        },

        // // получаем заказы
        // async getClients(data) {
        //     const res = await API.GET("owner/clients", { srt: data.srt, end: data.end });

        //     if (res.data.alert) mainStore().setAlert(res.data.alert);
        //     mainStore().setPopup("clients");
        //     this.clients = res.data.clients;
        // },

        // Фильтр по UTM
        setUtmSource(utm) {
            const index = this.utmsFilter.source.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.source.push(utm);
            } else {
                this.utmsFilter.source.splice(index, 1);
            }
        },

        setUtmMedium(utm) {
            const index = this.utmsFilter.medium.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.medium.push(utm);
            } else {
                this.utmsFilter.medium.splice(index, 1);
            }
        },

        setUtmCampaign(utm) {
            const index = this.utmsFilter.campaign.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.campaign.push(utm);
            } else {
                this.utmsFilter.campaign.splice(index, 1);
            }
        },

        setUtmContent(utm) {
            const index = this.utmsFilter.content.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.content.push(utm);
            } else {
                this.utmsFilter.content.splice(index, 1);
            }
        },

        setUtmTerm(utm) {
            const index = this.utmsFilter.term.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.term.push(utm);
            } else {
                this.utmsFilter.term.splice(index, 1);
            }
        },
    },
});
