<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent />

            <div class="btn btn-blue py-1 px-3 my-1" @click="(this.s.select = null), this.setSettings(this.s), update()">ПОЛУЧИТЬ</div>
            <div class="btn py-1 px-3 my-1 text-xs" @click="clearSettings(), update()">ОЧИСТИТЬ</div>
        </div>

        <div class="w-full">
            <div class="w-full group">
                <div class="grid grid-cols-7 gap-8 text-2xl text-slate-600">
                    <div class="flex flex-col col-span-2 bg-white p-8 rounded-2xl h-24 group-hover:h-36 transition-height ease-out">
                        <div class="h-0 group-hover:h-4 transition-height">
                            <div class="text-xs text-slate-400 opacity-0 group-hover:opacity-100 transition">ПРОЕКТ # {{ project?.project?.id }}</div>
                        </div>
                        <span class="latolight">{{ project?.project?.name }}</span>
                        <div class="flex flex-col opacity-0 group-hover:opacity-100 group-hover:h-auto text-slate-400 transition">
                            <span class="text-xs">{{ project?.project?.client_short }}</span>
                            <span class="text-xs">ИНН: {{ project?.project?.inn }}</span>
                        </div>
                    </div>

                    <div class="flex flex-col p-8 rounded-2xl h-24 group-hover:h-36 transition-height ease-out">
                        <div class="h-0 group-hover:h-4 transition-height">
                            <div class="text-xs text-slate-400 opacity-0 group-hover:opacity-100 transition">ОТКРУТ</div>
                        </div>
                        <div class="num text-2xl latolight">{{ parseInt(counter_total).toLocaleString() }}</div>
                    </div>

                    <div class="flex flex-col p-8 rounded-2xl h-24 group-hover:h-36 transition-height ease-out">
                        <div class="h-0 group-hover:h-4 transition-height">
                            <div class="text-xs text-slate-400 opacity-0 group-hover:opacity-100 transition">ОБЪЯВЛЕНИЯ</div>
                        </div>
                        <div class="num text-2xl latolight">{{ parseInt(project?.total?.ads).toLocaleString() }}</div>
                        <div class="flex flex-col opacity-0 group-hover:opacity-100 group-hover:h-auto text-slate-400 transition">
                            <span class="text-xs">
                                AD
                                {{ (parseInt(project?.total?.spent) / parseInt(project?.total?.ads)).toLocaleString() }} ₽
                            </span>
                        </div>
                    </div>

                    <div class="flex flex-col p-8 rounded-2xl h-24 group-hover:h-36 transition-height ease-out">
                        <div class="h-0 group-hover:h-4 transition-height">
                            <div class="text-xs text-slate-400 opacity-0 group-hover:opacity-100 transition">ПРОСМОТРЫ</div>
                        </div>
                        <div class="num text-2xl latolight">{{ parseInt(project?.total?.views).toLocaleString() }}</div>
                        <div class="flex flex-col opacity-0 group-hover:opacity-100 group-hover:h-auto text-slate-400 transition">
                            <span class="text-xs">
                                CPM
                                {{ ((parseInt(project?.total?.spent) / parseInt(project?.total?.views)) * 1000).toLocaleString() }} ₽
                            </span>
                        </div>
                    </div>

                    <div class="flex flex-col p-8 rounded-2xl h-24 group-hover:h-36 transition-height ease-out">
                        <div class="h-0 group-hover:h-4 transition-height">
                            <div class="text-xs text-slate-400 opacity-0 group-hover:opacity-100 transition">КЛИКИ</div>
                        </div>
                        <div class="num text-2xl latolight">{{ parseInt(project?.total?.clicks).toLocaleString() }}</div>
                        <div class="flex flex-col opacity-0 group-hover:opacity-100 group-hover:h-auto text-slate-400 transition">
                            <span class="text-xs">
                                CPC
                                {{ (parseInt(project?.total?.spent) / parseInt(project?.total?.clicks)).toLocaleString() }} ₽
                            </span>
                        </div>
                    </div>

                    <div class="flex flex-col p-8 rounded-2xl h-24 group-hover:h-36 transition-height ease-out">
                        <div class="h-0 group-hover:h-4 transition-height">
                            <div class="text-xs text-slate-400 opacity-0 group-hover:opacity-100 transition">ЛИДЫ</div>
                        </div>
                        <div class="num text-2xl latolight">{{ parseInt(project?.total?.regs).toLocaleString() }}</div>
                        <div class="flex flex-col opacity-0 group-hover:opacity-100 group-hover:h-auto text-slate-400 transition">
                            <span class="text-xs">
                                CPL
                                {{ (parseInt(project?.total?.spent) / parseInt(project?.total?.regs)).toLocaleString() }} ₽
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="text-slate-400 text-sm text-center my-8">КАНАЛЫ</h2>
            <div class="grid grid-cols-5 gap-4">
                <div class="fly2 f p-4">
                    <StatsCard
                        :title="'vk'"
                        :spent="parseInt(counter_vk)"
                        :views="parseInt(project?.vk?.views)"
                        :clicks="parseInt(project?.vk?.clicks)"
                        circleColor="rgba(60, 160, 255, 0.05)"
                    />
                </div>

                <div class="fly3 f p-4">
                    <StatsCard
                        :title="'vkads'"
                        :spent="parseInt(counter_vkads)"
                        :views="parseInt(project?.vkads?.views)"
                        :clicks="parseInt(project?.vkads?.clicks)"
                        circleColor="rgba(0, 210, 230, 0.05)"
                    />
                </div>

                <div class="fly2 f p-4">
                    <StatsCard
                        :title="'market'"
                        :spent="parseInt(counter_market)"
                        :views="parseInt(project?.market?.views)"
                        :clicks="parseInt(project?.market?.clicks)"
                        circleColor="rgba(220, 40, 180, 0.05)"
                    />
                </div>

                <div class="fly4 f p-4">
                    <StatsCard
                        :title="'book'"
                        :spent="parseInt(counter_facebook)"
                        :views="parseInt(project?.book?.views)"
                        :clicks="parseInt(project?.book?.clicks)"
                        circleColor="rgba(80, 100, 255, 0.05)"
                    />
                </div>

                <div class="fly3 f p-4">
                    <StatsCard
                        :title="'yandex'"
                        :spent="parseInt(counter_yandex)"
                        :views="parseInt(project?.yandex?.views)"
                        :clicks="parseInt(project?.yandex?.clicks)"
                        circleColor="rgba(255, 220, 40, 0.08)"
                    />
                </div>
            </div>

            <h2 class="text-slate-400 text-sm text-center mt-20">ГРАФИК РАСХОДА</h2>
            <div v-if="project?.grafs?.spent" class="w-full"><LineGraf :graf="project?.grafs?.spent" name="spent" h="h-48" /></div>

            <!-- <h2 class="text-slate-400 text-sm text-center mt-20">ГРАФИК КЛИКОВ</h2>
            <div v-if="project?.grafs?.clicks" class="w-full"><LineGraf :graf="project?.grafs?.clicks" name="clicks" h="h-48" /></div> -->

            <h2 class="text-slate-400 text-sm text-center mt-20">ГРАФИК ЛИДОВ</h2>
            <div v-if="project?.grafs?.regs" class="w-full"><LineGraf :graf="project?.grafs?.regs" name="regs" h="h-48" /></div>

            <h2 class="text-slate-400 text-sm text-center mt-20">ВОРОНКА ПО КАНАЛАМ</h2>
            <div class="w-full grid grid-cols-10 justify-center items-center mb-8 latolight">
                <div class="flex flex-col justify-center items-center aspect-square bg-white p-8 rounded-2xl">
                    <div class="text-blue-500"><IVk :size="10" /></div>
                    <!-- <span class="latolight">vk</span> -->
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(counter_vk).toLocaleString() }}</div>
                    <div class="text-xs text-slate-400">ОТКРУТ ₽</div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vk?.ads).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ОБЪЯВЛЕНИЯ<IVkAd :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vk?.views).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ПРОСМОТРЫ<IconEye :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vk?.clicks).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">КЛИКИ<IconClick :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vk?.regs).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ЛИДЫ<IconUser :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt((project?.vk?.spent * 1000) / project?.vk?.views).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPM</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vk?.spent / project?.vk?.clicks).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPC</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseFloat(project?.vk?.clicks / project?.vk?.views).toFixed(2) }}</div>
                    <span class="text-xs text-slate-400">CTR</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vk?.spent / project?.vk?.regs).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPL</span>
                </div>
            </div>

            <div class="w-full grid grid-cols-10 justify-center items-center mb-8 latolight">
                <div class="flex flex-col justify-center items-center aspect-square bg-white p-8 rounded-2xl">
                    <div class="text-blue-600"><IVkAds :size="8" /></div>
                    <!-- <span class="latolight">vk</span> -->
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(counter_vkads).toLocaleString() }}</div>
                    <div class="text-xs text-slate-400">ОТКРУТ ₽</div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vkads?.ads).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ОБЪЯВЛЕНИЯ<IVkAd :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vkads?.views).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ПРОСМОТРЫ<IconEye :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vkads?.clicks).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">КЛИКИ<IconClick :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vkads?.regs).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ЛИДЫ<IconUser :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt((project?.vkads?.spent * 1000) / project?.vkads?.views).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPM</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vkads?.spent / project?.vkads?.clicks).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPC</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseFloat(project?.vkads?.clicks / project?.vkads?.views).toFixed(2) }}</div>
                    <span class="text-xs text-slate-400">CTR</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vkads?.spent / project?.vkads?.regs).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPL</span>
                </div>
            </div>

            <div v-if="project?.market?.spent > 0" class="w-full grid grid-cols-10 justify-center items-center mb-8 latolight">
                <div class="flex flex-col justify-center items-center aspect-square bg-white p-8 rounded-2xl">
                    <div class="text-pink-600"><IVkCamp :size="10" /></div>
                    <!-- <span class="latolight">vk</span> -->
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(counter_market).toLocaleString() }}</div>
                    <div class="text-xs text-slate-400">ОТКРУТ ₽</div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.market?.ads).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ОБЪЯВЛЕНИЯ<IVkAd :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.market?.views).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ПРОСМОТРЫ<IconEye :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.market?.clicks).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">КЛИКИ<IconClick :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.market?.regs).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ЛИДЫ<IconUser :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">
                        {{ parseInt((project?.market?.spent * 1000) / project?.market?.views).toLocaleString() }}
                    </div>
                    <span class="text-xs text-slate-400">CPM</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.market?.spent / project?.market?.clicks).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPC</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseFloat(project?.market?.clicks / project?.market?.views).toFixed(2) }}</div>
                    <span class="text-xs text-slate-400">CTR</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.market?.spent / project?.market?.regs).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPL</span>
                </div>
            </div>

            <div v-if="project?.facebook?.spent > 0" class="w-full grid grid-cols-10 justify-center items-center mb-8 latolight">
                <div class="flex flex-col justify-center items-center aspect-square bg-white p-8 rounded-2xl">
                    <div><IFb :size="10" /></div>
                    <!-- <span class="latolight">vk</span> -->
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(counter_facebook).toLocaleString() }}</div>
                    <div class="text-xs text-slate-400">ОТКРУТ ₽</div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.facebook?.ads).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ОБЪЯВЛЕНИЯ<IVkAd :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.facebook?.views).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ПРОСМОТРЫ<IconEye :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.facebook?.clicks).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">КЛИКИ<IconClick :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.facebook?.regs).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ЛИДЫ<IconUser :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">
                        {{ parseInt((project?.facebook?.spent * 1000) / project?.facebook?.views).toLocaleString() }}
                    </div>
                    <span class="text-xs text-slate-400">CPM</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.facebook?.spent / project?.facebook?.clicks).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPC</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseFloat(project?.facebook?.clicks / project?.facebook?.views).toFixed(2) }}</div>
                    <span class="text-xs text-slate-400">CTR</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.facebook?.spent / project?.facebook?.regs).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPL</span>
                </div>
            </div>

            <div v-if="project?.yandex?.spent > 0" class="w-full flex justify-between items-center gap-8 latolight">
                <div class="flex flex-col justify-center items-center aspect-square bg-white p-8 rounded-2xl">
                    <div class="p-2"><IYa :size="8" /></div>
                    <!-- <span class="latolight">vk</span> -->
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(counter_yandex).toLocaleString() }}</div>
                    <div class="text-xs text-slate-400">ОТКРУТ ₽</div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.yandex?.ads).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ОБЪЯВЛЕНИЯ<IVkAd :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.yandex?.views).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ПРОСМОТРЫ<IconEye :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.yandex?.clicks).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">КЛИКИ<IconClick :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.yandex?.regs).toLocaleString() }}</div>
                    <div class="flex justify-center items-center gap-2 text-xs text-slate-400">ЛИДЫ<IconUser :size="3" /></div>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">
                        {{ parseInt((project?.yandex?.spent * 1000) / project?.yandex?.views).toLocaleString() }}
                    </div>
                    <span class="text-xs text-slate-400">CPM</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.yandex?.spent / project?.yandex?.clicks).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPC</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseFloat(project?.vk?.clicks / project?.vk?.views).toFixed(2) }}</div>
                    <span class="text-xs text-slate-400">CTR</span>
                </div>

                <div class="flex flex-col text-center">
                    <div class="num text-2xl">{{ parseInt(project?.vk?.spent / project?.vk?.regs).toLocaleString() }}</div>
                    <span class="text-xs text-slate-400">CPL</span>
                </div>
            </div>

            <h2 class="text-slate-400 text-sm text-center mt-10 mb-10">КАБИНЕТЫ</h2>
            <div class="mb-4 flex flex-wrap justify-start items-center gap-2 text-slate-500">
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData.month" value="spent" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData.month === 'spent' }">Расход</div>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData.month" value="views" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData.month === 'views' }">Просмотры</div>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData.month" value="clicks" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData.month === 'clicks' }">Клики</div>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData.month" value="cpm" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData.month === 'cpm' }">CPM</div>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData.month" value="cpc" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData.month === 'cpc' }">CPC</div>
                </label>
                <!-- <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData.month" value="ads" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData.month === 'ads' }">Объявления</div>
                </label> -->
            </div>

            <div class="grid grid-cols-3 gap-4">
                <div v-if="clients?.vk?.length > 0">
                    <div v-for="client in clients?.vk" :key="client" class="py-px w-full group hover:py-1 relative duration-500">
                        <div
                            class="grid grid-cols-4 items-center gap-4 bg-white/50 text-slate-500 hover:bg-cyan-50 hover:text-cyan-600 py-2 px-4 rounded"
                        >
                            <div class="flex items-center gap-2 col-span-3">
                                <div class="text-blue-500"><IVk :size="5" /></div>
                                <div>
                                    <div class="text-xs text-slate-300">{{ client.id }}</div>
                                    <div class="leading-4">{{ client.name }}</div>
                                </div>
                            </div>

                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(client.value).toLocaleString() }}
                                <span v-if="selectedData.month == 'spent'">₽</span>
                                <span v-if="selectedData.month == 'views'"><IconEye :size="3" /></span>
                                <span v-if="selectedData.month == 'clicks'"><IconClick :size="3" /></span>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="clients?.vkads?.length > 0">
                    <div v-for="client in clients?.vkads" :key="client" class="py-px w-full group hover:py-1 relative duration-500">
                        <div
                            class="grid grid-cols-4 items-center gap-4 bg-white/50 text-slate-500 hover:bg-cyan-50 hover:text-cyan-600 py-2 px-4 rounded"
                        >
                            <div class="flex items-center gap-2 col-span-3">
                                <div class="text-blue-500"><IVkAds :size="4" /></div>
                                <div>
                                    <div class="text-xs text-slate-300">{{ client.id }}</div>
                                    <div class="leading-4">{{ client.name }}</div>
                                </div>
                            </div>

                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(client.value).toLocaleString() }}
                                <span v-if="selectedData.month == 'spent'">₽</span>
                                <span v-if="selectedData.month == 'views'"><IconEye :size="3" /></span>
                                <span v-if="selectedData.month == 'clicks'"><IconClick :size="3" /></span>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="clients?.market?.length > 0">
                    <div v-for="client in clients?.market" :key="client" class="py-px w-full group hover:py-1 relative duration-500">
                        <div
                            class="grid grid-cols-4 items-center gap-4 bg-white text-slate-600 hover:bg-cyan-50 hover:text-cyan-600 py-2 px-4 rounded"
                        >
                            <div class="flex items-center gap-4 col-span-3">
                                <div class="text-pink-600"><IVkCamp :size="5" /></div>
                                <div>
                                    <div class="text-xs text-slate-300">{{ client.id }}</div>
                                    <div class="leading-4">{{ client.name }}</div>
                                </div>
                            </div>

                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(client.value).toLocaleString() }}
                                <span v-if="selectedData.month == 'spent'">₽</span>
                                <span v-if="selectedData.month == 'views'"><IconEye :size="3" /></span>
                                <span v-if="selectedData.month == 'clicks'"><IconClick :size="3" /></span>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="clients?.facebook?.length > 0">
                    <div v-for="client in clients?.facebook" :key="client" class="py-px w-full group hover:py-1 relative duration-500">
                        <div
                            class="grid grid-cols-4 items-center gap-4 bg-white/50 text-slate-500 hover:bg-cyan-50 hover:text-cyan-600 py-2 px-4 rounded"
                        >
                            <div class="flex items-center gap-2 col-span-3">
                                <IFb :size="5" />
                                <div>
                                    <div class="text-xs text-slate-300">{{ client.id }}</div>
                                    <div class="leading-4">{{ client.name }}</div>
                                </div>
                            </div>

                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(client.value).toLocaleString() }}
                                <span v-if="selectedData.month == 'spent'">₽</span>
                                <span v-if="selectedData.month == 'views'"><IconEye :size="3" /></span>
                                <span v-if="selectedData.month == 'clicks'"><IconClick :size="3" /></span>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="clients?.yandex?.length > 0">
                    <div v-for="client in clients?.yandex" :key="client" class="py-px w-full group hover:py-1 relative duration-500">
                        <div
                            class="grid grid-cols-4 items-center gap-4 bg-white/50 text-slate-500 hover:bg-cyan-50 hover:text-cyan-600 py-2 px-4 rounded"
                        >
                            <div class="flex items-center gap-2 col-span-3">
                                <div class="p-1"><IYa :size="4" /></div>
                                <div>
                                    <div class="text-xs text-slate-300">{{ client.id }}</div>
                                    <div class="leading-4">{{ client.name }}</div>
                                </div>
                            </div>

                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(client.value).toLocaleString() }}
                                <span v-if="selectedData.month == 'spent'">₽</span>
                                <span v-if="selectedData.month == 'views'"><IconEye :size="3" /></span>
                                <span v-if="selectedData.month == 'clicks'"><IconClick :size="3" /></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="project?.tgs">
                <h2 class="text-slate-400 text-sm text-center mt-20 mb-4">ТАРГЕТОЛОГИ</h2>

                <div class="w-2/3 m-auto">
                    <div
                        v-for="tg in project?.tgs"
                        :key="tg"
                        class="w-full rounded-xl h-24 overflow-hidden relative pt-2 text-slate-500 group hover:my-1 duration-500"
                    >
                        <div class="absolute bottom-0 z-50">
                            <img
                                v-if="tg.picture"
                                :src="'https://api.timekraken.ru/uploads/users/u' + tg.id + '.png'"
                                :alt="tg.name"
                                class="w-full h-[88px] object-contain group-hover:h-24 duration-300"
                            />
                            <img
                                v-else
                                :src="'https://api.timekraken.ru/uploads/users/u0.png'"
                                :alt="tg.name"
                                class="w-full h-[88px] object-contain group-hover:h-24 duration-300 opacity-30"
                            />
                        </div>

                        <div class="bg-white rounded-xl h-20 grid grid-cols-9 justify-center items-center gap-2 mt-4">
                            <div></div>
                            <div class="text-sm font-light text-center">{{ tg.name }}&nbsp;{{ tg.secondname.slice(0, 1) }}.</div>

                            <div class="flex justify-end items-center gap-1 text-cyan-500 col-span-2">
                                <span class="text-3xl group-hover:text-4xl duration-300 font-bold">{{ parseInt(tg.spent).toLocaleString() }}</span>
                                <sup>₽</sup>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">{{ parseInt(tg.ads).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">ОБЪЯВЛЕНИЯ</span>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">{{ parseInt(tg.views).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">ПРОСМОТРЫ</span>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">{{ parseInt(tg.clicks).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">КЛИКИ</span>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">{{ parseInt(tg.regs).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">
                                    {{ parseInt(parseInt(tg.spent) / parseInt(tg.regs)).toLocaleString() }}
                                </div>
                                <span class="text-[8px] text-slate-400">CPL</span>
                            </div>
                        </div>

                        <!-- <div class="flex flex-col items-center col-span-2">
                                <div>{{ tg.name }} {{ tg.secondname.slice(0, 1) }}.</div>
                            </div>

                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(tg.spent).toLocaleString() }} ₽
                            </span>
                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(tg.views).toLocaleString() }}<IconEye :size="3" />
                            </span>
                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(tg.clicks).toLocaleString() }}<IconClick :size="3" />
                            </span>
                            <span class="flex justify-end items-center gap-2 text-right text-cyan-600">
                                {{ parseInt(tg.regs).toLocaleString() }}<IconClick :size="3" />
                            </span> -->
                    </div>
                </div>
            </template>

            <template v-if="project?.ads">
                <!-- {{ project?.ads }} -->
                <h2 class="text-slate-400 text-sm text-center mt-20 mb-4">ОБЪЯВЛЕНИЯ</h2>

                <div class="grid grid-cols-1 md:grid-cols-5 gap-4 mt-4">
                    <div class="w-full gap-2 bg-white flex justify-center items-center flex-col rounded p-4">
                        <div class="text-blue-500"><IVk :size="5" /></div>
                        <div class="title text-center text-sm opacity-50">vk</div>

                        <div class="flex justify-center items-center gap-8 mt-4">
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">{{ parseInt(project?.ads?.vk?.count).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">СОЗДАНО</span>
                            </div>
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">{{ parseInt(project?.ads?.vk?.active).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">АКТИВНО</span>
                            </div>
                        </div>
                    </div>

                    <div class="w-full gap-2 bg-white flex justify-center items-center flex-col rounded p-4">
                        <div class="text-blue-600 p-1"><IVkAds :size="4" /></div>
                        <div class="title text-center text-sm opacity-50">vkads</div>

                        <div class="flex justify-center items-center gap-8 mt-4">
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">{{ parseInt(project?.ads?.vkads?.count).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">СОЗДАНО</span>
                            </div>
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">{{ parseInt(project?.ads?.vkads?.active).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">АКТИВНО</span>
                            </div>
                        </div>
                    </div>

                    <div class="w-full gap-2 bg-white flex justify-center items-center flex-col rounded p-4">
                        <div class="text-pink-600"><IVkCamp :size="5" /></div>
                        <div class="title text-center text-sm opacity-50">market</div>

                        <div class="flex justify-center items-center gap-8 mt-4">
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">{{ parseInt(project?.ads?.market?.count).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">СОЗДАНО</span>
                            </div>
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">
                                    {{ parseInt(project?.ads?.market?.active).toLocaleString() }}
                                </div>
                                <span class="text-[8px] text-slate-400">АКТИВНО</span>
                            </div>
                        </div>
                    </div>

                    <div class="w-full gap-2 bg-white flex justify-center items-center flex-col rounded p-4">
                        <div><IFb :size="5" /></div>
                        <div class="title text-center text-sm opacity-50">book</div>

                        <div class="flex justify-center items-center gap-8 mt-4">
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">
                                    {{ parseInt(project?.ads?.facebook?.count).toLocaleString() }}
                                </div>
                                <span class="text-[8px] text-slate-400">СОЗДАНО</span>
                            </div>
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">
                                    {{ parseInt(project?.ads?.facebook?.active).toLocaleString() }}
                                </div>
                                <span class="text-[8px] text-slate-400">АКТИВНО</span>
                            </div>
                        </div>
                    </div>

                    <div class="w-full gap-2 bg-white flex justify-center items-center flex-col rounded p-4">
                        <div class="p-1"><IYa :size="4" /></div>
                        <div class="title text-center text-sm opacity-50">yandex</div>

                        <div class="flex justify-center items-center gap-8 mt-4">
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">{{ parseInt(project?.ads?.yandex?.count).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">СОЗДАНО</span>
                            </div>
                            <div class="text-center">
                                <div class="text-2xl text-slate-500 leading-3">
                                    {{ parseInt(project?.ads?.yandex?.active).toLocaleString() }}
                                </div>
                                <span class="text-[8px] text-slate-400">АКТИВНО</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useManagerStore } from "@/store/manager";
import moment from "moment";

import FilterComponent from "../components/Filter.vue";
import StatsCard from "@/components/StatsCard.vue";
import LineGraf from "../components/LineGraf.vue";

export default {
    name: "OwnerProject",

    components: { FilterComponent, LineGraf, StatsCard },

    data() {
        return {
            circles: [],
            month: {
                1: "Январь",
                2: "Февраль",
                3: "Март",
                4: "Апрель",
                5: "Май",
                6: "Июнь",
                7: "Июль",
                8: "Август",
                9: "Сентябрь",
                10: "Октябрь",
                11: "Ноябрь",
                12: "Декабрь",
            },
            moment: moment,
            select: null,

            counter_total: 0,
            counter_vk: 0,
            counter_vkads: 0,
            counter_market: 0,
            counter_facebook: 0,
            counter_yandex: 0,
            animationInterval: null,
            selectedData: { project: "map", month: "spent" }, // Инициализируем выбор по умолчанию

            width: window.innerWidth - 160,
            height: window.innerHeight,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "profile", "setPeriod", "setMonth", "setSettings", "clearSettings", "setChannel"]),
        ...mapState(useManagerStore, ["project", "getProject"]),

        clients() {
            if (!this.project) return null;
            let data = {};

            const calculateValue = (data) => {
                if (this.selectedData.month === "cpm") {
                    return this.calculateCPM(data);
                } else if (this.selectedData.month === "cpc") {
                    return this.calculateCPC(data);
                }
                return data[this.selectedData.month];
            };

            const extractFields = (clientsData) => {
                if (!clientsData) return null;
                return clientsData.map((client) => ({
                    id: client.client_id,
                    name: client.name,
                    value: calculateValue(client),
                }));
            };

            data.vk = extractFields(this.project?.vk?.clients);
            data.vkads = extractFields(this.project?.vkads?.clients);
            data.market = extractFields(this.project?.market?.clients);
            data.facebook = extractFields(this.project?.facebook?.clients);
            data.yandex = extractFields(this.project?.yandex?.clients);

            return data;
        },
    },

    methods: {
        update() {
            this.select = null;
            this.getProject();
            this.startAnimation();
        },

        startAnimation() {
            if (!this.project) return null;

            const start = performance.now();
            const duration = 3000; // Длительность анимации в миллисекундах

            const increments = [
                { key: "total", target: this.project?.total?.spent },
                { key: "vk", target: this.project?.vk?.spent },
                { key: "vkads", target: this.project?.vkads?.spent },
                { key: "market", target: this.project?.market?.spent },
                { key: "facebook", target: this.project?.facebook?.spent },
                { key: "yandex", target: this.project?.yandex?.spent },
            ];

            const animate = (timestamp) => {
                const progress = Math.min((timestamp - start) / duration, 1); // Прогресс анимации от 0 до 1

                for (let i = 0; i < increments.length; i++) {
                    const item = increments[i];
                    const counterName = "counter_" + item.key;
                    let currentValue = this[counterName] || 0;

                    if (currentValue !== item.target) {
                        const diff = item.target - currentValue;
                        const change = diff * (1 - Math.pow(1 - progress, 2)); // Постепенное уменьшение скорости изменения
                        currentValue = currentValue + change; // Изменение значения счетчика

                        if (currentValue < 0 && currentValue >= diff) currentValue = 0;

                        this[counterName] = currentValue; // Присваиваем новое значение счетчику
                    }
                }

                if (progress < 1) requestAnimationFrame(animate);
            };

            requestAnimationFrame(animate);
        },

        calculateCPM(data) {
            if (parseInt(data.views) === 0) return 0;
            return ((parseInt(data.spent) / parseInt(data.views)) * 1000).toFixed(2);
        },

        calculateCPC(data) {
            if (parseInt(data.clicks) === 0) return 0;
            return (parseInt(data.spent) / parseInt(data.clicks)).toFixed(2);
        },
    },

    watch: {
        project: {
            handler() {
                this.startAnimation();
            },
            deep: true,
        },
    },

    mounted() {
        this.getProject();
    },
};
</script>

<style scoped>
.sticky-container {
    height: 100vh;
    overflow-y: auto;
}

.titleBlock.shrink {
    height: 50px;
    transition: all 0.5s ease;
}

.circle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; /* Помещаем контейнер на задний план */
}

.fly2 {
    animation: moveUpDown 6s infinite alternate;
}
.fly3 {
    animation: moveUpDown 8s infinite alternate;
}
.fly4 {
    animation: moveUpDown 10s infinite alternate;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

.project-animation {
    animation: moveProject 10s ease-in-out infinite alternate;
}

@keyframes moveProject {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(var(--translate-x), var(--translate-y));
    }
}
</style>
