<template>
    <div class="w-fit bg-white rounded p-4 mb-4">
        <div class="flex justify-between items-center gap-4">
            <div class="flex justify-center items-center text-slate-500 gap-2">
                КАБИНЕТЫ <span class="count">{{ allCabinets?.length }}</span>
            </div>
            <!-- <input type="text" class="input" placeholder="Поиск проектов" v-model="s.findme" @input="setSettings(s)" /> -->
            <!-- <div class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings()">ОЧИСТИТЬ</div> -->
            <!-- <div class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings(), (checkedClients = [])">ОЧИСТИТЬ</div> -->
        </div>
    </div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-6 items-center text-xs text-slate-400 gap-4 py-2 px-4 border-b border-slate-200 uppercase">
            <div>Канал</div>
            <div>ID</div>
            <div class="col-span-4">Название</div>
        </div>

        <template v-if="allCabinets && allCabinets.length > 0">
            <RecycleScroller class="h-[calc(100vh-200px)]" :items="allCabinets" :item-size="48" key-field="id" v-slot="{ item }">
                <div
                    class="h-12 overflow-hidden grid grid-cols-6 items-center gap-4 px-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-700 cursor-pointer"
                >
                    <a
                        v-if="item.channel_id == 1"
                        class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer"
                        target="_blank"
                        :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                    >
                        <IVk />
                    </a>
                    <a
                        v-if="item.channel_id == 2"
                        class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer p-1"
                        target="_blank"
                        href="https://ads.vk.com/hq/dashboard"
                    >
                        <IVkAds :size="6" />
                    </a>
                    <a v-if="item.channel_id == 3" class="text-pink-600 p-1">
                        <IVkCamp :size="7" />
                    </a>
                    <a v-if="item.channel_id == 4" class="">
                        <IFb :size="8" />
                    </a>
                    <a v-if="item.channel_id == 5" class="p-1">
                        <IYa :size="6" />
                    </a>
                    <a v-if="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(item.channel_id)" class="w-6 h-6 m-1 bg-slate-400 rounded-full"> </a>

                    <div>{{ item.account_id }}</div>
                    <div class="col-span-4">{{ item.name }}</div>
                </div>
            </RecycleScroller>
        </template>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAdminStore } from "@/store/admin";

import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import moment from "moment";

export default {
    name: "AdminClientsPage",
    components: { RecycleScroller },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "clearSettings"]),
        ...mapState(useAdminStore, ["cabinets", "getCabinets", "pickCabinet"]),

        allCabinets() {
            if (!this.cabinets) return null;

            let data = this.cabinets;

            if (this.s.findme) data = data.filter((item) => item.name?.toLowerCase().indexOf(this.s.findme.toLowerCase()) !== -1);

            return data;
        },
    },

    mounted() {
        this.getCabinets();
    },
};
</script>
