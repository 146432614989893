<template>
    <div class="flex justify-between items-center mb-4 gap-4">
        <div class="bg-white rounded p-4">
            <div class="flex justify-between items-center gap-4">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    КЛИЕНТЫ <span class="count">{{ allClients?.length }}</span>
                </div>

                <input type="text" class="input" placeholder="Поиск клиентов" v-model="s.findme" @input="setSettings(s)" />

                <input type="text" class="input w-20" placeholder="Лейбл" v-model="s.label" @input="setSettings(s)" />

                <select v-model="s.channel_id" class="bg-white h-9 px-3 py-2 rounded text-sm text-cyan-700" @change="setSettings(s)">
                    <option value="">ВСЕ КАНАЛЫ</option>
                    <template v-for="ch in channels" :key="ch.id">
                        <option :value="ch.id">{{ ch.name }}</option>
                    </template>
                </select>

                <div class="btn btn-empty" @click="toggleNoProject()">БЕЗ&nbsp;ПРОЕКТА</div>

                <div class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings(), (checkedClients = [])">ОЧИСТИТЬ</div>
            </div>
        </div>

        <div v-if="checkedClients?.length > 0" class="bg-white rounded p-4 mb-4">
            <div class="flex justify-between items-center gap-4">
                <span class="count text-slate-500">{{ checkedClients?.length }}</span>

                <div class="btn btn-red" @click="unProjectClient({ clients: checkedClients })">Убрать из проекта</div>
                <div class="btn btn-green" @click="setPopup('addProjectToClients')">Добавить в проект</div>
            </div>
        </div>
    </div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-6 items-center text-xs text-slate-400 gap-4 py-2 px-4 border-b border-slate-200 uppercase">
            <div class="flex items-center gap-4">
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">
                    <input type="checkbox" id="checkAll" class="mychbox" @click="checkAll()" />
                    <label for="checkAll"></label>
                </div>
                <div>Канал</div>
            </div>
            <div>ID</div>
            <div class="col-span-2">Название клиента</div>
            <div class="col-span-2">Проект</div>
        </div>

        <template v-if="allClients">
            <RecycleScroller class="h-[calc(100vh-200px)]" :items="allClients" :item-size="48" key-field="id" v-slot="{ item }">
                <div
                    class="h-12 overflow-hidden grid grid-cols-6 items-center gap-4 px-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-700"
                >
                    <div class="flex items-center gap-4" @click="checkOne(item, $event)">
                        <input type="checkbox" :id="item.id" :value="item.id" class="mychbox" :checked="checkedClients.includes(item)" />

                        <a
                            v-if="item.channel_id == 1"
                            class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer"
                            target="_blank"
                            :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                        >
                            <IVk />
                        </a>
                        <a
                            v-if="item.channel_id == 2"
                            class="text-2xl text-cyan-600 font-bold text-right tabular-nums cursor-pointer p-1"
                            target="_blank"
                            href="https://ads.vk.com/hq/dashboard"
                        >
                            <IVkAds :size="6" />
                        </a>
                        <a
                            v-if="item.channel_id == 3"
                            class="text-2xl text-pink-600 font-bold text-right tabular-nums cursor-pointer p-1"
                            target="_blank"
                            href="https://ads.vk.com/hq/dashboard"
                        >
                            <IVkCamp :size="7" />
                        </a>
                        <a
                            v-if="item.channel_id == 4"
                            class="text-2xl font-bold text-right tabular-nums cursor-pointer"
                            target="_blank"
                            href="https://ads.vk.com/hq/dashboard"
                        >
                            <IFb :size="8" />
                        </a>
                        <a
                            v-if="item.channel_id == 5"
                            class="text-2xl font-bold text-right tabular-nums cursor-pointer p-1"
                            target="_blank"
                            href="https://ads.vk.com/hq/dashboard"
                        >
                            <IYa :size="6" />
                        </a>

                        <div>{{ item.label }}</div>
                    </div>

                    <span>{{ item.id }}</span>

                    <div class="col-span-2">{{ item.name }}</div>

                    <div v-if="item.project_id" class="col-span-2">
                        <span class="text-slate-400">#{{ item.project_id }}</span>
                        {{ item.project_name }}
                    </div>
                    <div v-else class="col-span-2 text-slate-400">---</div>
                </div>
            </RecycleScroller>
        </template>
    </div>

    <template v-if="s && s.popup == 'addProjectToClients' && checkedClients?.length > 0">
        <div class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">ДОБАВИТЬ В ПРОЕКТ</h3>

            <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    ВЫБРАНО <span class="count">{{ checkedClients?.length }}</span>
                </div>
                <div class="h-32 overflow-hidden text-xs">
                    <div v-for="client in checkedClients" :key="client" class="grid grid-cols-3">{{ client.id }} {{ client.name }}</div>
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Введите название проекта</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" @input="findP()" v-model="findProject" />
                </div>

                <template v-if="projectList?.length > 0">
                    <div class="grid grid-cols-8 items-center text-left text-xs text-slate-400 gap-4 p-2 border-b border-slate-200 uppercase">
                        <div>ID</div>
                        <div class="col-span-5">Название проекта</div>
                        <div class="text-right col-span-2">Клиентов</div>
                    </div>

                    <div
                        v-for="pro in projectList.slice(0, 5)"
                        :key="pro"
                        @click="setCheckedProject(pro)"
                        class="grid grid-cols-8 items-center gap-4 cursor-pointer text-left text-slate-500 p-2 border-b border-slate-200 hover:bg-cyan-50/50 hover:text-cyan-600"
                    >
                        <div class="text-xs">{{ pro.id }}</div>
                        <div class="col-span-5">{{ pro.name }}</div>
                        <div class="text-xs text-right col-span-2">{{ pro.count }}</div>
                    </div>
                </template>

                <div v-if="checkedProject?.id > 0" class="flex flex-col justify-center items-center p-8">
                    <div class="text-center text-cyan-600">
                        Вы хотите добавить <span class="count">{{ checkedClients?.length }}</span> клиентов в проект "{{ checkedProject.name }}"?
                    </div>

                    <div
                        class="btn btn-green text-sm py-2 my-4 w-60"
                        @click="addProjectToClients({ project_id: checkedProject.id, clients: checkedClients }), setPopup('')"
                    >
                        ДОБАВИТЬ
                    </div>
                </div>
            </div>
        </div>
    </template>

    <div v-if="s && s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useManagerStore } from "@/store/manager";

import moment from "moment";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
    name: "ManagerClients",

    components: { RecycleScroller },

    data() {
        return {
            moment: moment,
            findProject: "",
            projectList: [],
            checkedProject: {},
            checkedClients: [],
            lastCheckedItem: null, // Последний выбранный элемент для SHIFT
            channels: [
                { id: 1, name: "VK" },
                { id: 2, name: "VK ADS" },
                { id: 3, name: "VK MARKET" },
                { id: 4, name: "FACEBOOK" },
                { id: 5, name: "YANDEX" },
            ],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "clearSettings", "toggleNoProject"]),
        ...mapState(useManagerStore, ["clients", "projects", "getClients", "getProjects", "addProjectToClients", "unProjectClient"]),

        allClients() {
            if (!this.clients) return null;

            // const projects = {};
            // this.projects.forEach((item) => (projects[item.id] = item.name));

            let data = this.clients;
            // Object.keys(this.clients).forEach((channel_id) => {
            //     if (Array.isArray(this.clients[channel_id])) {
            //         data.push(...this.clients[channel_id].map((item) => ({ ...item, channel_id, project: projects[item.project_id] })));
            //     }
            // });

            // Фильтрация по имени
            if (this.s.findme) data = data.filter((item) => item.name?.toLowerCase().indexOf(this.s.findme.toLowerCase()) !== -1);
            if (this.s.label) data = data.filter((item) => item.label?.toLowerCase().indexOf(this.s.label.toLowerCase()) !== -1);
            if (this.s.channel_id) data = data.filter((item) => item.channel_id == this.s.channel_id);
            if (this.s.noProject) data = data.filter((item) => !item.project_id);

            return data;
        },
    },

    methods: {
        findP() {
            if (!this.projects || this.findProject == "" || this.findProject == undefined) return null;
            this.projectList = this.projects.filter((project) => project.name.toLowerCase().indexOf(this.findProject.toLowerCase()) !== -1);
        },

        checkOne(item, event) {
            if (event.shiftKey && this.lastCheckedItem) {
                // console.log("lastCheckedItem", this.lastCheckedItem);

                // Получаем индексы текущего и последнего выбранных элементов
                const currentIndex = this.allClients.findIndex((client) => client.id === item.id);
                const lastIndex = this.allClients.findIndex((client) => client.id === this.lastCheckedItem.id);
                // console.log("currentIndex", currentIndex);
                // console.log("lastIndex", lastIndex);

                // Определяем начальный и конечный индексы для выборки
                const start = Math.min(currentIndex, lastIndex);
                const end = Math.max(currentIndex, lastIndex);
                // console.log("start", start);
                // console.log("end", end);

                // Выделяем все элементы между начальным и конечным индексами
                for (let i = start; i <= end; i++) {
                    if (!this.checkedClients.includes(this.allClients[i])) {
                        this.checkedClients.push(this.allClients[i]);
                    }
                }
            } else {
                // Если Shift не нажата или lastCheckedItem не определен, то просто выделяем текущий элемент
                if (!this.checkedClients.includes(item)) this.checkedClients.push(item);
            }

            // Обновляем последний выбранный элемент
            this.lastCheckedItem = item;
        },

        checkAll() {
            if (this.checkedClients.length === this.allClients.length) {
                this.checkedClients = [];
            } else {
                this.checkedClients = this.allClients;
            }
        },

        setCheckedProject(pro) {
            this.checkedProject = { id: pro.id, name: pro.name };
            this.projectList = null;
        },
    },

    mounted() {
        this.getClients();
        this.getProjects();
    },
};
</script>
