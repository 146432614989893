<template>
    <div class="flex flex-col justify-center items-center relative z-20 group">
        <div v-if="title == 'vk'" class="text-cyan-500 group-hover:text-blue-500 group-hover:scale-110 duration-300"><IVk :size="8" /></div>
        <div v-if="title == 'vkads'" class="text-cyan-500 group-hover:text-blue-500 group-hover:scale-110 duration-300"><IVkAds :size="8" /></div>
        <div v-if="title == 'market'" class="text-cyan-500 group-hover:text-pink-500 group-hover:scale-110 duration-300"><IMarket :size="8" /></div>
        <div v-if="title == 'book'" class="text-cyan-500 group-hover:text-indigo-500 group-hover:scale-110 duration-300"><IFb :size="8" /></div>
        <div v-if="title == 'yandex'" class="text-cyan-500 group-hover:text-red-500 group-hover:scale-110 duration-300"><IYa :size="8" /></div>
        <div v-if="title == 'google'" class="text-cyan-500 group-hover:text-yellow-400 group-hover:scale-110 duration-300"><IGg :size="8" /></div>
        <div v-if="title == 'tg'" class="text-cyan-500 group-hover:text-green-500 group-hover:scale-110 duration-300"><ITgads :size="8" /></div>
        <div v-if="title == 'tgads'" class="text-cyan-500 group-hover:text-green-500 group-hover:scale-110 duration-300"><ITgads :size="8" /></div>
        <div v-if="title == 'youtube'" class="text-cyan-500 group-hover:text-red-500 group-hover:scale-110 duration-300"><IYoutube :size="8" /></div>
        <div v-if="title == 'base'" class="text-cyan-500 group-hover:text-green-500 group-hover:scale-110 duration-300"><IBase :size="8" /></div>
        <div v-if="title == 'none'" class="text-cyan-500 group-hover:text-slate-500 group-hover:scale-110 duration-300"><IconClose :size="8" /></div>

        <div v-if="title == '101'" class="title text-center text-sm opacity-50 mb-2">sblv</div>
        <div v-if="title == '102'" class="title text-center text-sm opacity-50 mb-2">sv</div>
        <div v-else class="title text-center text-sm opacity-50 mb-2">{{ title }}</div>

        <span v-if="data?.spent" class="text-lg text-cyan-500 group-hover:text-pink-500">
            <span class="latobold"> {{ parseInt(data?.spent).toLocaleString() }}</span>
            <sup>&nbsp;₽</sup>
        </span>
        <div v-else class="text-slate-400">-</div>

        <template v-if="data?.payments">
            <span class="text-xl text-cyan-500 group-hover:text-teal-500">
                <span class="latobold"> {{ parseInt(data?.payments).toLocaleString() }}</span>
                <sup>&nbsp;₽</sup>
            </span>

            <div v-if="data?.romi" class="text-md text-cyan-500" :class="{ '!text-pink-500': data.romi < 0 }">{{ parseInt(data.romi) }}%</div>
            <div v-else class="text-slate-400">-</div>
        </template>
    </div>

    <div class="circle-container"><CircleS v-for="index in 3" :key="index" :color="getCircleColor(title)" /></div>
</template>
<script>
import CircleS from "./circle.vue";

export default {
    components: { CircleS },

    data() {
        return {
            circleColors: {
                vk: "rgba(60, 160, 255, 0.025)",
                vkads: "rgba(0, 210, 230, 0.025)",
                market: "rgba(220, 40, 180, 0.025)",
                book: "rgba(80, 100, 255, 0.025)",
                yandex: "rgba(255, 220, 40, 0.04)",
                google: "rgba(0, 0, 255, 0.02)",
                tg: "rgba(40, 255, 80, 0.04)",
                tgads: "rgba(40, 255, 80, 0.04)",
                youtube: "rgba(255, 0, 0, 0.025)",
                base: "rgba(60, 160, 255, 0.025)",
                none: "rgba(150, 150, 150, 0.025)",
            },
        };
    },

    props: {
        title: String,
        data: Object,
        spent: Number,
        payments: Number,
    },

    methods: {
        getCircleColor(title) {
            // Проверяем, есть ли цвет для указанного title в объекте circleColors
            if (this.circleColors[title]) {
                return this.circleColors[title];
            } else {
                const randomColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
                    Math.random() * 256
                )}, 0.025)`;
                return randomColor;
            }
        },
    },
};
</script>

<style scoped>
.circle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Помещаем контейнер на задний план */
    pointer-events: none; /* Круги не мешают взаимодействию с другими элементами */
}
</style>
