<template>
    <div class="bg-white rounded p-4 mb-4">
        <div class="flex justify-between items-center gap-4">
            <div>ОТЧЕТ ПО КРЕАТИВАМ</div>
            <div class="btn btn-blue gap-2" @click="exportGroups()">СКАЧАТЬ <IcoBig name="export" /></div>
        </div>
    </div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 py-2 px-4 border-b border-slate-200 uppercase">
            <div>КАНАЛ</div>
            <div class="col-span-2">КАБИНЕТ</div>
            <div>ID</div>
            <div class="col-span-2">НАЗВАНИЕ</div>
            <div>ID КАМПАНИИ</div>
            <div>UTM</div>
            <div>ФОРМАТ</div>
            <div>РАСХОД</div>
            <div>СТАТУС</div>
        </div>

        <RecycleScroller class="h-[calc(100vh-200px)]" :items="report" :item-size="48" key-field="id" v-slot="{ item }">
            <div
                class="h-12 overflow-hidden grid grid-cols-11 items-center gap-4 px-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-700"
            >
                <div v-if="item.channel_id == 1" class="text-cyan-600"><IVk :size="8" /></div>
                <div v-if="item.channel_id == 2" class="text-cyan-600 p-1"><IVkAds :size="6" /></div>
                <div v-if="item.channel_id == 3" class="text-pink-600 p-1"><IVkCamp :size="6" /></div>
                <div v-if="item.channel_id == 4" class=""><IFb :size="8" /></div>
                <div v-if="item.channel_id == 5" class="p-1"><IYa :size="6" /></div>

                <div class="overflow-hidden col-span-2">{{ item.client_name }}</div>
                <div class="overflow-hidden">{{ item.id }}</div>
                <div class="overflow-hidden col-span-2">{{ item.name }}</div>
                <div class="overflow-hidden">{{ item.campaign_id }}</div>
                <div class="overflow-hidden">{{ item.utm }}</div>
                <div class="overflow-hidden">{{ item.format }}</div>
                <div class="overflow-hidden">{{ item.spent }}</div>
                <div class="overflow-hidden">{{ item.status }}</div>
            </div>
        </RecycleScroller>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useManagerStore } from "@/store/manager";

import moment from "moment";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { utils, writeFileXLSX } from "xlsx";

export default {
    name: "ManagerReportAd",

    components: { RecycleScroller },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "profile"]),
        ...mapState(useManagerStore, ["report", "getReportGroup"]),
    },

    methods: {
        exportGroups() {
            let wscols = [
                { wch: 10 }, // КАНАЛ
                { wch: 15 }, // ID
                { wch: 15 }, // ID КАБИНЕТА
                { wch: 20 }, // КАБИНЕТ
                { wch: 15 }, // ID КАМПАНИИ
                { wch: 20 }, // НАЗВАНИЕ
                { wch: 20 }, // UTM
                { wch: 20 }, // ФОРМАТ
                { wch: 20 }, // РАСХОД
                { wch: 10 }, // СТАТУС
            ];

            let list = [];
            this.report.forEach((item) => {
                let object = {
                    channel_id: item.channel_id,
                    id: item.ad_id,
                    client_id: item.client_id,
                    client_name: item.client_name,
                    campaign_id: item.campaign_id,
                    name: item.name,
                    utm: item.utm,
                    format: item.format,
                    spent: item.spent,
                    status: item.status,
                };

                if (item.channel_id == 1) object.channel_id = "Vk";
                if (item.channel_id == 2) object.channel_id = "Vkads";
                if (item.channel_id == 3) object.channel_id = "Vk Market";
                if (item.channel_id == 4) object.channel_id = "Facebook";
                if (item.channel_id == 5) object.channel_id = "Yandex";

                list.push(object);
            });

            let worksheet = utils.json_to_sheet(list);
            worksheet = utils.sheet_add_aoa(
                worksheet,
                [["КАНАЛ", "ID", "ID КАБИНЕТА", "КАБИНЕТ", "ID КАМПАНИИ", "НАЗВАНИЕ", "UTM", "ФОРМАТ", "РАСХОД", "СТАТУС"]],
                {
                    origin: "A1",
                }
            );
            worksheet["!cols"] = wscols;
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "groups");
            writeFileXLSX(workbook, "ads.xlsx", { compression: true });
        },
    },

    mounted() {
        this.getReportGroup();
    },
};
</script>
