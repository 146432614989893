import { createRouter, createWebHistory } from "vue-router";
import { useProfileStore as profileStore } from "@/store/profile";
import authService from "./auth";

import MainPage from "@/Main/Main";
import ProfilePage from "@/Main/Profile";

import AdminMain from "@/Admin/Admin";
import AdminMonitor from "@/Admin/Monitor";
import AdminUsers from "@/Admin/Users";
import OwnerStat from "@/Owner/Stat";
import OwnerCabinets from "@/Owner/Cabinets";
import OwnerProjects from "@/Owner/Projects";
import OwnerProject from "@/Owner/Project";
import OwnerMonth from "@/Owner/Month";
import OwnerTargetologs from "@/Owner/Targetologs";
import OwnerRoadmap from "@/Owner/Roadmap";

import ManagerProjects from "@/Manager/Projects";
import ManagerProject from "@/Manager/Project";
import ManagerClients from "@/Manager/Clients";
import ManagerCabinets from "@/Manager/Cabinets";
import ManagerTargetologs from "@/Manager/Targetologs";
import ManagerReportGroup from "@/Manager/ReportGroup";
import ManagerReportAd from "@/Manager/ReportAd";

// import TargetologProjects from "@/Targetolog/Projects";

import ClientProjects from "@/Client/Projects";
import ClientSettings from "@/Client/Settings";
import ClientDashboard from "@/Client/Dashboard";
import ClientClients from "@/Client/Clients";
import ClientMonth from "@/Client/Month";
import ClientDays from "@/Client/Days";
import ClientWebinars from "@/Client/Webinars";
import ClientAds from "@/Client/Ads";
import ClientFunnel from "@/Client/Funnel";

const routes = [
    // MAIN
    {
        path: "/",
        name: "Main",
        component: MainPage,
        meta: { title: "Аналитика" },
    },
    {
        path: "/profile",
        name: "Profile",
        component: ProfilePage,
        meta: { title: "Профиль" },
    },

    // ADMIN
    {
        path: "/admin",
        name: "Admin",
        component: AdminMain,
        meta: { title: "Админ" },
    },
    {
        path: "/admin/monitor",
        name: "AdminMonitor",
        component: AdminMonitor,
        meta: { title: "Monitor" },
    },
    {
        path: "/admin/users",
        name: "AdminUsers",
        component: AdminUsers,
        meta: { title: "Users" },
    },

    // OWNER
    {
        path: "/owner/stat",
        name: "OwnerStat",
        component: OwnerStat,
        meta: { title: "Статистика" },
    },
    {
        path: "/owner/cabinets",
        name: "OwnerCabinets",
        component: OwnerCabinets,
        meta: { title: "Кабинеты" },
    },
    {
        path: "/owner/projects",
        name: "OwnerProjects",
        component: OwnerProjects,
        meta: { title: "Проекты" },
    },
    {
        path: "/owner/project",
        name: "OwnerProject",
        component: OwnerProject,
        meta: { title: "Проект" },
    },
    {
        path: "/owner/month",
        name: "OwnerMonth",
        component: OwnerMonth,
        meta: { title: "Месяцы" },
    },
    {
        path: "/owner/targetologs",
        name: "OwnerTargetologs",
        component: OwnerTargetologs,
        meta: { title: "Таргетологи" },
    },
    {
        path: "/owner/roadmap",
        name: "OwnerRoadmap",
        component: OwnerRoadmap,
        meta: { title: "Прогресс" },
    },

    // MANAGER
    {
        path: "/manager/cabinets",
        name: "ManagerCabinets",
        component: ManagerCabinets,
        meta: { title: "Cabinets" },
    },
    {
        path: "/manager/projects",
        name: "ManagerProjects",
        component: ManagerProjects,
        meta: { title: "Projects" },
    },
    {
        path: "/manager/project",
        name: "ManagerProject",
        component: ManagerProject,
        meta: { title: "Project" },
    },
    {
        path: "/manager/clients",
        name: "ManagerClients",
        component: ManagerClients,
        meta: { title: "Clients" },
    },
    {
        path: "/manager/targetologs",
        name: "ManagerTargetologs",
        component: ManagerTargetologs,
        meta: { title: "Targetologs" },
    },
    {
        path: "/manager/report-group",
        name: "ManagerReportGroup",
        component: ManagerReportGroup,
        meta: { title: "ReportGroup" },
    },
    {
        path: "/manager/report-ad",
        name: "ManagerReportAd",
        component: ManagerReportAd,
        meta: { title: "ReportAd" },
    },

    // CLIENT
    {
        path: "/projects",
        name: "ClientProjects",
        component: ClientProjects,
        meta: { title: "Проекты" },
    },
    {
        path: "/settings",
        name: "ClientSettings",
        component: ClientSettings,
        meta: { title: "Настройки" },
    },
    {
        path: "/dashboard",
        name: "ClientDashboard",
        component: ClientDashboard,
        meta: { title: "Дашборд" },
    },
    {
        path: "/clients",
        name: "ClientClients",
        component: ClientClients,
        meta: { title: "Кабинеты" },
    },
    {
        path: "/month",
        name: "ClientMonth",
        component: ClientMonth,
        meta: { title: "Месяца" },
    },
    {
        path: "/days",
        name: "ClientDays",
        component: ClientDays,
        meta: { title: "Дни" },
    },
    {
        path: "/webinars",
        name: "ClientWebinars",
        component: ClientWebinars,
        meta: { title: "Вебинары" },
    },
    {
        path: "/ads",
        name: "ClientAds",
        component: ClientAds,
        meta: { title: "Ads" },
    },
    {
        path: "/funnel",
        name: "ClientFunnel",
        component: ClientFunnel,
        meta: { title: "Funnel" },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach(async (to) => {
//     document.title = to.meta.title || "VK";

//     if (
//         // make sure the user is authenticated
//         to.name !== "Main" &&
//         !authService.isLogged(to)
//     ) {
//         // redirect the user to the login page
//         // this.$router.push('/')
//         return { name: "Main" };
//     }
//     window.scrollTo(0, 0);
// });

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title || "Аналитика";

    // Avoid an infinite redirect & make sure the user is authenticated
    if (to.path !== "/" && !(await authService.isLogged(to))) {
        // console.log("Роутер - нет прав", from);

        // Получаем массив путей из profile.menu
        const menuItems = profileStore().menu.map((item) => item.url);

        // Если есть пункты меню, перенаправляем на первый из них, иначе на главную страницу
        if (menuItems.length > 0) {
            next(menuItems[0]); // Отправляем пользователя на путь из первого элемента меню
        } else {
            next("/"); // Если пунктов меню нет, отправляем на главную страницу
        }
    } else {
        // Если пользователь аутентифицирован или переходит на главную страницу,
        // то продолжаем навигацию к запрошенному маршруту
        // console.log("Роутер - есть права");
        next();
    }
    window.scrollTo(0, 0);
});
export default router;
