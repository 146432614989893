<template>
    <div class="w-full">
        <h2 class="w-full text-center text-slate-400 text-3xl">ПРОЕКТЫ</h2>

        <!-- <div v-for="item in projects" :key="item" class="w-full rounded-xl overflow-hidden relative pt-2 text-slate-500">
            {{ item }}
        </div> -->

        <div
            v-for="item in projects"
            :key="item.id"
            class="w-full rounded-xl overflow-hidden relative pt-2 text-slate-500 group my-1 hover:my-2 duration-500"
        >
            <div
                class="grid grid-cols-2 md:grid-cols-7 justify-center items-center text-lg gap-4 px-4 rounded-xl h-fit md:h-20 bg-white/50 hover:bg-white duration-500"
            >
                <div @click="pickProject(item)" class="text-center md:text-left text-xl col-span-2 p-2 cursor-pointer hover:text-cyan-500">
                    {{ item.name }}
                </div>

                <div class="text-center">
                    <div class="mt-3 h-4 text-pink-500">{{ parseInt(item.spent).toLocaleString() }} <sup> ₽</sup></div>
                    <span class="text-[8px] text-slate-400">РАСХОД</span>
                </div>

                <div class="text-center">
                    <div class="mt-3 h-4 text-pink-500 latolight">- {{ parseInt(item.today_spent).toLocaleString() }} <sup> ₽</sup></div>
                    <span class="text-[8px] text-slate-400">СЕГОДНЯ</span>
                </div>

                <div class="text-center">
                    <div class="mt-3 h-4 text-teal-500">{{ parseInt(item.payment).toLocaleString() }} <sup> ₽</sup></div>
                    <span class="text-[8px] text-slate-400">ОПЛАТЫ</span>
                </div>

                <div class="text-center">
                    <div class="mt-3 h-4 text-teal-500 latolight">+ {{ parseInt(item.today_payment).toLocaleString() }} <sup> ₽</sup></div>
                    <span class="text-[8px] text-slate-400">СЕГОДНЯ</span>
                </div>

                <div class="text-center col-span-2 md:col-span-1">
                    <div v-if="item.spent > 0" class="mt-3 h-4 text-cyan-500">
                        {{ parseInt(((item.payment - item.spent) / item.spent) * 100).toLocaleString() }} %
                    </div>
                    <div v-else class="mt-3 h-4 text-cyan-500">-</div>
                    <span class="text-[8px] text-slate-400">ROMI %</span>
                </div>

                <!-- <div class="text-center">
                    <div class="mt-3 h-4 latolight">{{ parseInt(item.clients?.length).toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">КАБИНЕТЫ</span>
                </div>

                <div class="text-center">
                    <div class="mt-3 h-4 latolight">{{ parseInt(item.targetologs?.length).toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">ТАРГЕТОЛОГИ</span>
                </div>

                <div class="text-center">
                    <div class="mt-3 h-4 latolight">{{ parseInt(item.ads).toLocaleString() }}</div>
                    <span class="text-[8px] text-slate-400">ОБЪЯВЛЕНИЯ</span>
                </div> -->

                <!-- <div @click="settingsProject(item)" class="absolute -right-8 cursor-pointer hover:text-cyan-500 group-hover:right-4 duration-300">
                    <IcoBig name="settings" :size="8" />
                </div> -->

                <!-- <div class="text-center ">
                    <div class=" mt-3  ">{{ parseInt(item.views).toLocaleString() }}</div>
                    <span class=" absolute text-[8px] text-slate-400">ПРОСМОТРЫ</span>
                </div>

                <div class="text-center ">
                    <div class=" mt-3  ">{{ parseInt(item.clicks).toLocaleString() }}</div>
                    <span class=" absolute text-[8px] text-slate-400">КЛИКИ</span>
                </div>

                <div class="text-center ">
                    <div class=" mt-3  ">{{ parseInt(item.regs).toLocaleString() }}</div>
                    <span class=" absolute text-[8px] text-slate-400">ЛИДЫ</span>
                </div> -->

                <!-- <div class="text-center ">
                    <div class=" mt-3  ">{{ parseInt(item.stat?.orders_count).toLocaleString() }}</div>
                    <span class=" absolute text-[8px] text-slate-400">СЧЕТА</span>
                </div>

                <div class="text-center ">
                    <div class=" mt-3  ">{{ parseInt(item.stat?.orders_sum).toLocaleString() }}</div>
                    <span class=" absolute text-[8px] text-slate-400">СУММА СЧЕТОВ</span>
                </div>

                <div class="text-center ">
                    <div class=" mt-3  ">{{ parseInt(item.stat?.payments_count).toLocaleString() }}</div>
                    <span class=" absolute text-[8px] text-slate-400">ОПЛАТЫ</span>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";

export default {
    name: "ClientProjects",

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "setPeriod", "setMonth", "setSettings", "clearSettings", "setChannel"]),
        ...mapState(useClientStore, ["projects", "getProjects", "pickProject", "settingsProject"]),
    },

    methods: {
        update() {
            this.getProjects();
        },
    },

    mounted() {
        this.getProjects();
    },
};
</script>
