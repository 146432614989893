<template>
    <TopMenu />
    <!-- dark:bg-slate-800 -->
    <div class="bg-slate-100 p-4 pt-16 min-h-screen" v-if="!s.request"><RouterView /></div>
    <div v-else class="flex justify-center items-center h-screen"><IconLoader :size="30" /></div>

    <FilterUtm />
    <FilterTgs />
    <div v-if="s.popup" @click="closePopup()" class="fixed bg-cyan-900 z-40 w-screen h-screen top-0 left-0 opacity-80" id="overlay"></div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";

import TopMenu from "./components/Menu.vue";
import FilterUtm from "./components/FilterUtm.vue";
import FilterTgs from "./components/FilterTgs.vue";

export default {
    name: "App",

    components: { TopMenu, FilterUtm, FilterTgs },

    computed: {
        ...mapState(useMainStore, ["s", "filter", "alert", "routes", "loadData", "closePopup"]),

        currentRoute() {
            return this.$route.name;
        },
    },

    watch: {
        alert() {
            if (this.alert?.type == "success") {
                this.$toast.success(this.alert.msg);
            }
            if (this.alert?.type == "error") {
                this.$toast.error(this.alert.msg);
            }
            if (this.alert?.type == "warning") {
                this.$toast.warning(this.alert.msg);
            }
            if (this.alert?.type == "info") {
                this.$toast.info(this.alert.msg);
            }
        },
    },

    mounted() {
        this.$nextTick(function () {
            this.loadData();
        });
    },
};
</script>
