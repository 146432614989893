import IVk from "@/components/iconsVk/IVk";
import IMarket from "@/components/iconsVk/IMarket";
import IVkLike from "@/components/iconsVk/IVkLike";
import IVkRepost from "@/components/iconsVk/IVkRepost";
import IVkComment from "@/components/iconsVk/IVkComment";
import IVkAd from "@/components/iconsVk/IVkAd";
import IVkAds from "@/components/iconsVk/IVkAds";
import IVkBook from "@/components/iconsVk/IVkBook";
import IVkCamp from "@/components/iconsVk/IVkCamp";
import IVkDoc from "@/components/iconsVk/IVkDoc";
import IVkMoney from "@/components/iconsVk/IVkMoney";
import IVkUser from "@/components/iconsVk/IVkUser";
import ISenler from "@/components/iconsVk/ISenler";
import IFb from "@/components/iconsVk/IFb";
import ITgads from "@/components/iconsVk/ITgads";
import IYa from "@/components/iconsVk/IYa";
import IYoutube from "@/components/iconsVk/IYoutube";
import IBase from "@/components/iconsVk/IBase";
import IGg from "@/components/iconsVk/IGg";

import IcoBig from "@/components/IcoBig";
import IconBack from "@/components/icons/IconBack";
import IconCalendar from "@/components/icons/IconCalendar";
import IconClick from "@/components/icons/IconClick";
import IconClock from "@/components/icons/IconClock";
import IconPlus from "@/components/icons/IconPlus";
import IconEdit from "@/components/icons/IconEdit";
import IconDelete from "@/components/icons/IconDelete";
import IconDown from "@/components/icons/IconDown";
import IconClose from "@/components/icons/IconClose";
import IconClone from "@/components/icons/IconClone";
import IconGood from "@/components/icons/IconGood";
import IconBad from "@/components/icons/IconBad";
import IconLink from "@/components/icons/IconLink";
import IconList from "@/components/icons/IconList";
import IconLoader from "@/components/icons/IconLoader";
import IconRe from "@/components/icons/IconRe";
import IconRocket from "@/components/icons/IconRocket";
import IconTelegram from "@/components/icons/IconTelegram";
import IconHome from "@/components/icons/IconHome";
import IconMore from "@/components/icons/IconMore";
import IconOk from "@/components/icons/IconOk";
import IconSetting from "@/components/icons/IconSetting";
import IconStop from "@/components/icons/IconStop";
import IconProtfolio from "@/components/icons/IconProtfolio";
import IconEye from "@/components/icons/IconEye";
import IconEyeClose from "@/components/icons/IconEyeClose";
import IconUp from "@/components/icons/IconUp";
import IconWarning from "@/components/icons/IconWarning";
import IconUser from "@/components/icons/IconUser";

export default [
    IVk,
    IMarket,
    IVkLike,
    IVkRepost,
    IVkComment,
    IVkAd,
    IVkAds,
    IVkBook,
    IVkCamp,
    IVkDoc,
    IVkMoney,
    IVkUser,
    ISenler,
    IFb,
    ITgads,
    IYa,
    IYoutube,
    IBase,
    IGg,

    IcoBig,
    IconBack,
    IconCalendar,
    IconClick,
    IconClock,
    IconGood,
    IconBad,
    IconPlus,
    IconEdit,
    IconDelete,
    IconDown,
    IconClose,
    IconClone,
    IconLink,
    IconList,
    IconLoader,
    IconMore,
    IconTelegram,
    IconHome,
    IconOk,
    IconSetting,
    IconStop,
    IconProtfolio,
    IconRe,
    IconRocket,
    IconEye,
    IconEyeClose,
    IconUp,
    IconWarning,
    IconUser,
];
