<template>
    <div
        v-if="s.popup == 'filterTgs'"
        class="fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] h-fit w-5/6 rounded z-50 p-4 bg-white overflow-y-scroll"
    >
        <div class="text-xl text-center text-slate-400">ФИЛЬТР ПО ТАРГЕТОЛОГУ</div>

        <div class="w-full flex flex-wrap items-center gap-1 mb-1 text-xs">
            <div
                @click="setTargetolog(item)"
                v-for="item in filter?.targetologs"
                :key="item"
                class="bg-slate-50 py-1 px-2 rounded cursor-pointer"
                :class="{ '!bg-cyan-500 text-white': s.tgs.includes(item) }"
            >
                {{ item }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";

export default {
    name: "FilterUtm",

    emits: ["update"], // объявляем событие update

    computed: {
        ...mapState(useMainStore, [
            "s",
            "filter",
            "month",
            "setPeriod",
            "setMonth",
            "setSettings",
            "setCompact",
            "setSDays",
            "setProject",
            "setChannel",
            "clearSettings",
            "setPopup",
        ]),

        allUtms() {
            let data = { source: [], medium: [], campaign: [], content: [], term: [] };
            if (!this.utms) return data;

            if (this.s.channel_id) {
                data.source.push(...(this.utms[this.s.channel_id].source || []));
                data.medium.push(...(this.utms[this.s.channel_id].medium || []));
                data.campaign.push(...(this.utms[this.s.channel_id].campaign || []));
                data.content.push(...(this.utms[this.s.channel_id].content || []));
                data.term.push(...(this.utms[this.s.channel_id].term || []));
            } else {
                Object.values(this.utms).forEach((item) => {
                    data.source.push(...(item.source || []));
                    data.medium.push(...(item.medium || []));
                    data.campaign.push(...(item.campaign || []));
                    data.content.push(...(item.content || []));
                    data.term.push(...(item.term || []));
                });
            }

            // Преобразование в уникальные значения
            data.source = [...new Set(data.source)];
            data.medium = [...new Set(data.medium)];
            data.campaign = [...new Set(data.campaign)];
            data.content = [...new Set(data.content)];
            data.term = [...new Set(data.term)];

            return data;
        },
    },

    methods: {
        setSource(utm) {
            this.s.utms?.source?.includes(utm) ? this.s.utms?.source?.splice(this.s.utms?.source?.indexOf(utm), 1) : this.s.utms?.source?.push(utm);
            this.setSettings(this.s);
        },

        setMedium(utm) {
            this.s.utms?.medium?.includes(utm) ? this.s.utms?.medium?.splice(this.s.utms?.medium?.indexOf(utm), 1) : this.s.utms?.medium?.push(utm);
            this.setSettings(this.s);
        },

        setCampaign(utm) {
            this.s.utms?.campaign?.includes(utm)
                ? this.s.utms?.campaign?.splice(this.s.utms?.campaign?.indexOf(utm), 1)
                : this.s.utms?.campaign?.push(utm);
            this.setSettings(this.s);
        },

        setContent(utm) {
            this.s.utms?.content?.includes(utm)
                ? this.s.utms?.content?.splice(this.s.utms?.content?.indexOf(utm), 1)
                : this.s.utms?.content?.push(utm);
            this.setSettings(this.s);
        },

        setTerm(utm) {
            this.s.utms?.term?.includes(utm) ? this.s.utms?.term?.splice(this.s.utms?.term?.indexOf(utm), 1) : this.s.utms?.term?.push(utm);
            this.setSettings(this.s);
        },

        update() {
            this.$emit("update");
        },
    },
};
</script>
