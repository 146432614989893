<template>
    <svg
        class="fill-stroke transition duration-300"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <line x1="7" y1="17" x2="17" y2="7" />
        <polyline points="7 7 17 7 17 17" />
    </svg>
</template>

<script>
export default {
    name: "IconUp",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
