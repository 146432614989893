<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9,18.8c0.3,1.8-0.9,3.5-2.7,3.8s-3.5-0.9-3.8-2.7c-0.2-1.1-0.4-2.1-0.5-2.8c-2.8-0.5-5-2.9-5-5.9
	c0-3.3,2.7-6,6-6h8.6L19,2.4c1.5-1,3.5-0.5,4.5,1C23.8,4,24,4.6,24,5.2v12c0,1.8-1.5,3.3-3.3,3.3c-0.6,0-1.2-0.2-1.8-0.5l-4.3-2.8
	l-3,0C11.7,17.7,11.8,18.2,11.9,18.8z M7.7,19.6c0.1,0.6,0.7,1,1.3,0.9c0.6-0.1,1-0.7,0.9-1.3c-0.1-0.8-0.3-1.4-0.4-2l-2.2,0
	C7.3,17.8,7.5,18.6,7.7,19.6z M20.1,4.3l-4.9,3.1V15l4.9,3.1c0.2,0.1,0.4,0.2,0.6,0.2c0.6,0,1.1-0.5,1.1-1.1v-12
	c0-0.2-0.1-0.4-0.2-0.6C21.3,4.1,20.6,4,20.1,4.3z M13.1,7.4L6,7.4c-2.1,0-3.8,1.7-3.8,3.8S3.9,15,6,15l7.1,0V7.4z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "IMarket",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
