<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent :view="['date']" @update="update" />
        </div>

        <div class="w-full px-4">
            <div class="grid grid-cols-9 items-center gap-4 p-2 mt-4 text-xs text-slate-400">
                <div class="col-span-3">КАНАЛ ID</div>
                <div class="col-span-3">КАБИНЕТ</div>
                <div class="text-right">БЮДЖЕТ</div>
                <div class="text-right">ПОКАЗЫ</div>
                <div class="text-right">КЛИКИ</div>
            </div>

            <div v-for="item in clients" :key="item.id" class="w-full my-1 text-slate-500 text-sm group hover:my-2 duration-500">
                <div class="bg-white/50 border border-white h-10 rounded-xl grid grid-cols-9 justify-center items-center gap-4 px-4 hover:bg-white">
                    <div class="flex justify-start items-center gap-4 col-span-3 text-cyan-500">
                        <div v-if="item.channel_id == 1" class="group-hover:text-blue-500 group-hover:scale-110 duration-300"><IVk :size="5" /></div>
                        <div v-if="item.channel_id == 2" class="group-hover:text-blue-500 group-hover:scale-110 duration-300">
                            <IVkAds :size="5" />
                        </div>
                        <div v-if="item.channel_id == 3" class="group-hover:text-pink-500 group-hover:scale-110 duration-300">
                            <IMarket :size="5" />
                        </div>
                        <div v-if="item.channel_id == 4" class="group-hover:text-indigo-500 group-hover:scale-110 duration-300">
                            <IFb :size="5" />
                        </div>
                        <div v-if="item.channel_id == 5" class="group-hover:text-red-500 group-hover:scale-110 duration-300"><IYa :size="5" /></div>
                        <div v-if="item.channel_id == 7" class="group-hover:text-yellow-400 group-hover:scale-110 duration-300">
                            <IGg :size="5" />
                        </div>
                        <div v-if="item.channel_id == 9" class="group-hover:text-teal-500 group-hover:scale-110 duration-300">
                            <ITgads :size="5" />
                        </div>
                        <div v-if="item.channel_id == 10" class="group-hover:text-red-500 group-hover:scale-110 duration-300">
                            <IYoutube :size="5" />
                        </div>
                        <div v-if="item.channel_id == 100" class="group-hover:text-green-500 group-hover:scale-110 duration-300">
                            <IBase :size="5" />
                        </div>
                        <div v-if="item.channel_id == 'none'" class="group-hover:text-slate-500 group-hover:scale-110 duration-300">
                            <IconClose :size="5" />
                        </div>

                        <!-- <div class="text-cyan-500">
                            <a v-if="item.channel_id == 1" target="_blank" :href="'https://vk.com/ads?act=office&union_id=' + item.id">
                                <IVk :size="5" />
                            </a>

                            <a v-if="item.channel_id == 2" target="_blank" href="https://ads.vk.com/hq/dashboard"><IVkAds :size="5" /> </a>

                            <a v-if="item.channel_id == 3" target="_blank" :href="'https://vk.com/ads?act=office&union_id=' + item.id">
                                <IVkCamp :size="5" />
                            </a>

                            <a v-if="item.channel_id == 4"><IFb :size="5" /> </a>
                            <a v-if="item.channel_id == 5"><IYa :size="5" /> </a>

                            <a v-if="item.channel_id == 9"><ITgads :size="5" /></a>
                            <a v-if="item.channel_id == 10"><IYoutube :size="5" /></a>
                            <a v-if="item.channel_id == 100"><IBase :size="5" /></a>
                        </div> -->

                        <div class="text-slate-500">{{ item.id }}</div>
                    </div>

                    <div class="col-span-3">{{ item.name }}</div>

                    <div class="text-right tabular-nums text-cyan-500">{{ item.spent?.toLocaleString() }}&nbsp;<sup>₽</sup></div>

                    <div class="text-right tabular-nums">{{ item.views?.toLocaleString() }}</div>

                    <div class="text-right tabular-nums">{{ item.clicks?.toLocaleString() }}</div>

                    <!-- <div class="absolute top-2 right-2 text-sky-800 cursor-pointer" @click="parseClientCampaigns(item.id)">
                        <IconDown :size="5" />
                    </div> -->
                </div>
            </div>

            <!--  <div class="h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 px-4 font-bold text-sm text-slate-500">
                <div class="flex justify-start items-center gap-2 col-span-2">ИТОГО</div>

                <div class="col-span-7">Количество: {{ total.count }}</div>

                <div class="text-right tabular-nums text-cyan-500">{{ total.spent?.toLocaleString() }}&nbsp;<sup>₽</sup></div>

                <div class="text-right tabular-nums">{{ total.views?.toLocaleString() }}</div>

                <div class="text-right tabular-nums">{{ total.clicks?.toLocaleString() }}</div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

import FilterComponent from "../components/Filter.vue";

export default {
    name: "ClientsPage",

    components: { FilterComponent },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s"]),
        ...mapState(useClientStore, ["dashboard", "dataAll", "getData", "updateData"]),

        clients() {
            if (!this.dashboard) return [];

            let data = {};

            this.dashboard.forEach((day) => {
                const campaigns = Object.values(day.campaigns);
                campaigns.forEach((campaign) => {
                    let id = campaign.client_id;
                    if (parseInt(id) > 0) {
                        if (!data[id])
                            data[id] = {
                                id: id,
                                channel_id: campaign.channel_id,
                                spent: 0,
                                views: 0,
                                clicks: 0,
                                regs: 0,
                                cpm: 0,
                                cpc: 0,
                                cpl: 0,
                                ctr: 0,
                            };

                        for (const key in campaign) {
                            if (["id", "channel_id", "campaign_id", "client_id"].includes(key)) continue;
                            if (!data[id][key]) data[id][key] = 0;
                            data[id][key] += campaign[key];
                        }
                    }
                });
            });

            // Добавляем названия клиентов из dataAll.clients
            if (this.dataAll && this.dataAll.clients) {
                this.dataAll.clients.forEach((client) => {
                    if (data[client.id]) data[client.id].name = client.name;
                });
            }

            return data;

            // Рассчитываем метрики для каждого клиента
            // let clients = Object.keys(data).map((client_id) => {
            //     let clientData = data[client_id];
            //     clientData.cpm = clientData.views ? parseFloat((clientData.spent / clientData.views) * 1000).toFixed(2) : 0;
            //     clientData.cpc = clientData.clicks ? parseFloat(clientData.spent / clientData.clicks).toFixed(2) : 0;
            //     clientData.cpl = clientData.regs ? parseFloat(clientData.spent / clientData.regs).toFixed(2) : 0;
            //     clientData.ctr = clientData.views ? parseFloat((clientData.clicks / clientData.views) * 100).toFixed(2) : 0;
            //     clientData.client_id = client_id;
            //     return clientData;
            // });

            // return clients;
        },
    },

    methods: {
        update() {
            this.updateData();
        },
    },

    mounted() {
        this.getData();
    },
};
</script>
