<template>
    <div class="grid grid-cols-1 md:grid-cols-5 gap-4 min-h-[calc(100vh-80px)]">
        <div class="sticky top-16 bg-white rounded p-4 overflow-y-auto"><Menu /></div>

        <div class="col-span-4">
            <template v-if="admin && admin.accounts && !adminMenu"><Accounts :accounts="admin.accounts" /></template>

            <template v-if="adminAccount && adminAccount.id > 0">
                <template v-if="adminMenu == 'Clients' && adminAccount.clients"><Clients :clients="adminAccount.clients" /></template>
            </template>
        </div>
    </div>
    <div class="bg-white">
        <div class="w-full h-screen bg-white flex justify-center items-center">
            <a @click="$router.push(topMenu[0].url)" class="flex justify-between items-center text-xl font-bold text-cyan-500 cursor-pointer">
                <LogoAnalytics />
                <span>Stat</span>
            </a>
        </div>

        <div class="w-full h-screen flex justify-center items-center gap-10 text-white my-12">
            <div class="w-fit h-32 flex flex-col text-center relative fly2 f p-4">
                <span class="title">РАСХОД</span>
                <span class="num text-pink-500">
                    <span class="latobold"> {{ parseInt(counter_spent).toLocaleString() }}</span>
                    <sup> ₽</sup>
                </span>
                <StatsCard circleColor="rgba(220, 40, 180, 0.05)" />
            </div>

            <div class="w-12 pb-20 flex flex-col justify-center items-center">
                <span class="title">ROMI</span>
                <span class="text-xl text-slate-500">{{ parseInt(funnel?.romi) }}%</span>
            </div>

            <div class="w-fit h-32 flex flex-col text-center relative fly3 f p-4">
                <span class="title">ОПЛАТЫ</span>
                <span class="num text-teal-500">
                    <span class="latobold"> {{ parseInt(counter_payments).toLocaleString() }}</span>
                    <sup> ₽</sup>
                </span>
                <StatsCard circleColor="rgba(10, 210, 230, 0.05)" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAdminStore } from "@/store/admin";
import { useProfileStore } from "@/store/profile";
import LogoAnalytics from "@/components/logo.vue";

import Menu from "./components/Menu.vue";
import Accounts from "./components/Accounts.vue";
import Clients from "./components/Clients.vue";

export default {
    name: "AdminPage",

    components: { Menu, Accounts, Clients, LogoAnalytics },

    data() {
        return {
            roleNames: { An: "Админ", Or: "Владелец", Cl: "Клиент", Mn: "Менеджер" },
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useAdminStore, ["admin", "adminMenu", "adminAccount", "getAdmin"]),
        ...mapState(useProfileStore, ["profile", "getProfile", "getPermission", "uploadAvatar"]),
    },

    mounted() {
        this.getAdmin();
        // this.getPermission("/admin");
    },
};
</script>
