<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent />

            <div class="btn btn-blue py-1 px-3 my-1" @click="(this.s.select = null), this.setSettings(this.s), update()">ПОЛУЧИТЬ</div>
            <div class="btn py-1 px-3 my-1 text-xs" @click="clearSettings(), update()">ОЧИСТИТЬ</div>
        </div>

        <div class="w-full">
            <h2 class="w-full text-center text-slate-400 text-3xl font-bold">ПРОЕКТЫ</h2>

            <div class="mb-4 flex flex-wrap justify-start items-center gap-2 text-slate-500">
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData" value="map" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData === 'map' }">Карта</div>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" v-model="selectedData" value="list" class="hidden" />
                    <div class="btn p-1" :class="{ '!bg-cyan-500 !text-white': selectedData === 'list' }">Список</div>
                </label>
            </div>

            <template v-if="selectedData == 'map'">
                <svg :width="width" :height="height">
                    <circle
                        v-for="(circle, index) in allProjects"
                        :key="index"
                        :cx="circle.x"
                        :cy="circle.y"
                        :r="circle.radius"
                        :fill="circle.color"
                        :style="{ '--translate-x': circle.translateX, '--translate-y': circle.translateY }"
                        class="project-animation"
                    />
                    <g v-for="(circle, index) in allProjects" :key="index">
                        <foreignObject
                            :x="circle.x - circle.radius"
                            :y="circle.y - circle.radius"
                            :width="circle.radius * 2"
                            :height="circle.radius * 2"
                        >
                            <div
                                v-if="circle.radius > 40"
                                class="flex flex-col justify-center items-center text-xs text-slate-600 text-center project-animation"
                                :style="{ '--translate-x': circle.translateX, '--translate-y': circle.translateY }"
                                style="height: 100%"
                            >
                                <div>{{ circle.name }}</div>
                                <div>{{ parseInt(circle.spent).toLocaleString() }}</div>
                            </div>
                            <div
                                v-else-if="circle.radius > 30"
                                class="flex flex-col justify-center items-center text-[9px] text-slate-600 text-center project-animation"
                                :style="{ '--translate-x': circle.translateX, '--translate-y': circle.translateY }"
                                style="height: 100%"
                            >
                                <div>{{ parseInt(circle.spent).toLocaleString() }}</div>
                            </div>
                            <div
                                v-else
                                class="flex flex-col justify-center items-center text-[7px] text-slate-600 text-center project-animation"
                                :style="{ '--translate-x': circle.translateX, '--translate-y': circle.translateY }"
                                style="height: 100%"
                            >
                                <div>{{ parseInt(circle.spent).toLocaleString() }}</div>
                            </div>
                        </foreignObject>
                    </g>
                </svg>
            </template>

            <template v-if="selectedData == 'list'">
                <div class="w-5/6 m-auto">
                    <div
                        v-for="(item, index) in projects"
                        :key="item"
                        @click="pickProject(item)"
                        class="w-full rounded-xl h-20 overflow-hidden relative pt-2 text-slate-500 group hover:my-1 duration-500 hover:scale-[1.02]"
                    >
                        <div class="absolute flex justify-center items-center gap-2 bottom-0 z-20">
                            <div v-if="index > 9" class="font-bold text-xl w-10 p-5">{{ index + 1 }}</div>
                            <span class="group-hover:scale-110 duration-500 text-yellow-500" v-if="index < 3">
                                <IcoBig name="star-cup" :size="20"
                            /></span>
                            <span class="group-hover:scale-110 duration-500 text-teal-500" v-if="index > 2 && index < 10">
                                <IcoBig name="star" :size="20"
                            /></span>
                        </div>

                        <!-- :class="{ '!bg-yellow-50 ': index < 3, '!bg-teal-50 ': index > 2 && index < 10 }" -->
                        <div class="bg-white rounded-xl h-20 grid grid-cols-10 justify-center items-center gap-2">
                            <div></div>
                            <div class="text-sm font-light text-center col-span-2">{{ item.name }}</div>

                            <div class="flex justify-end items-center gap-1 text-cyan-500 col-span-2">
                                <span class="text-3xl group-hover:text-4xl duration-300 font-bold">{{ parseInt(item.spent).toLocaleString() }}</span>
                                <sup>₽</sup>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">{{ parseInt(item.ads).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">ОБЪЯВЛЕНИЯ</span>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">{{ parseInt(item.views).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">ПРОСМОТРЫ</span>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">{{ parseInt(item.clicks).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">КЛИКИ</span>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">{{ parseInt(item.regs).toLocaleString() }}</div>
                                <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                            </div>

                            <div class="text-center">
                                <div class="text-sm font-light leading-3 mt-2">
                                    {{ parseInt(parseInt(item.spent) / parseInt(item.regs)).toLocaleString() }}
                                </div>
                                <span class="text-[8px] text-slate-400">CPL</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div :class="'grid gap-x-4 grid-rows-' + Math.ceil(projects.length / 3)">
                    <div v-for="(project, index) in projects" :key="project.id" class="py-px w-full group hover:py-1 relative duration-500">
                        <div
                            class="grid grid-cols-3 items-center gap-4 bg-white/50 text-slate-500 hover:bg-cyan-50 hover:text-cyan-600 p-2 rounded"
                            :class="{ '!bg-yellow-100 !text-yellow-600': index < 3, '!bg-teal-100 !text-teal-600': index > 2 && index < 10 }"
                        >
                            <div class="col-span-2 h-6 overflow-hidden text-sm flex items-center gap-2">
                                <span class="font-bold w-4">{{ index + 1 }}</span>
                                <span v-if="index < 3"> <IcoBig name="star-cup" :size="5" /></span>
                                <span v-if="index > 2 && index < 10"> <IcoBig name="star" :size="5" /></span>
                                {{ project.name }}
                            </div>
                            <div class="text-right tabular-nums">{{ parseInt(project.spent).toLocaleString() }}</div>
                        </div>
                    </div>
                </div> -->
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useOwnerStore } from "@/store/owner";

import FilterComponent from "../components/Filter.vue";

export default {
    name: "OwnerProjects",

    components: { FilterComponent },

    data() {
        return {
            circles: [],

            select: null,

            selectedData: "list", // Инициализируем выбор по умолчанию

            width: window.innerWidth - 160,
            height: window.innerHeight,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "profile", "setPeriod", "setMonth", "setSettings", "clearSettings", "setChannel"]),
        ...mapState(useOwnerStore, ["projects", "getProjects", "pickProject"]),

        allProjects() {
            if (!this.projects) return [];

            const maxSpent = Math.max(...this.projects?.map((item) => item.spent));
            const minRadius = this.width * 0.015;
            const maxRadius = this.width * 0.15;
            const circles = [];
            const coordinates = []; // Массив для хранения уже использованных координат
            const occupied = Array.from({ length: this.width }, () => Array(this.height).fill(false));

            this.projects?.forEach((item) => {
                if (!item.id) return;

                let x, y;
                let attempts = 0;
                let radius = Math.floor(minRadius + (maxRadius - minRadius) * (item.spent / maxSpent));

                do {
                    x = Math.random() * (this.width - radius * 3) + radius;
                    y = Math.random() * (this.height - 100 - radius * 3) + radius;
                    attempts++;
                } while (this.isOverlapping(x, y, minRadius, circles) && attempts < 5000);

                if (attempts >= 4999) console.log("attempts", attempts);

                // Определяем занятые промежутки и помечаем их
                for (let i = Math.max(0, Math.floor(x - radius)); i <= Math.min(this.width - 1, Math.floor(x + radius)); i++) {
                    for (let j = Math.max(0, Math.floor(y - radius)); j <= Math.min(this.height - 1, Math.floor(y + radius)); j++) {
                        occupied[i][j] = true;
                    }
                }

                coordinates.push({ x, y });
                circles.push({
                    x,
                    y,
                    radius,
                    color: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.2)`,
                    name: item.name,
                    spent: item.spent,
                });
            });

            circles.forEach((circle) => {
                circle.translateX = `${Math.random() > 0.5 ? 15 : -15}px`;
                circle.translateY = `${Math.random() > 0.5 ? 15 : -15}px`;
            });

            console.log("Math.random()", Math.random());
            console.log("circles", this.projects.length, circles.length);

            return circles;
        },
    },

    methods: {
        update() {
            this.getProjects();
            this.startAnimation();
        },

        startAnimation() {
            if (!this.main) return null;

            const start = performance.now();
            const duration = 3000; // Длительность анимации в миллисекундах

            const increments = [
                { key: "total", target: this.hub.main.total.spent },
                { key: "market", target: this.hub.main.market.spent },
                { key: "vk", target: this.hub.main.vk.spent },
                { key: "vkads", target: this.hub.main.vkads.spent },
                { key: "facebook", target: this.hub.main.facebook.spent },
                { key: "yandex", target: this.hub.main.yandex.spent },
            ];

            const animate = (timestamp) => {
                const progress = Math.min((timestamp - start) / duration, 1); // Прогресс анимации от 0 до 1

                increments.forEach((item, index) => {
                    const counterName = `counter${index}`;
                    const currentValue = this[counterName] || 0;
                    const inc = Math.ceil(item.target / (duration / 100)); // Рассчитываем инкремент

                    if (currentValue < item.target) {
                        this[counterName] = Math.min(currentValue + progress * inc, item.target); // Используем прогресс для плавного изменения значений
                    }
                });

                if (!increments.every((item) => this[`counter${increments.indexOf(item) + 1}`] === item.target)) {
                    requestAnimationFrame(animate);
                }
            };

            requestAnimationFrame(animate);
        },

        isOverlapping(x, y, radius, circles) {
            for (const circle of circles) {
                const dx = circle.x - x;
                const dy = circle.y - y;
                const minDistance = circle.radius + radius;

                if (Math.sqrt(dx * dx + dy * dy) < minDistance) {
                    return true;
                }
            }
            return false;
        },
    },

    watch: {
        projects: {
            handler() {
                this.startAnimation();
            },
            deep: true,
        },
    },

    mounted() {
        this.getProjects();
    },
};
</script>

<style scoped>
.circle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; /* Помещаем контейнер на задний план */
}

.fly2 {
    animation: moveUpDown 6s infinite alternate;
}
.fly3 {
    animation: moveUpDown 8s infinite alternate;
}
.fly4 {
    animation: moveUpDown 10s infinite alternate;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

.project-animation {
    animation: moveProject 10s ease-in-out infinite alternate;
}

@keyframes moveProject {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(var(--translate-x), var(--translate-y));
    }
}
</style>
