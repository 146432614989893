import { createApp } from "vue";
import { createPinia } from "pinia";

const app = createApp(App);
const pinia = createPinia();

import Toaster from "@meforma/vue-toaster";
import "@/assets/tailwind.css";
import components from "@/components";

import router from "@/services/router";
import App from "@/App.vue";

components.forEach((component) => app.component(component.name, component));

app.use(pinia);
app.use(Toaster, { position: "bottom-right" });
app.use(router);

app.mount("#app");
