<template>
    <!-- <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent :view="['date']" @update="update" />
        </div> -->

    <div class="w-full">
        <h2 class="w-full text-center text-slate-400 text-xl font-bold mb-8">РАСХОДЫ ПО МЕСЯЦАМ</h2>

        <div class="mb-4 flex flex-wrap justify-start items-center gap-4 text-slate-500">
            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="sp" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 'sp' }">Расход</div>
            </label>
            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="vi" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 'vi' }">Просмотры</div>
            </label>
            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="cl" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 'cl' }">Клики</div>
            </label>

            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="re" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 're' }">Лиды</div>
            </label>

            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="w1" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 'w1' }">Вебинар 1</div>
            </label>

            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="oc" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 'oc' }">Заказы</div>
            </label>

            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="os" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 'os' }">Сумма заказов</div>
            </label>

            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="pc" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 'pc' }">Оплаты</div>
            </label>

            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="ps" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 'ps' }">Сумма оплат</div>
            </label>

            <label class="inline-flex items-center">
                <input type="radio" v-model="selectedData" value="romi" class="hidden" />
                <div class="btn p-1 px-2" :class="{ '!bg-cyan-500 !text-white': selectedData === 'romi' }">Romi</div>
            </label>
        </div>

        <div class="grid grid-cols-9 gap-4 border-y border-slate-200 p-2 sticky top-12 bg-white z-10">
            <div class="text-slate-400">Дата</div>
            <div class="text-right text-slate-400">ВСЕГО</div>

            <div class="flex items-center gap-2 justify-self-end text-slate-400">
                <div class="text-cyan-600"><IVk :size="4" /></div>
                vk
            </div>
            <div class="flex items-center gap-2 justify-self-end text-slate-400">
                <div class="text-cyan-600"><IVkAds :size="4" /></div>
                vkads
            </div>
            <div class="flex items-center gap-2 justify-self-end text-slate-400">
                <div class="text-cyan-600"><IVkCamp :size="4" /></div>
                market
            </div>
            <div class="flex items-center gap-2 justify-self-end text-slate-400">
                <div class="text-cyan-600"><IFb :size="4" /></div>
                book
            </div>
            <div class="flex items-center gap-2 justify-self-end text-slate-400">
                <div class="text-cyan-600"><IYa :size="4" /></div>
                yandex
            </div>
            <div class="flex items-center gap-2 justify-self-end text-slate-400">
                <div class="text-cyan-600"><ITgads :size="4" /></div>
                tgads
            </div>
            <div class="flex items-center gap-2 justify-self-end text-slate-400">другое</div>
        </div>

        <!-- {{ monthly }} -->

        <div v-for="(mo, index) in monthly" :key="index">
            <template v-if="index === 0 || moment(mo?.date).format('YYYY') !== moment(monthly[index - 1]?.date).format('YYYY')">
                <h2 class="font-bold m-2">{{ moment(mo?.date).format("YYYY") }}</h2>
            </template>

            <div class="py-px w-full group hover:py-1 relative duration-500">
                <div
                    class="grid grid-cols-9 items-center gap-4 bg-white/50 text-slate-500 text-right hover:bg-cyan-50 hover:text-cyan-600 p-2 w-full rounded"
                >
                    <div class="text-left">{{ month[parseInt(moment(mo?.date).format("MM"))] }}</div>
                    <div>{{ parseInt(mo?.total?.[selectedData] || 0).toLocaleString() }}</div>
                    <div>{{ parseInt(mo?.channels["1"]?.[selectedData] || 0).toLocaleString() }}</div>
                    <div>{{ parseInt(mo?.channels["2"]?.[selectedData] || 0).toLocaleString() }}</div>
                    <div>{{ parseInt(mo?.channels["3"]?.[selectedData] || 0).toLocaleString() }}</div>
                    <div>{{ parseInt(mo?.channels["4"]?.[selectedData] || 0).toLocaleString() }}</div>
                    <div>{{ parseInt(mo?.channels["5"]?.[selectedData] || 0).toLocaleString() }}</div>
                    <div>{{ parseInt(mo?.channels["9"]?.[selectedData] || 0).toLocaleString() }}</div>
                    <div>{{ parseInt(mo?.channels["100"]?.[selectedData] || 0).toLocaleString() }}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

// import FilterComponent from "../components/Filter.vue";

export default {
    name: "OwnerMonth",

    // components: { FilterComponent },

    data() {
        return {
            selectedData: "sp",
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "month", "profile", "setPeriod", "setMonth", "setSettings", "clearSettings", "setChannel"]),
        ...mapState(useClientStore, ["monthly", "getMonthly"]),
    },

    methods: {
        update() {
            this.getMonthly();
        },
    },

    mounted() {
        this.getMonthly();
    },
};
</script>
