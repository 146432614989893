<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent />

            <div class="btn btn-blue py-1 px-3 my-1" @click="(this.s.select = null), this.setSettings(this.s), update()">ПОЛУЧИТЬ</div>
            <div class="btn py-1 px-3 my-1 text-xs" @click="clearSettings(), update()">ОЧИСТИТЬ</div>
        </div>

        <div class="w-full">
            <h2 class="w-full text-center text-slate-400 text-3xl font-bold">КАБИНЕТЫ</h2>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div
                    v-for="cabinet in cabinets"
                    :key="cabinet"
                    class="w-full bg-white flex justify-center items-center flex-col rounded-xl p-4 group hover:scale-[1.02] duration-300"
                >
                    <div class="flex justify-center items-center gap-2 uppercase">
                        <div v-if="cabinet.channel_id == 1" class="text-blue-500"><IVk :size="5" /></div>
                        <div v-if="cabinet.channel_id == 2" class="text-blue-600 p-1"><IVkAds :size="4" /></div>
                        <div v-if="cabinet.channel_id == 3" class="text-pink-600"><IVkCamp :size="5" /></div>
                        <div v-if="cabinet.channel_id == 4"><IFb :size="5" /></div>
                        <div v-if="cabinet.channel_id == 5" class="p-1"><IYa :size="4" /></div>
                        {{ cabinet.name }}
                        <!-- {{ cabinet.cabinet_id }}  -->
                    </div>

                    <div class="text-xs text-slate-400 opacity-0 group-hover:opacity-100 duration-300">{{ cabinet.account_id }}</div>

                    <div class="num text-4xl md:text-3xl mt-4">{{ parseInt(cabinet.spent).toLocaleString() }} <sup>₽</sup></div>

                    <div class="flex justify-center items-center gap-8 mt-2">
                        <!-- <div>{{ parseInt(cabinet.views).toLocaleString() }}</div>
                        <div>{{ parseInt(cabinet.clicks).toLocaleString() }}</div> -->

                        <span class="flex justify-center items-center text-cyan-600/60 gap-2">
                            <IconEye :size="3" />
                            {{ parseInt(cabinet.views).toLocaleString() }}
                        </span>
                        <span class="flex justify-center items-center text-cyan-600/60 gap-2">
                            <IconClick :size="3" />
                            {{ parseInt(cabinet.clicks).toLocaleString() }}
                        </span>
                    </div>

                    <div class="w-full md:grid grid-cols-3 gap-8 mt-4 text-xs text-slate-400 opacity-0 group-hover:opacity-100 duration-300 hidden">
                        <div class="flex justify-center items-center gap-2">
                            <span class="">CPM: </span> {{ parseInt(cabinet.spent / (cabinet.views / 1000)).toLocaleString() }} ₽
                        </div>
                        <div class="flex justify-center items-center gap-2">
                            <span class="">CPC: </span> {{ parseInt(cabinet.spent / cabinet.clicks).toLocaleString() }} ₽
                        </div>
                        <div class="flex justify-center items-center gap-2">
                            <span class="">CTR: </span> {{ ((cabinet.clicks * 100) / cabinet.views).toFixed(2) }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useOwnerStore } from "@/store/owner";
import moment from "moment";

import FilterComponent from "../components/Filter.vue";

export default {
    name: "OwnerCabinets",

    components: { FilterComponent },

    data() {
        return {
            month: {
                1: "Январь",
                2: "Февраль",
                3: "Март",
                4: "Апрель",
                5: "Май",
                6: "Июнь",
                7: "Июль",
                8: "Август",
                9: "Сентябрь",
                10: "Октябрь",
                11: "Ноябрь",
                12: "Декабрь",
            },
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "profile", "setPeriod", "setMonth", "setSettings", "clearSettings", "setChannel"]),
        ...mapState(useOwnerStore, ["cabinets", "getCabinets"]),
    },

    methods: {
        update() {
            this.getCabinets();
        },
    },

    mounted() {
        this.getCabinets();
    },
};
</script>
