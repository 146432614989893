<template>
    <div class="w-fit bg-white rounded p-4 mb-4">
        <div class="flex justify-between items-center gap-4">
            <div class="flex justify-center items-center text-slate-500 gap-2">
                ПРОЕКТЫ <span class="count">{{ allProjects?.length }}</span>
            </div>
            <!-- <input type="text" class="input" placeholder="Поиск проектов" v-model="s.findme" @input="setSettings(s)" /> -->
            <!-- <div class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings()">ОЧИСТИТЬ</div> -->
            <!-- <div class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings(), (checkedClients = [])">ОЧИСТИТЬ</div> -->
        </div>
    </div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-12 items-center text-xs text-slate-400 gap-4 py-2 px-4 border-b border-slate-200 uppercase">
            <div>ID</div>
            <div class="col-span-4">Название</div>
            <div class="col-span-5">КАМПАНИЯ</div>
            <div class="col-span-2">ИНН</div>
        </div>

        <template v-if="allProjects && allProjects.length > 0">
            <RecycleScroller class="h-[calc(100vh-200px)]" :items="allProjects" :item-size="48" key-field="id" v-slot="{ item }">
                <div
                    @click="pickProject(item)"
                    class="h-12 overflow-hidden grid grid-cols-12 items-center gap-4 px-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-700 cursor-pointer"
                >
                    <div>{{ item.id }}</div>
                    <div class="col-span-4">{{ item.name }}</div>
                    <div class="col-span-5">{{ item.client_short }}</div>
                    <div class="col-span-2">{{ item.inn }}</div>
                </div>
            </RecycleScroller>
        </template>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useManagerStore } from "@/store/manager";

import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import moment from "moment";

export default {
    name: "AdminClientsPage",
    components: { RecycleScroller },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "clearSettings"]),
        ...mapState(useManagerStore, ["projects", "getProjects", "pickProject"]),

        allProjects() {
            if (!this.projects) return null;

            let data = this.projects;

            if (this.s.findme) data = data.filter((item) => item.name?.toLowerCase().indexOf(this.s.findme.toLowerCase()) !== -1);

            return data;
        },
    },

    mounted() {
        this.getProjects();
    },
};
</script>

<style scoped>
.scroller {
    height: 100%;
}

.user {
    height: 32%;
    padding: 0 12px;
    display: flex;
    align-items: center;
}
</style>
