<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.1,0l0.2,0.2v2.6C19.8,3.2,24,6.9,24,12c0,5.1-4.2,8.8-9.7,9.2v2.6L14.1,24H9.9l-0.2-0.2v-2.6
		C4.2,20.8,0,17.1,0,12c0-5.1,4.2-8.8,9.7-9.2V0.2L9.9,0H14.1z M9.7,7.1C7,7.4,5,9.4,5,12c0,2.6,1.9,4.6,4.7,4.9V7.1z M14.3,16.9
		C17,16.6,19,14.6,19,12c0-2.6-1.9-4.6-4.7-4.9V16.9z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "IFb",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
