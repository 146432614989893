<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent @update="update" />

            <div class="btn btn-blue py-1 px-3 my-1" @click="(this.s.select = null), this.setSettings(this.s), update()">ПОЛУЧИТЬ</div>
            <div class="btn py-1 px-3 my-1 text-xs" @click="clearSettings(), update()">ОЧИСТИТЬ</div>
        </div>

        <div class="w-full">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-10">
                <div></div>
                <div class="fly4 f p-4 relative">
                    <StatsCard
                        :title="'ВСЕГО'"
                        :spent="parseInt(counter_total)"
                        :ads="parseInt(allStat?.total?.ads)"
                        :views="parseInt(allStat?.total?.views)"
                        :clicks="parseInt(allStat?.total?.clicks)"
                        circleColor="rgba(80, 80, 80, 0.05)"
                    />
                </div>
                <div></div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-20">
                <div class="fly3 f p-4 relative">
                    <StatsCard
                        :title="'vk'"
                        :spent="parseInt(counter_vk)"
                        :ads="parseInt(allStat?.vk?.ads)"
                        :views="parseInt(allStat?.vk?.views)"
                        :clicks="parseInt(allStat?.vk?.clicks)"
                        circleColor="rgba(60, 160, 255, 0.05)"
                    />
                </div>

                <div class="fly2 f p-4 relative">
                    <StatsCard
                        :title="'vkads'"
                        :spent="parseInt(counter_vkads)"
                        :ads="parseInt(allStat?.vkads?.ads)"
                        :views="parseInt(allStat?.vkads?.views)"
                        :clicks="parseInt(allStat?.vkads?.clicks)"
                        circleColor="rgba(0, 210, 230, 0.05)"
                    />
                </div>

                <div class="fly2 f p-4 relative">
                    <StatsCard
                        :title="'market'"
                        :spent="parseInt(counter_market)"
                        :ads="parseInt(allStat?.market?.ads)"
                        :views="parseInt(allStat?.market?.views)"
                        :clicks="parseInt(allStat?.market?.clicks)"
                        circleColor="rgba(220, 40, 180, 0.05)"
                    />
                </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
                <div class="fly4 f p-4 relative">
                    <StatsCard
                        :title="'book'"
                        :spent="parseInt(counter_facebook)"
                        :ads="parseInt(allStat?.facebook?.ads)"
                        :views="parseInt(allStat?.facebook?.views)"
                        :clicks="parseInt(allStat?.facebook?.clicks)"
                        circleColor="rgba(80, 100, 255, 0.05)"
                    />
                </div>

                <div class="fly3 f p-4 relative">
                    <StatsCard
                        :title="'yandex'"
                        :spent="parseInt(counter_yandex)"
                        :ads="parseInt(allStat?.yandex?.ads)"
                        :views="parseInt(allStat?.yandex?.views)"
                        :clicks="parseInt(allStat?.yandex?.clicks)"
                        circleColor="rgba(255, 220, 40, 0.08)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useOwnerStore } from "@/store/owner";
import moment from "moment";

import FilterComponent from "@/components/Filter.vue";
import StatsCard from "@/components/StatsCard.vue";

export default {
    name: "OwnerStat",

    components: { FilterComponent, StatsCard },

    data() {
        return {
            moment: moment,

            counter_total: 0,
            counter_market: 0,
            counter_vk: 0,
            counter_vkads: 0,
            counter_facebook: 0,
            counter_yandex: 0,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "profile", "setSettings", "clearSettings"]),
        ...mapState(useOwnerStore, ["stat", "getStat"]),

        allStat() {
            if (!this.stat) return null;

            const zero = { spent: 0, views: 0, clicks: 0, ads: 0 };
            let result = { total: { ...zero } };
            const platforms = ["vk", "vkads", "market", "facebook", "yandex"];

            platforms.forEach((platform, index) => {
                if (this.stat[index + 1]?.spent) {
                    result[platform] = this.stat[index + 1];
                } else {
                    result[platform] = { ...zero };
                }

                // Общий итог
                result.total.spent += result[platform].spent;
                result.total.views += result[platform].views;
                result.total.clicks += result[platform].clicks;
                result.total.ads += result[platform].ads;
            });

            return result;
        },
    },

    methods: {
        update() {
            this.getStat();
        },

        startAnimation() {
            if (!this.allStat) return null;

            const start = performance.now();
            const duration = 3000; // Длительность анимации в миллисекундах

            const increments = [
                { key: "total", target: this.allStat.total?.spent },
                { key: "market", target: this.allStat.market?.spent },
                { key: "vk", target: this.allStat.vk?.spent },
                { key: "vkads", target: this.allStat.vkads?.spent },
                { key: "facebook", target: this.allStat.facebook?.spent },
                { key: "yandex", target: this.allStat.yandex?.spent },
            ];

            const animate = (timestamp) => {
                const progress = Math.min((timestamp - start) / duration, 1); // Прогресс анимации от 0 до 1

                for (let i = 0; i < increments.length; i++) {
                    const item = increments[i];
                    const counterName = "counter_" + item.key;
                    let currentValue = this[counterName] || 0;

                    if (currentValue !== item.target) {
                        const diff = item.target - currentValue;
                        const change = diff * (1 - Math.pow(1 - progress, 2)); // Постепенное уменьшение скорости изменения
                        currentValue = currentValue + change; // Изменение значения счетчика

                        if (currentValue < 0 && currentValue >= diff) currentValue = 0;

                        this[counterName] = currentValue; // Присваиваем новое значение счетчику
                    }
                }

                if (progress < 1) requestAnimationFrame(animate);
            };

            requestAnimationFrame(animate);
        },
    },

    watch: {
        stat: {
            handler() {
                this.startAnimation();
            },
            deep: true,
        },
    },

    mounted() {
        this.getStat();
    },
};
</script>

<style scoped>
.fly2 {
    animation: moveUpDown 6s infinite alternate;
}
.fly3 {
    animation: moveUpDown 8s infinite alternate;
}
.fly4 {
    animation: moveUpDown 10s infinite alternate;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}
</style>
