<template>
    <!-- {{ normalized }} -->
    <div class="w-full relative overflow-hidden p-0 m-0" :class="h">
        <div :id="'chart' + name" class="w-full relative" :class="h"></div>

        <div
            v-if="toolTip?.green >= 0"
            class="flex flex-col justify-center items-center pointer-events-none absolute top-0 z-30 w-40 h-auto p-2 text-xs text-center bg-teal-500 rounded"
            :style="toolTip?.left"
        >
            <span class="text-sm font-bold text-white leading-4">Оплаты: {{ parseInt(toolTip?.green).toLocaleString() }}</span>
        </div>

        <!-- <div
            v-if="toolTip?.blue >= 0"
            class="flex flex-col justify-center items-center pointer-events-none absolute top-5 z-30 w-40 h-auto p-2 text-xs text-center bg-cyan-500 rounded"
            :style="toolTip?.left"
        >
            <span class="text-sm font-bold text-white leading-4">Реги: {{ parseInt(toolTip?.blue).toLocaleString() }}</span>
        </div> -->

        <div
            v-if="toolTip?.red >= 0"
            class="flex flex-col justify-center items-center pointer-events-none absolute top-10 z-30 w-40 h-auto p-2 text-xs text-center bg-pink-500 rounded"
            :style="toolTip?.left"
        >
            <span class="text-sm font-bold text-white leading-4">Расход: {{ parseInt(toolTip?.red).toLocaleString() }}</span>
        </div>
    </div>
</template>

<script>
import { createChart } from "lightweight-charts";
import moment from "moment";

export default {
    name: "MultiGrafAnimated",

    props: {
        red: { type: Array },
        blue: { type: Array },
        green: { type: Array },
        h: { type: String, default: "h-28" },
        name: { type: String, default: "first" },
    },

    data() {
        return {
            chart: null,
            blueLine: null,
            redLine: null,
            greenLine: null,
            toolTip: { blue: -1, red: -1, green: -1 },
            moment: moment,
            animatedData: [],
            animationTimeout: null,
        };
    },

    watch: {
        blue: {
            handler() {
                this.animateGraf();
            },
            deep: true,
        },
        red: {
            handler() {
                this.animateGraf();
            },
            deep: true,
        },
        green: {
            handler() {
                this.animateGraf();
            },
            deep: true,
        },
    },

    computed: {
        normalized() {
            // Находим минимальное и максимальное значения
            let minValue = Infinity;
            let maxValue = -Infinity;

            for (const point of this.red) {
                if (point.value < minValue) minValue = point.value;
                if (point.value > maxValue) maxValue = point.value;
            }

            for (const point of this.blue) {
                if (point.value < minValue) minValue = point.value;
                if (point.value > maxValue) maxValue = point.value;
            }

            for (const point of this.green) {
                if (point.value < minValue) minValue = point.value;
                if (point.value > maxValue) maxValue = point.value;
            }

            const data = {};

            data.red = this.red.map((point) => ({
                time: point.time,
                value: (point.value - minValue) / (maxValue - minValue),
            }));

            data.blue = this.blue.map((point) => ({
                time: point.time,
                value: (point.value - minValue) / (maxValue - minValue),
            }));

            data.green = this.green.map((point) => ({
                time: point.time,
                value: (point.value - minValue) / (maxValue - minValue),
            }));

            return data;
        },
    },

    methods: {
        createChart() {
            const chartContainer = document.getElementById("chart" + this.name);
            if (!chartContainer) return;

            chartContainer.innerHTML = "";

            this.chart = createChart(chartContainer, {
                rightPriceScale: {
                    visible: false,
                    borderVisible: false,
                },
                timeScale: {
                    borderVisible: false,
                    visible: true,
                },
                layout: {
                    background: { color: "transparent" },
                },
                grid: {
                    horzLines: {
                        color: "#eee",
                    },
                    vertLines: {
                        color: "transparent",
                    },
                },
                autoSize: true,
            });

            const options = {
                lineWidth: 2,
                lineType: 2,
                priceLineVisible: false,
                priceScale: {
                    scaleMargins: { top: 0.1, bottom: 0 },
                    borderVisible: false,
                },
                baseValue: { type: "price", price: 0 },
            };

            this.greenLine = this.chart.addAreaSeries({
                name: "green",
                topColor: "rgba(6, 182, 212, 0.5)",
                bottomColor: "rgba(6, 182, 212, 0.04)",
                lineColor: "rgba(6, 182, 212, 0.75)",
                ...options,
            });

            this.blueLine = this.chart.addAreaSeries({
                name: "blue",
                topColor: "rgba(6, 182, 212, 0.5)",
                bottomColor: "rgba(6, 182, 212, 0.04)",
                lineColor: "rgba(6, 182, 212, 0.75)",
                ...options,
            });

            this.redLine = this.chart.addAreaSeries({
                name: "red",
                topColor: "rgba(6, 182, 212, 0.5)",
                bottomColor: "rgba(6, 182, 212, 0.04)",
                lineColor: "rgba(6, 182, 212, 0.75)",
                ...options,
            });

            this.chart.applyOptions({
                autoSize: true,
                handleScroll: false,
                handleScale: false,
                crosshair: { mode: "Magnet" },
                priceScale: { mode: 1, borderVisible: false, minValue: 0 },
            });

            this.chart.timeScale().fitContent();

            chartContainer.addEventListener("mouseenter", () => {
                this.greenLine.applyOptions({
                    topColor: "rgba(20, 184, 166, 0.5)",
                    bottomColor: "rgba(20, 184, 166, 0.04)",
                    lineColor: "rgba(20, 184, 166, 0.75)",
                });
            });

            chartContainer.addEventListener("mouseleave", () => {
                this.greenLine.applyOptions({
                    topColor: "rgba(6, 182, 212, 0.5)",
                    bottomColor: "rgba(6, 182, 212, 0.04)",
                    lineColor: "rgba(6, 182, 212, 0.75)",
                });
            });

            chartContainer.addEventListener("mouseenter", () => {
                this.redLine.applyOptions({
                    topColor: "rgba(236, 72, 153, 0.5)",
                    bottomColor: "rgba(236, 72, 153, 0.04)",
                    lineColor: "rgba(236, 72, 153, 0.75)",
                });
            });

            chartContainer.addEventListener("mouseleave", () => {
                this.redLine.applyOptions({
                    topColor: "rgba(6, 182, 212, 0.5)",
                    bottomColor: "rgba(6, 182, 212, 0.04)",
                    lineColor: "rgba(6, 182, 212, 0.75)",
                });
            });

            this.chart.subscribeCrosshairMove((param) => {
                this.toolTip.red = -1;
                this.toolTip.blue = -1;
                this.toolTip.green = -1;

                if (!param || !param?.point || param?.point.x < 0 || param?.point.y < 0) return;

                if (param.seriesData) {
                    param.seriesData.forEach((data, series) => {
                        const seriesOptions = series.options();
                        if (seriesOptions.name == "red") this.toolTip.red = data.value;
                        if (seriesOptions.name == "blue") this.toolTip.blue = data.value;
                        if (seriesOptions.name == "green") this.toolTip.green = data.value;
                    });
                }

                if (!param.time) return;

                let center = this.chart.timeScale().timeToCoordinate(param?.time) - 80; // 80 половина от ширины плашки (160)
                let chartWidth = document.getElementById("chart" + this.name).offsetWidth;
                if (center < 0) center = 0;
                if (center > chartWidth - 160) center = chartWidth - 160;

                this.toolTip.time = param?.time;
                this.toolTip.left = { left: center + "px" };
            });
        },

        animateGraf() {
            if (this.animationTimeout) clearTimeout(this.animationTimeout);

            this.createChart();

            this.animatedData = [];
            let index = 0;
            const addDataPoint = () => {
                if (index < this.blue.length || index < this.red.length || index < this.green.length) {
                    if (index < this.red.length) this.animatedData.push(this.red[index]);
                    this.redLine.setData(this.animatedData);

                    // if (index < this.blue.length) this.blueLine.setData(this.blue.slice(0, index + 1));
                    if (index < this.green.length) this.greenLine.setData(this.green.slice(0, index + 1));

                    this.chart.timeScale().fitContent();
                    index++;
                    this.animationTimeout = setTimeout(
                        addDataPoint,
                        Math.floor(1000 / Math.max(this.blue.length, this.red.length, this.green.length))
                    );
                }
            };

            addDataPoint();
        },

        handleVisibilityChange() {
            if (!document.hidden) this.animateGraf(); // Если страница становится видимой, повторно создаем график
        },
    },

    mounted() {
        this.animateGraf();
        window.addEventListener("resize", this.animateGraf);
        document.addEventListener("visibilitychange", this.handleVisibilityChange); // Обработка события изменения видимости страницы
    },

    unmounted() {
        window.removeEventListener("resize", this.animateGraf);
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    },
};
</script>
