<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent :view="['date', 'compact', 'targetologs']" @update="update" />
        </div>

        <div class="w-full px-4" v-if="funnel">
            <!-- <div class="flex items-center justify-center gap-4 min-h-screen bg-gray-100">
                <div class="color-circle"></div>
                <div class="color-circle1"></div>
                <div class="color-square"></div>
            </div> -->

            <!-- ИТОГ -->
            <div class="w-full h-32 flex justify-center items-center gap-10 text-white my-12">
                <div class="w-fit h-32 flex flex-col text-center relative fly2 f p-4">
                    <span class="title">РАСХОД</span>
                    <span class="num3 text-pink-500">
                        <span class="latobold"> {{ parseInt(counter_spent).toLocaleString() }}</span>
                        <sup> ₽</sup>
                    </span>
                    <StatsCard circleColor="rgba(220, 40, 180, 0.05)" />
                </div>

                <div class="w-12 pb-20 flex flex-col justify-center items-center">
                    <span class="title">ROMI</span>
                    <span class="text-xl text-slate-500">{{ parseInt(funnel?.romi) }}%</span>
                </div>

                <div class="w-fit h-32 flex flex-col text-center relative fly3 f p-4">
                    <span class="title">ОПЛАТЫ</span>
                    <span class="num3 text-teal-500">
                        <span class="latobold"> {{ parseInt(counter_payments).toLocaleString() }}</span>
                        <sup> ₽</sup>
                    </span>
                    <StatsCard circleColor="rgba(10, 210, 230, 0.05)" />
                </div>
            </div>

            <!-- ВОРОНКА -->
            <div class="flex justify-between gap-2 h-28 my-4 rounded group">
                <div class="flex flex-col justify-center items-center">
                    <span class="title myopacity">РАСХОД</span>
                    <span class="num1 my-1 text-pink-500">{{ parseInt(funnel?.spent).toLocaleString() }} <sup> ₽</sup></span>
                    <span class="title myopacity">&nbsp;</span>
                    <!-- <span class="title myopacity">CPAD {{ parseInt(funnel?.cpad).toLocaleString() }} ₽</span> -->
                </div>

                <div v-if="!s.compact" class="flex flex-col justify-center items-center relative">
                    <span class="title myopacity">ПРОСМОТРЫ</span>
                    <span class="num1 my-1 text-pink-500">{{ parseInt(funnel?.views).toLocaleString() }}</span>
                    <span class="title myopacity">CPM {{ parseInt(funnel?.cpm).toLocaleString() }} ₽</span>
                </div>

                <div class="flex flex-col justify-center items-center relative">
                    <span class="title myopacity">КЛИКИ</span>
                    <span class="num1 my-1 text-pink-500">{{ parseInt(funnel?.clicks).toLocaleString() }}</span>
                    <span class="title myopacity">CPC {{ funnel?.cpc }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.click }}%
                    </div>
                </div>

                <div
                    @click="setPopup('userList')"
                    class="flex flex-col justify-center items-center relative group cursor-pointer hover:bg-white rounded p-2 duration-300"
                >
                    <span class="title myopacity myflex"><IconList :size="3" />ЛИДЫ</span>
                    <span class="num1 my-1 text-cyan-500 duration-300">{{ parseInt(funnel?.regs).toLocaleString() }}</span>
                    <span class="title myopacity">CPL {{ funnel?.cpl }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.reg }}%
                    </div>
                </div>

                <div v-if="!s.compact" class="flex flex-col justify-center items-center relative">
                    <span class="title myopacity">ВЕБИНАР 1</span>
                    <span class="num1 my-1 text-cyan-500">{{ parseInt(funnel?.web1).toLocaleString() }}</span>
                    <span class="title myopacity">CPV {{ funnel?.cpv1 }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.web1 }}%
                    </div>
                </div>

                <div
                    @click="setPopup('orderList')"
                    class="flex flex-col justify-center items-center relative group cursor-pointer hover:bg-white rounded p-2 duration-300"
                >
                    <span class="title myopacity myflex"><IconList :size="3" />ЗАКАЗЫ</span>
                    <span class="num1 my-1 text-cyan-500 duration-300">{{ funnel?.orderc }}</span>
                    <span class="title myopacity">CPO {{ funnel?.cpo }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.order }}%
                    </div>
                </div>

                <div v-if="!s.compact" class="flex flex-col justify-center items-center group cursor-pointer">
                    <span class="title myopacity">СУММА ЗАКАЗОВ</span>
                    <span class="num1 my-1 text-cyan-500 duration-300">{{ parseInt(funnel?.orders).toLocaleString() }} <sup> ₽</sup></span>
                    <span class="title myopacity">СРЕДНИЙ ЧЕК {{ parseInt(funnel?.orders / funnel?.orderc).toLocaleString() }} ₽</span>
                </div>

                <div
                    @click="setPopup('paymentList')"
                    class="flex flex-col justify-center items-center relative group cursor-pointer hover:bg-white rounded p-2 duration-300"
                >
                    <span class="title myopacity myflex"><IconList :size="3" />ОПЛАТЫ</span>
                    <span class="num1 my-1 text-teal-500 duration-300">{{ funnel?.paymentc }}</span>
                    <span class="title myopacity">CAC {{ funnel?.cac }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.payment }}%
                    </div>
                </div>

                <div class="flex flex-col justify-center items-center group cursor-pointer">
                    <span class="title myopacity">СУММА ОПЛАТ</span>
                    <span class="num1 my-1 text-teal-500 duration-300">{{ parseInt(funnel?.payments).toLocaleString() }} <sup> ₽</sup></span>
                    <span class="title myopacity">СРЕДНИЙ ЧЕК {{ parseInt(funnel?.payments / funnel?.paymentc).toLocaleString() }} ₽</span>
                </div>

                <div class="flex flex-col justify-center items-center">
                    <span class="title myopacity">РЕЗУЛЬТАТ</span>
                    <span class="num1 my-1 text-teal-500">{{ parseFloat(funnel?.romi / 100).toFixed(1) }}x</span>
                    <span class="title myopacity"> ROMI {{ parseInt(funnel?.romi).toLocaleString() }} % </span>
                </div>
            </div>

            <!-- <div class="grid grid-cols-5 gap-8">
                    <div class="fly2 f p-4">
                        <StatsCard :title="'vk'" :spent="parseInt(counter_vk)" circleColor="rgba(60, 160, 255, 0.05)" />
                    </div>

                    <div class="fly3 f p-4">
                        <StatsCard :title="'vkads'" :spent="parseInt(counter_vkads)" circleColor="rgba(0, 210, 230, 0.05)" />
                    </div>

                    <div class="fly2 f p-4">
                        <StatsCard :title="'market'" :spent="parseInt(counter_market)" circleColor="rgba(220, 40, 180, 0.05)" />
                    </div>

                    <div class="fly4 f p-4">
                        <StatsCard :title="'book'" :spent="parseInt(counter_facebook)" circleColor="rgba(80, 100, 255, 0.05)" />
                    </div>

                    <div class="fly3 f p-4">
                        <StatsCard :title="'yandex'" :spent="parseInt(counter_yandex)" circleColor="rgba(255, 220, 40, 0.08)" />
                    </div>
                </div> -->

            <div class="w-full flex flex-wrap justify-center items-center gap-20 px-20 my-32 h-">
                <template v-for="(channel, title) in channels" :key="channel">
                    <div v-if="channel?.spent > 0 || channel?.payments > 0" :class="['relative', 'f', `fly${Math.floor(Math.random() * 3) + 1}`]">
                        <StatsCardMini :title="title" :data="channel" />
                    </div>
                </template>
            </div>

            <!-- <h2 class="text-slate-400 text-sm text-center mt-10 mb-4">ГРАФИК</h2> -->
            <template v-if="Object.keys(grafs).length > 0">
                <div class="my-20" @click="accumulation = !accumulation">
                    <MultiGrafAnimated :red="grafs?.spent" :blue="grafs?.regs" :green="grafs?.payments" name="spent" h="h-60" />
                </div>
            </template>

            <!-- <h2 class="text-slate-400 text-sm text-center mt-20">ГРАФИК ЛИДОВ</h2>
            <div v-if="grafs?.spent" class="w-full"><LineGraf :graf="grafs?.spent" name="regs" h="h-48" /></div> -->

            <!-- <h2 class="text-slate-400 text-sm text-center mt-20 mb-8">КАНАЛЫ</h2> -->

            <!-- ТАБЛИЦА КАНАЛОВ -->
            <!-- <h2 class="text-slate-400 text-sm text-center mt-20 mb-2">ВОРОНКА ПО КАНАЛАМ</h2> -->

            <div class="grid grid-cols-11 items-center gap-2 text-right text-xs text-slate-400 mt-20">
                <span></span>
                <span>РАСХОД</span>
                <!-- <span>ОБЪЯВЛЕНИЯ</span> -->
                <span>ПРОСМОТРЫ</span>
                <span>КЛИКИ</span>
                <span>ЛИДЫ</span>
                <span>ВЕБ</span>
                <span>ЗАКАЗЫ</span>
                <span>СУММА&nbsp;ЗАКАЗОВ</span>
                <span class="pr-2">ОПЛАТЫ</span>
                <span class="pr-2">СУММА&nbsp;ОПЛАТ</span>
                <span class="pr-4">ROMI</span>
            </div>

            <div v-for="(channel, title) in channels" :key="channel" class="w-full rounded-xl my-1 text-slate-500 hover:my-2 duration-500">
                <ChannelTable :title="title" :channel="channel" />
            </div>

            <!-- <div class="flex flex-col justify-center items-center rounded p-4 mt-8">
                    <h2 class="text-slate-400 text-sm">UTM TERM</h2>

                    <div class="grid grid-cols-5 items-center gap-4 p-2 w-full text-slate-400 text-xs">
                        <div>КАНАЛ</div>
                        <div>НАЗВАНИЕ</div>
                        <div class="text-right">РАСХОД</div>
                        <div class="text-right">ПРОСМОТРЫ</div>
                        <div class="text-right">КЛИКИ</div>
                    </div>

                    <div v-for="item in dash?.utm_term" :key="item" class="py-px w-full group hover:py-1 relative duration-500">
                        <div
                            class="grid grid-cols-5 items-center gap-4 bg-white/50 text-slate-500 hover:bg-cyan-50 hover:text-cyan-600 p-2 w-full rounded"
                        >
                            <div>{{ item.channel }}</div>
                            <div>{{ item.name }}</div>
                            <div class="text-right tabular-nums">{{ parseInt(item.spent).toLocaleString() }}</div>
                            <div class="text-right tabular-nums">{{ parseInt(item.views).toLocaleString() }}</div>
                            <div class="text-right tabular-nums">{{ parseInt(item.clicks).toLocaleString() }}</div>
                        </div>
                    </div>
                </div> -->

            <template v-if="tgs">
                <h2 class="text-slate-400 text-sm text-center mt-10 mb-4">ТАРГЕТОЛОГИ</h2>

                <div class="w-1/3 mx-auto grid grid-cols-3 gap-4 relative mb-10">
                    <div v-if="tgs[1]" class="w-full flex flex-col justify-center items-center">
                        <img v-if="targetologs[tgs[1]?.id]" :src="targetologs[tgs[1]?.id]?.pic" class="h-20 mt-4 shrink-0 object-contain" />
                        <img v-else :src="'https://statapi.timekraken.ru/uploads/users/u0.png'" class="h-20 mt-4 shrink-0 object-contain" />
                        <div class="w-full bg-cyan-500 text-white text-center rounded p-1">{{ parseInt(tgs[1]?.[s.sortBy]).toLocaleString() }}</div>
                    </div>

                    <div v-if="tgs[0]" class="w-full flex flex-col justify-center items-center">
                        <img v-if="targetologs[tgs[0]?.id]" :src="targetologs[tgs[0]?.id]?.pic" class="h-24 shrink-0 object-contain" />
                        <img v-else :src="'https://statapi.timekraken.ru/uploads/users/u0.png'" class="h-24 shrink-0 object-contain" />
                        <div class="w-full bg-cyan-500 text-white text-center rounded p-2">{{ parseInt(tgs[0]?.[s.sortBy]).toLocaleString() }}</div>
                    </div>

                    <div v-if="tgs[2]" class="w-full flex flex-col justify-center items-center">
                        <img v-if="targetologs[tgs[2]?.id]" :src="targetologs[tgs[2]?.id]?.pic" class="h-20 mt-4 shrink-0 object-contain" />
                        <img v-else :src="'https://statapi.timekraken.ru/uploads/users/u0.png'" class="h-20 mt-4 shrink-0 object-contain" />
                        <div class="w-full bg-cyan-500 text-white text-center rounded p-1">{{ parseInt(tgs[2]?.[s.sortBy]).toLocaleString() }}</div>
                    </div>
                </div>

                <div class="grid grid-cols-11 items-center gap-2 text-right text-xs text-slate-400">
                    <span></span>
                    <span class="cursor-pointer" :class="{ '!text-cyan-500': s.sortBy == 'spent' }" @click="setSort('spent')">РАСХОД</span>
                    <span>ПРОСМОТРЫ</span>
                    <span>КЛИКИ</span>
                    <span class="cursor-pointer" :class="{ '!text-cyan-500': s.sortBy == 'regs' }" @click="setSort('regs')">ЛИДЫ</span>
                    <span>ВЕБ</span>
                    <span>ЗАКАЗЫ</span>
                    <span class="cursor-pointer" :class="{ '!text-cyan-500': s.sortBy == 'orders' }" @click="setSort('orders')"
                        >СУММА&nbsp;ЗАКАЗОВ</span
                    >
                    <span class="pr-2">ОПЛАТЫ</span>
                    <span class="pr-2 cursor-pointer" :class="{ '!text-cyan-500': s.sortBy == 'payments' }" @click="setSort('payments')"
                        >СУММА&nbsp;ОПЛАТ</span
                    >
                    <span class="pr-4 cursor-pointer" :class="{ '!text-cyan-500': s.sortBy == 'romi' }" @click="setSort('romi')">ROMI</span>
                </div>

                <!-- v-if="tg?.id > 0 && parseInt(tg?.spent) > 0" -->
                <template v-for="tg in tgs" :key="tg">
                    <div class="w-full rounded-xl group relative my-1 text-slate-500 hover:my-2 duration-500">
                        <div class="absolute bottom-0 left-1 z-20 w-fit">
                            <!-- :src="'https://statapi.timekraken.ru/uploads/users/mini/' + tg?.pic" -->
                            <img
                                v-if="targetologs[tg.id]"
                                :src="targetologs[tg.id]?.pic"
                                class="h-[36px] shrink-0 object-contain duration-500 opacity-50 group-hover:h-[44px] group-hover:opacity-100"
                            />
                            <img
                                v-else
                                :src="'https://statapi.timekraken.ru/uploads/users/u0.png'"
                                class="h-[36px] shrink-0 object-contain duration-500 opacity-30 group-hover:h-[44px]"
                            />
                        </div>

                        <div
                            class="grid grid-cols-11 justify-center items-center gap-2 text-right text-sm relative overflow-hidden border border-white bg-white/50 hover:bg-white hover:shadow-xl h-10 rounded-xl"
                        >
                            <div v-if="parseInt(tg?.id)" class="pl-12 text-sm text-slate-400 text-center">{{ tg.utm }}</div>
                            <div v-else class="pl-12 text-sm text-slate-400 text-center">NoName</div>

                            <div class="latobold text-pink-500">{{ parseInt(tg.spent).toLocaleString() }}</div>
                            <!-- <div class="latolight">{{ parseInt(tg.ads || 0).toLocaleString() }}</div> -->
                            <div class="latolight">{{ parseInt(tg.views).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(tg.clicks).toLocaleString() }}</div>
                            <div class="latobold text-cyan-500">{{ parseInt(tg.regs).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(tg.web1).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(tg.orderc).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(tg.orders).toLocaleString() }}</div>
                            <div class="latolight pr-2">{{ parseInt(tg.paymentc).toLocaleString() }}</div>
                            <div class="latobold text-teal-500 pr-4">{{ parseInt(tg.payments || 0).toLocaleString() }}</div>

                            <div class="relative top-0 right-4 z-20 h-10 w-full flex justify-end items-center text-white latobold">
                                {{ tg.romi.toLocaleString() }}
                            </div>

                            <span
                                class="absolute z-10 -right-4 top-1/2 transform -translate-y-1/2 w-20 h-20 rotate-45 rounded-xl group-hover:-right-0 duration-500"
                                :class="{
                                    '!bg-cyan-500': tg.romi > 100,
                                    '!bg-slate-300': tg.romi > 0 && tg.romi <= 100,
                                    '!bg-pink-500': tg.romi <= 0,
                                }"
                            >
                            </span>
                        </div>
                    </div>
                </template>
            </template>

            <template v-if="campaigns?.length > 0">
                <h2 class="text-slate-400 text-sm text-center mt-10 mb-4">КАМПАНИИ</h2>

                <div class="grid grid-cols-11 items-center gap-2 text-right text-xs text-slate-400">
                    <span></span>
                    <span>РАСХОД</span>
                    <!-- <span>ОБЪЯВЛЕНИЯ</span> -->
                    <span>ПРОСМОТРЫ</span>
                    <span>КЛИКИ</span>
                    <span>ЛИДЫ</span>
                    <span>ВЕБ</span>
                    <span>ЗАКАЗЫ</span>
                    <span>СУММА&nbsp;ЗАКАЗОВ</span>
                    <span class="pr-2">ОПЛАТЫ</span>
                    <span class="pr-2">СУММА&nbsp;ОПЛАТ</span>
                    <span class="pr-4">ROMI</span>
                </div>

                <template v-for="item in campaigns" :key="item">
                    <div
                        v-if="item?.id > 0 && parseInt(item?.spent) > 0"
                        class="w-full rounded-xl group relative my-1 text-slate-500 hover:my-2 duration-500"
                    >
                        <div
                            class="grid grid-cols-11 justify-center items-center gap-2 text-right text-sm relative overflow-hidden bg-white/50 hover:bg-white h-10 rounded-xl"
                        >
                            <div v-if="parseInt(item?.id)" class="h-12 text-sm text-slate-400 text-center overflow-hidden">{{ item.name }}</div>
                            <div v-else class="h-12 text-sm text-slate-400 text-center">NoName</div>

                            <div class="latobold text-pink-500">{{ parseInt(item.spent).toLocaleString() }}</div>
                            <!-- <div class="latolight">{{ parseInt(item.ads || 0).toLocaleString() }}</div> -->
                            <div class="latolight">{{ parseInt(item.views).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(item.clicks).toLocaleString() }}</div>
                            <div class="latobold text-cyan-500">{{ parseInt(item.regs).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(item.web1).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(item.orderc).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(item.orders).toLocaleString() }}</div>
                            <div class="latolight pr-2">{{ parseInt(item.paymentc).toLocaleString() }}</div>
                            <div class="latobold text-teal-500 pr-4">{{ parseInt(item.payments || 0).toLocaleString() }}</div>

                            <div
                                v-if="item.spent > 0"
                                class="relative top-0 right-4 z-20 h-10 w-full flex justify-end items-center text-white latobold"
                            >
                                {{ parseInt((item.payments * 100) / (item.spent || 1) || 0).toLocaleString() }}
                            </div>
                            <div v-else class="relative top-0 right-4 z-20 h-10 w-full flex justify-end items-center text-white latobold">-</div>

                            <span
                                class="absolute z-10 -right-4 top-1/2 transform -translate-y-1/2 w-20 h-20 rotate-45 rounded-xl group-hover:-right-0 duration-500"
                                :class="{
                                    '!bg-cyan-500': parseInt(item.payments * 100) / item.spent > 100,
                                    '!bg-slate-300': parseInt(item.payments * 100) / item.spent <= 100,
                                }"
                            >
                            </span>
                        </div>
                    </div>
                </template>
            </template>

            <template v-if="crs?.length > 0">
                <h2 class="text-slate-400 text-sm text-center mt-10 mb-4">КРЕАТИВЫ</h2>

                <div class="grid grid-cols-11 items-center gap-2 text-right text-xs text-slate-400">
                    <span></span>
                    <span>РАСХОД</span>
                    <span>ПРОСМОТРЫ</span>
                    <span>КЛИКИ</span>
                    <span>ЛИДЫ</span>
                    <span>ВЕБ</span>
                    <span>ЗАКАЗЫ</span>
                    <span>СУММА&nbsp;ЗАКАЗОВ</span>
                    <span class="pr-2">ОПЛАТЫ</span>
                    <span class="pr-2">СУММА&nbsp;ОПЛАТ</span>
                    <span class="pr-4">ROMI</span>
                </div>

                <template v-for="tg in crs" :key="tg">
                    <div
                        v-if="tg?.id > 0 && parseInt(tg?.spent) > 0"
                        class="w-full rounded-xl group relative my-1 text-slate-500 hover:my-2 duration-500"
                    >
                        <!-- <div class="w-full absolute bottom-0 left-1 z-20"> -->
                        <!-- :src="'https://statapi.timekraken.ru/uploads/users/mini/' + tg?.pic" -->
                        <!-- <img
                                v-if="targetologs[tg.id]"
                                :src="targetologs[tg.id]?.pic"
                                class="h-[36px] shrink-0 object-contain duration-500 opacity-50 group-hover:h-[44px] group-hover:opacity-100"
                            />
                            <img
                                v-else
                                :src="'https://statapi.timekraken.ru/uploads/users/u0.png'"
                                class="h-[36px] shrink-0 object-contain duration-500 opacity-30 group-hover:h-[44px]"
                            /> -->
                        <!-- </div> -->

                        <!-- <template v-if="tg.name"><ToolTip :tip="tg.name" /></template> -->

                        <div
                            class="grid grid-cols-11 justify-center items-center gap-2 text-right text-sm relative overflow-hidden bg-white/50 hover:bg-white h-10 rounded-xl"
                        >
                            <div v-if="parseInt(tg?.id)" class="pl-2 flex justify-start items-start gap-1 text-sm text-slate-400 text-center">
                                #{{ tg.id }}
                            </div>
                            <div v-else class="pl-2 text-sm text-slate-400 text-center">NoName</div>

                            <div class="latobold text-pink-500">{{ parseInt(tg.spent).toLocaleString() }}</div>
                            <!-- <div class="latolight">{{ parseInt(tg.ads || 0).toLocaleString() }}</div> -->
                            <div class="latolight">{{ parseInt(tg.views).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(tg.clicks).toLocaleString() }}</div>
                            <div class="latobold text-cyan-500">{{ parseInt(tg.regs).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(tg.web1).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(tg.orderc).toLocaleString() }}</div>
                            <div class="latolight">{{ parseInt(tg.orders).toLocaleString() }}</div>
                            <div class="latolight pr-2">{{ parseInt(tg.paymentc).toLocaleString() }}</div>
                            <div class="latobold text-teal-500 pr-4">{{ parseInt(tg.payments || 0).toLocaleString() }}</div>

                            <div
                                v-if="tg.spent > 0"
                                class="relative top-0 right-4 z-20 h-10 w-full flex justify-end items-center text-white latobold"
                            >
                                {{ parseInt((tg.payments * 100) / (tg.spent || 1) || 0).toLocaleString() }}
                            </div>
                            <div v-else class="relative top-0 right-4 z-20 h-10 w-full flex justify-end items-center text-white latobold">-</div>

                            <span
                                class="absolute z-10 -right-4 top-1/2 transform -translate-y-1/2 w-20 h-20 rotate-45 rounded-xl group-hover:-right-0 duration-500"
                                :class="{
                                    '!bg-cyan-500': parseInt(tg.payments * 100) / tg.spent > 100,
                                    '!bg-slate-300': parseInt(tg.payments * 100) / tg.spent <= 100,
                                }"
                            >
                            </span>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>

    <template v-if="s.popup == 'userList' && dashboard?.payments"><UserList /></template>
    <template v-if="s.popup == 'userInfo' && user?.id"><UserInfo /></template>
    <template v-if="s.popup == 'orderList' && dashboard?.orders"><OrderList /></template>
    <template v-if="s.popup == 'paymentList' && dashboard?.payments"><PaymentList /></template>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

import FilterComponent from "../components/Filter.vue";
import MultiGrafAnimated from "../components/Graf/MultiGrafAnimated.vue";
import StatsCard from "@/components/StatsCard.vue";
import StatsCardMini from "@/components/StatsCardMini.vue";
import ChannelTable from "@/components/ChannelTable.vue";

import UserList from "../components/UserList.vue";
import UserInfo from "../components/UserInfo.vue";
import OrderList from "../components/OrderList.vue";
import PaymentList from "../components/PaymentList.vue";

// import ToolTip from "../components/ToolTip.vue";

export default {
    name: "ClientDashboard",

    components: { FilterComponent, MultiGrafAnimated, StatsCard, StatsCardMini, ChannelTable, UserList, UserInfo, OrderList, PaymentList },

    data() {
        return {
            moment: moment,
            accumulation: false,

            counter_spent: 0,
            counter_payments: 0,
            counter_vk: 0,
            counter_vkads: 0,
            counter_market: 0,
            counter_facebook: 0,
            counter_yandex: 0,
            counter_tgads: 0,
            animationInterval: null,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "targetologs", "profile", "setPopup", "setSort"]),
        ...mapState(useClientStore, ["project", "dataAll", "dashboard", "user", "getData", "updateData"]),

        funnel() {
            if (!this.dashboard) return [];

            const total = { romi: 0, cpm: 0, cpc: 0, cpl: 0, ctr: 0, cpo: 0, cac: 0 };

            this.dashboard.forEach((day) => {
                const channels = Object.values(day.channels); // Convert channels object to array of values
                channels.forEach((channel) => {
                    for (const key in channel) {
                        if (!total[key]) total[key] = 0;
                        total[key] += channel[key];
                    }
                });
            });

            // if (this.s.nds) data.spent = data.spent * 1.2;

            // total.cpad = parseFloat(total.spent / total.ads).toFixed(2);
            total.cpm = parseFloat((total.spent / total.views) * 1000).toFixed(2);
            total.cpc = parseInt(total.spent / total.clicks);
            total.cpl = parseInt(total.spent / total.regs);
            total.cpv1 = parseInt(total.spent / total.web1);
            total.ctr = parseInt(total.clicks / (total.views / 100));
            total.cpo = parseInt(total.spent / total.orderc);
            total.cac = parseInt(total.spent / total.paymentc);
            total.romi = parseFloat(((total.payments - total.spent) / total.spent) * 100).toFixed(2);

            return total;
        },

        conversion() {
            if (!this.funnel) return [];

            let data = {};

            data.click = parseFloat((this.funnel.clicks / this.funnel.views) * 100).toFixed(2);
            data.reg = parseFloat((this.funnel.regs / this.funnel.clicks) * 100).toFixed(2);
            data.web1 = parseFloat((this.funnel.web1 / this.funnel.regs) * 100).toFixed(2);
            data.order = parseFloat((this.funnel.orderc / this.funnel.web1) * 100).toFixed(2);
            data.payment = parseFloat((this.funnel.payments / this.funnel.orders) * 100).toFixed(2);

            return data;
        },

        channels() {
            if (!this.dashboard) return [];

            const totals = {};

            this.dashboard.forEach((day) => {
                // Здесь мы используем Object.entries, чтобы получить доступ к ключам и значениям
                Object.entries(day.channels).forEach(([channelKey, channelData]) => {
                    // Проверяем, существует ли уже запись для этого канала в totals
                    if (!totals[channelKey]) {
                        // Инициализация объекта для нового канала
                        totals[channelKey] = {
                            spent: 0,
                            views: 0,
                            clicks: 0,
                            regs: 0,
                            web1: 0,
                            web2: 0,
                            web3: 0,
                            orderc: 0,
                            orders: 0,
                            paymentc: 0,
                            payments: 0,
                            cpm: 0,
                            cpc: 0,
                            cpl: 0,
                            ctr: 0,
                            cpo: 0,
                            cac: 0,
                            romi: 0,
                        };
                    }
                    // Суммирование данных для канала
                    for (const key in channelData) {
                        if (totals[channelKey][key] !== undefined) totals[channelKey][key] += channelData[key];
                    }
                });
            });

            // Рассчитываем метрики
            Object.values(totals).forEach((item) => {
                item.cpm = parseFloat((item.spent / item.views) * 1000).toFixed(2);
                item.cpc = parseInt(item.spent / item.clicks);
                item.cpl = parseInt(item.spent / item.regs);
                item.cpv1 = parseInt(item.spent / item.web1);
                item.ctr = parseInt(item.clicks / (item.views / 100));
                item.cpo = parseInt(item.spent / item.orderc);
                item.cac = parseInt(item.spent / item.paymentc);
                if (item.payments && item.spent) {
                    item.romi = parseFloat(((item.payments - item.spent) / item.spent) * 100).toFixed(2);
                }
            });

            // Преобразуем totals в массив и сортируем по romi
            const sortedTotals = Object.entries(totals).sort((a, b) => b[1].romi - a[1].romi);

            const sortedTotalsObject = {};
            sortedTotals.forEach(([key, value]) => (sortedTotalsObject[key] = value));

            return sortedTotalsObject;
        },

        grafs() {
            if (!this.dashboard) return [];

            // Инициализируем массив, который будет хранить суммы по дням
            const data = { spent: [], regs: [], payments: [] };

            // Переменные для накопления значений
            let totalSpent = 0;
            let totalRegs = 0;
            let totalPayments = 0;

            // Перебираем каждый элемент dashboard (каждый день)
            this.dashboard.forEach((day) => {
                let spent = 0;
                let regs = 0;
                let payments = 0;

                // Получаем все каналы за этот день
                const channels = Object.values(day.channels);

                // Перебираем все каналы и суммируем затраты
                channels.forEach((channel) => {
                    if (channel.spent) spent += channel.spent; // Добавляем к сумме за день
                    if (channel.regs) regs += channel.regs; // Добавляем к сумме за день
                    if (channel.payments) payments += channel.payments; // Добавляем к сумме за день
                });

                // Если включен режим накопления, суммируем с предыдущими днями
                if (this.accumulation) {
                    totalSpent += spent;
                    totalRegs += regs;
                    totalPayments += payments;

                    data.spent.push({ time: day.date, value: totalSpent });
                    data.regs.push({ time: day.date, value: totalRegs });
                    data.payments.push({ time: day.date, value: totalPayments });
                } else {
                    data.spent.push({ time: day.date, value: spent });
                    data.regs.push({ time: day.date, value: regs });
                    data.payments.push({ time: day.date, value: payments });
                }
            });

            // Сортировка массивов по ключу time
            data.spent.sort((a, b) => new Date(a.time) - new Date(b.time));
            data.regs.sort((a, b) => new Date(a.time) - new Date(b.time));
            data.payments.sort((a, b) => new Date(a.time) - new Date(b.time));

            return data;
        },

        tgs() {
            if (!this.dashboard) return [];

            const totals = {};

            this.dashboard.forEach((day) => {
                Object.entries(day.targetologs).forEach(([key, data]) => {
                    if (!totals[key]) {
                        totals[key] = {
                            spent: 0,
                            views: 0,
                            clicks: 0,
                            regs: 0,
                            web1: 0,
                            web2: 0,
                            web3: 0,
                            orderc: 0,
                            orders: 0,
                            paymentc: 0,
                            payments: 0,
                            cpm: 0,
                            cpc: 0,
                            cpl: 0,
                            ctr: 0,
                            cpo: 0,
                            cac: 0,
                            romi: 0,
                        };
                    }
                    // Суммирование данных для канала
                    for (const k in data) {
                        if (totals[key][k] !== undefined) totals[key][k] += data[k];
                    }
                });
            });

            // Обогатим totals из массива таргетологов this.dataAll.targetologs
            if (this.dataAll.targetologs) {
                Object.values(this.dataAll.targetologs).forEach((tg) => {
                    if (totals[tg.id]) {
                        totals[tg.id].id = tg.id;
                        totals[tg.id].name = tg.name;
                        totals[tg.id].utm = tg.utm;
                        totals[tg.id].pic = tg.pic;
                    }
                });
            }

            // Рассчитываем метрики
            Object.values(totals).forEach((item) => {
                item.cpm = parseFloat((item.spent / item.views) * 1000).toFixed(2);
                item.cpc = parseInt(item.spent / item.clicks);
                item.cpl = parseInt(item.spent / item.regs);
                item.cpv1 = parseInt(item.spent / item.web1);
                item.ctr = parseInt(item.clicks / (item.views / 100));
                item.cpo = parseInt(item.spent / item.orderc);
                item.cac = parseInt(item.spent / item.paymentc);
                if (item.payments && item.spent) {
                    item.romi = parseInt(((item.payments - item.spent) / item.spent) * 100);
                } else {
                    item.romi = -Infinity;
                }
            });

            // TODO вынести в отдельную функцию
            // Преобразуем totals в массив и сортируем по romi
            const sortedTotals = Object.entries(totals)
                .sort((a, b) => {
                    const fieldA = a[1][this.s.sortBy];
                    const fieldB = b[1][this.s.sortBy];

                    if (this.s.sortDesc) {
                        return fieldB - fieldA; // Сортировка по убыванию
                    } else {
                        return fieldA - fieldB; // Сортировка по возрастанию
                    }
                })
                .map((entry) => entry[1]); // Извлекаем только значения, игнорируя ключи

            // // Преобразуем totals в массив и сортируем по romi
            // const sortedTotals = Object.entries(totals)
            //     .sort((a, b) => b[1].romi - a[1].romi) // Сортируем
            //     .map((entry) => entry[1]); // Извлекаем только значения, игнорируя ключи

            return sortedTotals;
        },

        orders() {
            if (!this.dashboard || !this.dashboard?.orders) return [];

            let data = JSON.parse(JSON.stringify(this.dashboard.grafs));
            if (this.s.nds && data.spent) for (let item in data.spent) data.spent[item].value *= 1.2;

            return data;
        },

        crs() {
            if (!this.dashboard || !this.dashboard?.creatives) return [];

            let data = Object.values(this.dashboard?.creatives).map((item) => {
                return {
                    ...item,
                    romi: item.spent ? parseInt(((item.payments - item.spent) / item.spent) * 100) : 0,
                };
            });

            return data.sort((a, b) => b.romi - a.romi);
        },

        campaigns() {
            if (!this.dashboard || !this.dashboard?.campaigns) return [];

            let data = Object.values(this.dashboard?.campaigns).map((item) => {
                return {
                    ...item,
                    romi: item.spent ? parseInt(((item.payments - item.spent) / item.spent) * 100) : 0,
                };
            });

            return data.sort((a, b) => b.romi - a.romi);
        },
    },

    watch: {
        dashboard: {
            handler() {
                this.startAnimation();
            },
            deep: true,
        },

        s: {
            handler() {
                this.startAnimation();
            },
            deep: true,
        },
    },

    methods: {
        update() {
            this.updateData();
        },

        startAnimation() {
            if (!this.dashboard) return null;

            const start = performance.now();
            const duration = 1000; // Длительность анимации в миллисекундах

            const increments = [
                { key: "spent", target: this.funnel?.spent },
                { key: "payments", target: this.funnel?.payments },
                { key: "vk", target: this.channels?.vk?.spent },
                { key: "vkads", target: this.channels?.vkads?.spent },
                { key: "market", target: this.channels?.market?.spent },
                { key: "facebook", target: this.channels?.facebook?.spent },
                { key: "yandex", target: this.channels?.yandex?.spent },
                { key: "tgads", target: this.channels?.tgads?.spent },
            ];

            const animate = (timestamp) => {
                const progress = Math.min((timestamp - start) / duration, 1); // Прогресс анимации от 0 до 1

                for (let i = 0; i < increments.length; i++) {
                    const item = increments[i];
                    const counterName = "counter_" + item.key;
                    let currentValue = this[counterName] || 0;

                    if (currentValue !== item.target) {
                        const diff = item.target - currentValue;
                        const change = diff * (1 - Math.pow(1 - progress, 2)); // Постепенное уменьшение скорости изменения
                        currentValue = currentValue + change; // Изменение значения счетчика

                        if (currentValue < 0 && currentValue >= diff) currentValue = 0;

                        this[counterName] = currentValue; // Присваиваем новое значение счетчику
                    }
                }

                if (progress < 1) requestAnimationFrame(animate);
            };

            requestAnimationFrame(animate);
        },
    },

    mounted() {
        this.getData();
    },
};
</script>

<style scoped>
.fly2 {
    animation: moveUpDown 6s infinite alternate;
}
.fly3 {
    animation: moveUpDown 8s infinite alternate;
}
.fly4 {
    animation: moveUpDown 10s infinite alternate;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}

.color-circle1 {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: conic-gradient(from 0deg at 50% 50%, #5edaf0 0%, #5edaf0 16%, #ff96ce 33%, #ff96ce 50%, #68ffeb 66%, #68ffeb 83%, #5edaf0 100%);
}

.color-circle1::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, white 0%, rgba(255, 255, 255, 0) 70%);
    border-radius: 50%;
}
.color-circle {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: radial-gradient(circle at 100% 50%, #5edaf0, transparent 50%), radial-gradient(circle at 25% 93.3%, #ff96ce, transparent 50%),
        radial-gradient(circle at 25% 6.7%, #68ffeb, transparent 50%);
    background-repeat: no-repeat;
}

.color-square {
    width: 500px; /* Большой квадрат */
    height: 500px;
    background: radial-gradient(circle at 20% 30%, #5edaf0, transparent 150px), radial-gradient(circle at 40% 70%, #ff96ce, transparent 150px),
        radial-gradient(circle at 60% 50%, #68ffeb, transparent 150px), radial-gradient(circle at 80% 20%, #ff96ce, transparent 150px),
        radial-gradient(circle at 10% 80%, #5edaf0, transparent 150px);
    background-color: #f0f0f0; /* Цвет фона для заполнения пространства между градиентами */
}
</style>
