<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent :view="['compact']" />
        </div>

        <div class="w-full px-4">
            <div class="flex flex-col bg-white rounded p-8 mb-4">
                <span>ВОРОНКА</span>
                <!-- {{ funnel }} -->
            </div>

            <div class="w-full flex gap-4">
                <div class="w-1/2">
                    <template v-for="item in funnel" :key="item">
                        <div v-if="!s.compact || item.type == 1" class="bg-white rounded p-4 mb-4">
                            <div class="flex justify-start items-center gap-1 relative">
                                {{ parseInt(item.count).toLocaleString() }}
                                <template v-if="item.tip"><ToolTip :tip="item.tip" /></template>
                            </div>
                            <div class="title">{{ item.name }}</div>
                        </div>
                    </template>
                </div>

                <div class="w-1/2">
                    <div class="bg-white rounded p-4 mb-4">
                        <div>{{ parseInt(2766721).toLocaleString() }} <sup>₽</sup></div>
                        <div class="title">РАСХОД</div>
                    </div>

                    <div v-if="!s.compact" class="bg-white rounded p-4 mb-4">
                        <div class="flex flex-col mb-4">
                            <div class="flex justify-start items-center gap-1 relative">
                                {{ parseInt(3582899).toLocaleString() }}
                                <ToolTip tip="По данным социальной сети" />
                            </div>
                            <span class="title">Увидели рекламу</span>
                        </div>

                        <div class="flex flex-col mb-4">
                            {{ parseInt(49619).toLocaleString() }}
                            <span class="title">Кликов на рекламу</span>
                            <span class="title">По данным социальной сети</span>
                        </div>

                        <div class="flex flex-col mb-4">
                            {{ parseInt(42563).toLocaleString() }}
                            <span class="title">Людей на лендинге</span>
                            <span class="title">По данным визитов из метрики</span>
                        </div>

                        <div class="flex flex-col">
                            {{ parseInt(15381).toLocaleString() }}
                            <span class="title">Начали заполнять форму</span>
                            <span class="title">По данным цели из метрики</span>
                        </div>
                    </div>

                    <div class="bg-white rounded p-4 mb-4">
                        <div class="flex flex-col">
                            <div>{{ parseInt(8919).toLocaleString() }}</div>
                            <div class="title">ЛИДЫ</div>
                        </div>
                    </div>

                    <div v-if="!s.compact" class="bg-white rounded p-4 mb-4">
                        <div class="flex flex-col mb-4">
                            {{ parseInt(8890).toLocaleString() }}
                            <span class="title">Получили письмо/сообщение</span>
                            <span class="title">По данным из ???</span>
                        </div>

                        <div class="flex flex-col">
                            {{ parseInt(6473).toLocaleString() }}
                            <span class="title">Зарегистрировались на вебинар</span>
                            <span class="title">По данным из ???</span>
                        </div>
                    </div>

                    <div class="bg-white rounded p-4 mb-4">
                        <div class="flex flex-col">
                            <div>{{ parseInt(5916).toLocaleString() }}</div>
                            <div class="title">ВЕБИНАР</div>
                        </div>
                    </div>

                    <div v-if="!s.compact" class="bg-white rounded p-4 mb-4">
                        <div class="flex flex-col mb-4">
                            {{ parseInt(3473).toLocaleString() }}
                            <span class="title">Досидели до кнопки</span>
                            <span class="title">По данным bizon</span>
                        </div>

                        <div class="flex flex-col mb-4">
                            {{ parseInt(1798).toLocaleString() }}
                            <span class="title">Нажали на кнопку</span>
                            <span class="title">По данным bizon</span>
                        </div>

                        <div class="flex flex-col mb-4">
                            {{ parseInt(1587).toLocaleString() }}
                            <span class="title">Перешли на лендинг</span>
                            <span class="title">По данным метрики</span>
                        </div>

                        <div class="flex flex-col mb-4">
                            {{ parseInt(1232).toLocaleString() }}
                            <span class="title">Начали заполнять форму</span>
                            <span class="title">По данным цели из метрики</span>
                        </div>

                        <div class="flex flex-col mb-4">
                            {{ parseInt(5916).toLocaleString() }}
                            <span class="title">Получили письмо с дожимом</span>
                            <span class="title">По данным из ???</span>
                        </div>

                        <div class="flex flex-col mb-4">
                            {{ parseInt(587).toLocaleString() }}
                            <span class="title">Перешли на лендинг</span>
                            <span class="title">По данным метрики ???</span>
                        </div>

                        <div class="flex flex-col">
                            {{ parseInt(279).toLocaleString() }}
                            <span class="title">Начали заполнять форму</span>
                            <span class="title">По данным из ???</span>
                        </div>
                    </div>

                    <div class="bg-white rounded p-4 mb-4">
                        <div class="flex flex-col">
                            <div>{{ parseInt(1033).toLocaleString() }}</div>
                            <div class="title">ЗАКАЗ</div>
                        </div>
                    </div>

                    <div v-if="!s.compact" class="bg-white rounded p-4 mb-4">
                        <div class="flex flex-col">
                            ???
                            <span class="title">Как разметить?</span>
                            <span class="title">По данным из AMO ???</span>
                        </div>
                    </div>

                    <div class="bg-white rounded p-4 mb-4">
                        <div class="flex flex-col mb-4">
                            <div>{{ parseInt(275).toLocaleString() }}</div>
                            <div class="title">ОПЛАТА</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAccountStore } from "@/store/account";
import { useFunnelStore } from "@/store/funnel";
import moment from "moment";

import FilterComponent from "../components/Filter.vue";
import ToolTip from "../components/ToolTip.vue";

export default {
    name: "FunnelPage",

    components: { FilterComponent, ToolTip },

    data() {
        return {
            moment: moment,

            funnel: [
                {
                    name: "РАСХОД",
                    count: 2766721,
                    type: 1,
                },
                {
                    name: "Увидели рекламу",
                    count: 3582899,
                    tip: "По данным социальной сети",
                },
                {
                    name: "Кликов на рекламу",
                    count: 49619,
                    tip: "По данным социальной сети",
                },
                {
                    name: "Людей на лендинге",
                    count: 42563,
                    tip: "По данным визитов из метрики",
                },
                {
                    name: "Начали заполнять форму",
                    count: 15381,
                },
                {
                    name: "ЛИДЫ",
                    count: 8919,
                    type: 1,
                },
                {
                    name: "Получили письмо/сообщение",
                    count: 8890,
                },
                {
                    name: "Зарегистрировались на вебинар",
                    count: 6473,
                },
                {
                    name: "ВЕБИНАР",
                    count: 5916,
                    type: 1,
                },
                {
                    name: "Досидели до кнопки",
                    count: 3473,
                },
                {
                    name: "Нажали на кнопку",
                    count: 1798,
                },
                {
                    name: "Перешли на лендинг",
                    count: 1587,
                },
                {
                    name: "Начали заполнять форму",
                    count: 1232,
                },
                {
                    name: "Получили письмо с дожимом",
                    count: 5916,
                },
                {
                    name: "Перешли на лендинг",
                    count: 587,
                },
                {
                    name: "Начали заполнять форму",
                    count: 279,
                },
                {
                    name: "ЗАКАЗ",
                    count: 1033,
                    type: 1,
                },
                {
                    name: "ОПЛАТА",
                    count: 275,
                    type: 1,
                },
            ],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "profile", "setPeriod", "setMonth", "setSettings"]),
        ...mapState(useAccountStore, ["funnel", "events"]),
        ...mapState(useFunnelStore, ["getFunnel"]),
    },

    mounted() {
        // this.getFunnel();
    },
};
</script>

<style scoped>
.tip {
    @apply hidden absolute bg-gray-200 text-gray-700 text-xs p-1 rounded shadow-lg;
    top: 1.5rem;
    left: 0;
}

.info-icon {
    @apply text-blue-500 text-lg;
}
</style>
