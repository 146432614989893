<template>
    <div :style="circleStyle" class="circle" ref="circle"></div>
</template>

<script>
export default {
    name: "CircleS",

    props: {
        color: {
            type: String,
            default: "rgba(0, 0, 255, 0.5)",
        },
    },

    data() {
        return {
            parentWidth: 0,
            parentHeight: 0,
            finalSize: Math.floor(Math.random() * 251) + 50, // Случайный конечный размер от 50px до 300px
        };
    },

    mounted() {
        this.getParentSize();
        this.startAnimation();
    },

    methods: {
        getParentSize() {
            const parent = this.$el.parentNode;
            if (parent) {
                this.parentWidth = parent.clientWidth;
                this.parentHeight = parent.clientHeight;
            }
        },
        startAnimation() {
            this.$nextTick(() => {
                const circle = this.$refs.circle;
                if (circle) {
                    // Устанавливаем начальные размеры
                    circle.style.width = "1px";
                    circle.style.height = "1px";

                    // Запускаем анимацию через requestAnimationFrame для обеспечения выполнения после отрисовки начальных стилей
                    requestAnimationFrame(() => {
                        circle.style.transition = "width 1s ease-in-out, height 1s ease-in-out";
                        circle.style.width = `${this.finalSize}px`;
                        circle.style.height = `${this.finalSize}px`;
                    });
                }
            });
        },
    },

    computed: {
        circleStyle() {
            // Проверяем, что родительский контейнер существует и имеет размеры
            if (this.parentWidth && this.parentHeight) {
                const top = Math.random() * (this.parentHeight - this.finalSize);
                const left = Math.random() * (this.parentWidth - this.finalSize);

                return {
                    top: `${top}px`,
                    left: `${left}px`,
                    position: "absolute",
                    borderRadius: "50%",
                    backgroundColor: this.color,
                    width: "1px", // Начальные размеры
                    height: "1px", // Начальные размеры
                };
            } else {
                return {};
            }
        },
    },
};
</script>

<style scoped>
.circle {
    position: absolute;
    border-radius: 50%;
}
</style>
