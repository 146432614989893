import { defineStore } from "pinia";
import API from "../services/api";
import axios from "axios";
import router from "@/services/router";
import { useMainStore as mainStore } from "./main";
import { useClientStore as clientStore } from "./client";

export const useProfileStore = defineStore("profile", {
    state: () => ({
        menus: {
            An: [
                {
                    name: "Админка",
                    url: "/admin",
                    children: [
                        { name: "Проекты", url: "/admin/projects" },
                        { name: "Кабинеты", url: "/admin/clients" },
                        { name: "Статистика", url: "/admin/stat" },
                        { name: "Пользователи", url: "/admin/users" },
                    ],
                },
                { name: "Монитор", url: "/admin/monitor" },
                { name: "Кабинеты", url: "/clients" },
            ],
            Or: [
                { name: "Статистика", url: "/owner/stat" },
                { name: "Кабинеты", url: "/owner/cabinets" },
                {
                    name: "Проекты",
                    url: "/owner/",
                    children: [{ name: "Проект", url: "/owner/project" }],
                },
                { name: "Месяцы", url: "/owner/month" },
                { name: "Таргетологи", url: "/owner/targetologs" },
            ],
            Pm: [
                { name: "Проекты", url: "/manager/projects" },
                { name: "Клиенты", url: "/manager/clients" },
                { name: "Таргетологи", url: "/manager/targetologs" },
                { name: "Отчет Групп", url: "/manager/report-group" },
                { name: "Отчет Об", url: "/manager/report-ad" },
            ],
            Cl: [
                {
                    name: "Проекты",
                    url: "/projects",
                    children: [
                        { name: "Настройки", url: "/settings" },
                        { name: "В", url: "/funnel" },
                    ],
                },
                { name: "Дашборд", url: "/dashboard" },
                { name: "Кабинеты", url: "/clients" },
                { name: "Месяцы", url: "/month" },
                { name: "РнП", url: "/days" },
                { name: "Вебинары", url: "/webinars" },
            ],
        },
        menu: {},
        profile: {},
    }),

    actions: {
        async login(data) {
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("password", data.password);

            const res = await axios.post("https://statapi.timekraken.ru/site/login", formData);
            console.log("login res", res);

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            if (res.data.token) localStorage.setItem("AUTH", res.data.token);
            if (res.data.profile) this.setProfile(res.data.profile);
            console.log("this.profile.role", this.profile.role);

            if (res.data.projects) clientStore().projects = res.data.projects;

            if (this.menu && this.menu[0].url) {
                router.push(this.menu[0].url);
                console.log("url", this.menu[0].url);
            } else {
                router.push("/profile");
                console.log("url нет", this.menu[0]);
            }
        },

        // async registration(data) {
        //     const res = await axios.post("https://statapi.timekraken.ru/site/registration", data);
        //     if (res.data.alert) mainStore().setAlert(res.data.alert);
        //     if (res.data && res.data.token) {
        //         this.profile = res.data.profile;
        //         this.menu = res.data.menu;
        //         if (!this.profile || !this.menu) router.push("/");
        //         localStorage.setItem("AUTH", res.data.token);
        //         localStorage.setItem("profile", JSON.stringify(this.profile));
        //         localStorage.setItem("menu", JSON.stringify(this.menu));
        //         if (this.profile.r == "Mn") return router.push("/dashboard");
        //         router.push("/dashboard");
        //     }
        // },

        logout() {
            localStorage.removeItem("AUTH");
            localStorage.removeItem("profile");
            localStorage.removeItem("picture");
            localStorage.removeItem("username");
            this.profile = null;
            this.menu = null;
            router.push("/");
        },

        setProfile(profile) {
            this.profile = profile;
            if (this.profile?.role) this.menu = this.menus[this.profile?.role];
            if (profile) localStorage.setItem("profile", JSON.stringify(profile));
            if (profile?.picture) localStorage.setItem("picture", profile.picture);
            if (profile?.username) localStorage.setItem("username", profile.username);
        },

        async getProfile() {
            if (this.profile?.id) return;

            // получить профиль из localstore
            let p = localStorage.getItem("profile");
            if (p && JSON.parse(p)) {
                this.setProfile(JSON.parse(p));
                return;
            }

            // получить профиль из API
            const res = await API.GET("user/user/profile");

            if (res.data && res.data.profile && res.data.menu) {
                this.setProfile(res.data.profile);
                if (this.profile && this.menu && this.menu[0].url && router.currentRoute._value.name == "Main") router.push(this.menu[0].url);
            } else {
                this.profile = null;
                this.menu = null;
            }
        },

        async uploadAvatar(data) {
            // console.log("uploadAvatar", data);
            let formData = new FormData();

            let file = data.e.target.files[0];

            let ext = "не определилось";
            let parts = file.name.split(".");
            if (parts.length > 1) ext = parts.pop();

            if (!["jpeg", "jpg", "png"].includes(ext)) {
                mainStore().setAlert({ msg: "Неправильный формат. Загрузите .jpg .png", type: "error" });
                return;
            }

            formData.append("picture", file);

            const res = await API.POST("user/user/upload-avatar", formData, { headers: { "Content-Type": "multipart/form-data" } });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            console.log(res.data);
            if (res.data && res.data.profile) {
                this.profile = res.data.profile;
                if (this.profile.picture) localStorage.setItem("picture", this.profile.picture);
            }
        },

        async changeRole(role) {
            const formData = new FormData();
            formData.append("role", role);
            const res = await API.POST("user/user/role", formData);
            this.profile = res.data.profile;
            this.menu = res.data.menu;
        },

        getPermission() {
            // url
            if (!this.profile || !this.profile.r || !this.menu) router.push("/profile");

            // let permission = false;
            // this.menu.forEach((item) => {
            //     if (item.url == url) permission = true;
            // });

            // if (!permission) router.push(this.menu[0].url);
        },
    },
});
