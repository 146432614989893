// Функция для обработки проектов
export function processProject(projects) {
    return projects.map((project) => parseJsonFields(project));
}

// Функция для проверки валидности JSON-строк
function isValidJsonString(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

// Функция для парсинга JSON-строк внутри объекта
function parseJsonFields(obj) {
    const result = { ...obj };
    for (const key in obj) {
        if (typeof obj[key] === "string" && isValidJsonString(obj[key])) {
            result[key] = JSON.parse(obj[key]);
        }
    }
    return result;
}
