<template>
    <div class="flex justify-between items-center mb-4 gap-4">
        <div class="shrink-0 text-sm text-slate-500 uppercase">
            ПОЛЬЗОВАТЕЛИ <span class="count">{{ users?.length }}</span>
        </div>

        <!-- <input type="text" class="input" placeholder="Поиск проекта" v-model="s.findme" @input="filterUsers()" /> -->

        <!-- <div v-if="s.clear" class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings()">ОЧИСТИТЬ</div> -->

        <div v-if="['Or', 'Bs', 'Lr'].includes(profile?.role)" @click="createUser()" class="btn btn-green gap-2">
            <span class="text-white my-2"><IconPlus size="5" /></span>
            <span class="text-white text-xs">СОЗДАТЬ</span>
        </div>
    </div>

    <div class="bg-white rounded">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-4 border-b border-slate-200 uppercase">
            <div class="flex justify-between gap-2"><span>ПРОЕКТ</span> <span>КЛИЕНТЫ</span></div>
            <div class="col-span-2">НАЗВАНИЕ</div>
            <div class="col-span-2">Роль</div>
            <div class="col-span-2">Другие роли</div>
            <div>VK</div>
            <div>TL</div>
            <div class="text-center">Email</div>

            <div class="text-center"></div>
        </div>

        <template v-for="item in users" :key="item.id">
            <div
                class="grid grid-cols-11 items-center gap-4 p-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50"
                :class="{ '!text-slate-400 !bg-slate-50/50': item.status == 0 }"
            >
                <div>
                    <img
                        v-if="item?.picture"
                        class="w-10 h-10 object-cover rounded-full"
                        :src="'https://statapi.timekraken.ru/users/mini/' + item?.picture"
                    />
                    <img v-else class="w-10 h-10 object-cover rounded-full" src="https://statapi.timekraken.ru/noimg.jpg" />
                </div>

                <div class="col-span-2">{{ item.secondname }} {{ item.name }}</div>

                <div class="col-span-2">{{ roles[item.role] }}</div>

                <div class="col-span-2 text-xs">
                    <div v-for="r in JSON.parse(item.roles)" :key="r">{{ roles[r] }}</div>
                </div>

                <div>{{ item.vk }}</div>
                <div>{{ item.tl }}</div>
                <div>{{ item.email }}</div>

                <div class="flex justify-center gap-2 relative group">
                    <div
                        v-if="['An', 'Or', 'Bs'].includes(profile?.role)"
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'редактировать'"
                        @mouseleave="tip = ''"
                        @click="pickUser(item), setPopup('editUser')"
                    >
                        <IcoBig name="edit" size="5" />
                    </div>
                </div>
            </div>
        </template>
    </div>

    <div class="shrink-0 text-sm text-slate-500 uppercase mt-8">ПРАВА</div>

    <div class="">
        <div :class="'grid grid-cols-' + (modules.length + 1) + ' gap-4 py-2 text-xs text-slate-400 uppercase'">
            <div></div>

            <template v-for="module in modules" :key="module">
                <div>{{ module }}</div>
            </template>
        </div>
    </div>

    <div v-for="user in users" :key="user.id" class="py-px w-full group hover:py-1 relative duration-500">
        <div :class="'grid grid-cols-' + (modules.length + 1) + ' items-center gap-4 bg-white text-slate-500 hover:bg-cyan-50  py-2 px-4 rounded'">
            <div>{{ user.secondname }} {{ user.name }}</div>

            <div v-for="module in modules" :key="module" class="flex flex-col gap-2">
                <template v-for="permission in getUserModulePermissions(user, module)" :key="permission.id">
                    <div class="flex items-center gap-2">
                        <span v-if="permission.has" class="text-teal-500"><IcoBig name="ok" :size="5" /></span>
                        <span v-else class="text-pink-500"><IcoBig name="minus" :size="5" /></span>
                        {{ permission.name }}
                    </div>
                </template>
            </div>
        </div>
    </div>

    <!-- <template v-if="s.popup == 'addUser'"><AddUser /></template>
        <template v-if="s.popup == 'editUser' && user?.id"><EditUser /></template>
        <template v-if="s.popup == 'deleteUser' && user?.id"><DeleteUser /></template> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useProfileStore } from "@/store/profile";
import { useAdminStore } from "@/store/admin";

import moment from "moment";

export default {
    name: "AdminUsersPage",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "roles", "setPopup", "closePopup", "setSettings", "clearSettings"]),
        ...mapState(useProfileStore, ["profile"]),
        ...mapState(useAdminStore, ["users", "permissions", "getAdmin", "getUsers"]),

        // Получение списка модулей из разрешений
        modules() {
            return Array.from(new Set(this.permissions.map((permission) => permission.module)));
        },
    },

    methods: {
        // // Метод для проверки наличия разрешения у пользователя
        // hasPermission(user, permission) {
        //     return user.permissions.some((p) => p.id === permission.id);
        // },

        getUserModulePermissions(user, module) {
            const userPermissionIds = new Set(user.permissions.map((permission) => permission.id));
            const permissionsWithStatus = [];

            this.permissions.forEach((permission) => {
                if (permission.module === module) permissionsWithStatus.push({ ...permission, has: userPermissionIds.has(permission.id) });
            });

            return permissionsWithStatus;
        },

        // getPermissionsByModule(module) {
        //     return this.permissions.filter((permission) => permission.module === module);
        // },

        // // Метод для получения невыбранных разрешений пользователя по модулю
        // getUnassignedPermissions(user, module) {
        //     const assignedPermissionIds = user.permissions.map((permission) => permission.id);
        //     return this.permissions.filter((permission) => permission.module === module && !assignedPermissionIds.includes(permission.id));
        // },
    },

    mounted() {
        this.getUsers();
    },
};
</script>
