<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent :view="['date', 'compact']" @update="update" />
        </div>

        <div class="w-full px-4">
            <h2 class="text-slate-400 text-sm text-center mt-10">ЗРИТЕЛЕЙ НА ВЕБИНАРЕ</h2>
            <div class="mt-4 mb-10" v-if="grafs['157450:neiro3_15']">
                <LineWebGraf :graf="grafs['157450:neiro3_15']" name="web1" h="h-48" />
            </div>

            <!-- 
            <div class="mt-4 mb-4" v-if="grafs['157450:neiro3_15']">
                <LineWebGraf :graf="grafs['157450:neiro3_20']" name="web2" h="h-48" />
            </div> -->

            <template v-for="group in days" :key="group">
                <div class="w-full text-slate-500 text-md">{{ group.group }}</div>

                <div class="w-full flex mb-8">
                    <div class="tbl w-52 shrink-0 border border-slate-100 rounded overflow-hidden text-sm">
                        <div class="bg-white">
                            <div class="flex items-center !h-8 text-slate-500">ДАТА</div>
                        </div>

                        <template v-for="item in group.data[0].items" :key="item">
                            <div class="bg-white my-2" :class="{ '!bg-sky-100': !days[0].date && days[0].name }">
                                <div class="bb">{{ item.name }}</div>
                                <div v-if="!s.compact" class="bb">На эфире</div>
                                <div v-if="!s.compact" class="bb">На повторе</div>
                            </div>
                        </template>
                    </div>

                    <div class="w-[calc(100vw-368px)] flex gap-2 overflow-x-scroll snap-x scroll-pl-2 scroll-smooth pl-2 pr-4">
                        <div
                            v-for="day in group.data"
                            :key="day.date"
                            class="tbl snap-start w-20 shrink-0 border border-slate-100 rounded overflow-hidden text-sm text-right"
                            :class="{
                                'border !border-pink-300': [6, 7].includes(moment(day.date).isoWeekday()),
                                'border !border-teal-300': moment(day.date).format('Y-M-D') == moment().format('Y-M-D'),
                                'border !border-sky-300 !text-sky-600 ': !day.date && day.name,
                            }"
                        >
                            <div class="bg-white">
                                <template v-if="day.date">
                                    <div
                                        class="flex justify-end items-center !h-8 text-slate-500"
                                        :class="{
                                            ' text-pink-500 bg-pink-50': [6, 7].includes(moment(day.date).isoWeekday()),
                                            ' text-teal-500 bg-teal-100': moment(day.date).format('Y-M-D') == moment().format('Y-M-D'),
                                        }"
                                    >
                                        {{ moment(day.date).format("DD") }}&nbsp;{{ month[parseInt(moment(day.date).format("MM"))] }}
                                    </div>
                                </template>

                                <template v-if="!day.date && day.name">
                                    <div class="flex justify-end items-center !h-8 text-sky-600 !text-[16px] !bg-sky-100">{{ day.name }}</div>
                                </template>
                            </div>
                            <template v-for="item in day.items" :key="item">
                                <div class="bg-white my-2" :class="{ '!bg-sky-100': !day.date && day.name }">
                                    <div class="bb">{{ item.total }}</div>
                                    <div v-if="!s.compact" class="bb">{{ item.web_users }}</div>
                                    <div v-if="!s.compact" class="bb">{{ item.rep_users }}</div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <!-- {{ groups }}
            <hr />
            {{ webinars }}
            <hr />
            {{ days }} -->
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";
import LineWebGraf from "../components/LineWebGraf.vue";

import FilterComponent from "@/components/Filter.vue";

export default {
    name: "WebinarsPage",

    components: { FilterComponent, LineWebGraf },

    data() {
        return {
            moment: moment,
            grafs: {
                "157450:neiro3_15": [
                    1, 8, 9, 12, 12, 15, 19, 21, 25, 33, 40, 47, 47, 52, 61, 60, 70, 73, 84, 93, 93, 94, 97, 99, 99, 111, 110, 111, 112, 116, 116,
                    119, 123, 119, 122, 123, 118, 116, 117, 117, 118, 120, 124, 122, 125, 127, 126, 129, 131, 128, 128, 128, 131, 135, 136, 134, 138,
                    141, 136, 136, 136, 135, 130, 132, 132, 133, 131, 129, 130, 127, 124, 119, 117, 116, 113, 113, 110, 113, 108, 109, 110, 107, 108,
                    103, 102, 104, 102, 99, 102, 99, 98, 96, 95, 93, 98, 97, 96, 95, 97, 93, 92, 92, 87, 89, 91, 82, 78, 79, 80, 79, 80, 77, 80, 81,
                    81, 84, 80, 83, 82, 80, 80, 81, 79, 78, 79, 77, 73, 73, 75, 73, 69, 71, 69, 68, 68, 70, 69, 69, 71, 70, 68, 71, 68, 66, 65, 66,
                    19,
                ],

                // "157450:neiro3_20": [
                //     1502, 1662, 1779, 1854, 1904, 1950, 2016, 2061, 2095, 2193, 2188, 2222, 2240, 2257, 2296, 2321, 2332, 2354, 2367, 2389, 2415,
                //     2453, 2460, 2465, 2481, 2508, 2516, 2497, 2500, 2509, 2509, 2525, 2542, 2553, 2554, 2556, 2559, 2574, 2563, 2560, 2568, 2577,
                //     2563, 2557, 2552, 2556, 2538, 2544, 2535, 2525, 2503, 2487, 2492, 2494, 2495, 2479, 2470, 2458, 2463, 2448, 2434, 2429, 2410,
                //     2403, 2411, 2393, 2391, 2401, 2393, 2396, 2386, 2374, 2384, 2366, 2364, 2348, 2350, 2346, 2347, 2323, 2332, 2331, 2322, 2315,
                //     2323, 2306, 2281, 2268, 2257, 2250, 2253, 2253, 2259, 2253, 2235, 2226, 2208, 2217, 2231, 2226, 2215, 2208, 2213, 2200, 2203,
                //     2201, 2193, 2222, 2172,
                // ],

                "157450:neiro3_20": [
                    14, 16, 18, 20, 20, 19, 19, 20, 20, 18, 20, 21, 23, 20, 21, 23, 21, 22, 23, 22, 21, 21, 21, 21, 22, 22, 22, 22, 22, 21, 21, 22,
                    23, 21, 21, 21, 21, 21, 22, 22, 22, 22, 21, 21, 21, 22, 22, 22, 24, 22, 22, 23, 24, 24, 23, 22, 24, 25, 27, 26, 24, 25, 26, 24,
                    27, 28, 24, 25, 26, 28, 27, 25, 27, 26, 25, 20, 21, 18, 21, 16, 17, 16, 18, 17, 17, 18, 16, 18, 17, 21, 22, 22, 22, 21, 21, 21,
                    20, 20, 20, 21, 21, 22, 22, 22, 21, 20, 20, 20, 18,
                ],
            },
        };
    },

    computed: {
        ...mapState(useMainStore, [
            "s",
            "month",
            "profile",
            "setPeriod",
            "setMonth",
            "setSettings",
            "addWebinarGroup",
            "delWebinarGroup",
            "setWebinar",
        ]),
        ...mapState(useClientStore, ["groups", "webinars", "getWebinars"]),

        // days() {
        //     if (!this.groups || !this.webinars) return [];

        //     let data = [];
        //     let str = moment(this.s.start);
        //     let end = moment(this.s.end);

        //     while (str <= end) {
        //         let dayData = { date: str.format("YYYY-MM-DD"), items: [] };

        //         this.groups.forEach((group) => {
        //             if (!Array.isArray(group.webinars)) return;
        //             group.webinars.forEach((item) => {
        //                 if (typeof item !== "object") return;
        //                 let dayItem = { ...item, web_users: 0, rep_users: 0, total: 0 };
        //                 this.webinars.forEach((web) => {
        //                     if (dayItem.webinar === web.name && str.isSame(web.date, "day")) {
        //                         if (dayItem.time === web.time) dayItem.web_users += web.users;
        //                         if (dayItem.repeat === web.time) dayItem.rep_users += web.users;
        //                         dayItem.total += web.users;
        //                     }
        //                 });
        //                 dayData.items.push(dayItem);
        //             });
        //         });
        //         data.push(dayData);

        //         str = str.add(1, "day");
        //     }

        //     return data;
        // },

        days() {
            if (!this.groups || !this.webinars) return [];

            let result = []; // Итоговый массив для хранения данных всех групп

            this.groups.forEach((group) => {
                let groupData = []; // Массив данных для текущей группы

                let str = moment(this.s.start);
                let end = moment(this.s.end);
                let week = { date: null, items: [] };

                if (!Array.isArray(group.webinars)) return;

                group.webinars.forEach((item) => {
                    week.items.push({ ...item, web_users: 0, rep_users: 0, total: 0 });
                });

                while (str <= end) {
                    let dayData = { date: str.format("YYYY-MM-DD"), items: [] };

                    group.webinars.forEach((item, index) => {
                        if (typeof item !== "object") return;
                        let dayItem = { ...item, web_users: 0, rep_users: 0, total: 0 };
                        this.webinars.forEach((web) => {
                            if (dayItem.webinar === web.name && str.isSame(web.date, "day")) {
                                if (dayItem.time === web.time) dayItem.web_users += web.users;
                                if (dayItem.repeat === web.time) dayItem.rep_users += web.users;
                                dayItem.total += web.users;
                                week.items[index].web_users += dayItem.web_users;
                                week.items[index].rep_users += dayItem.rep_users;
                                week.items[index].total += dayItem.total;
                            }
                        });
                        if (this.s.days) dayData.items.push(dayItem);
                    });

                    if (this.s.days) groupData.push(dayData);

                    if (moment(dayData.date).isoWeekday() === 7) {
                        week.name = moment(dayData.date).isoWeek() + " week";
                        groupData.push(week);
                        week = { date: null, items: week.items.map((item) => ({ ...item, web_users: 0, rep_users: 0, total: 0 })) };
                    }
                    str = str.add(1, "day");
                }

                // Добавляем данные текущей группы к результату
                result.push({ group: group.name, data: groupData });
            });

            return result;
        },

        // webinarNames() {
        //     if (!this.webinars) return [];
        //     return [...new Set(this.webinars.map((item) => item.name))];
        // },

        webinarNames() {
            if (!this.webinars) return [];
            return [...new Set(this.webinars.map((item) => item.name.split(":")[1]))].filter(Boolean);
        },
    },

    methods: {
        update() {
            this.getWebinars();
        },
    },

    mounted() {
        this.getWebinars();
    },
};
</script>

<style>
.tbl div div {
    padding: 2px 8px;
    overflow: hidden;
    /* border-bottom: #eee solid 1px; */
}
.bb {
    border-bottom: #eee solid 1px;
}
</style>
