<template>
    <div class="cursor-pointer text-center text-slate-400 mb-8" @click="setAdminMenu('')">Все Аккаунты</div>

    <div class="flex flex-col">
        <div v-if="adminAccount && adminAccount.id > 0" class="text-center mb-8">
            <span class="text-slate-400 text-xs">ВЫБРАН АККАУНТ #{{ adminAccount.id }}</span>

            <div class="mb-4">{{ adminAccount.name }}</div>

            <div class="flex flex-col mb-4 cursor-pointer" @click="setAdminMenu('Clients')">
                <span class="num">{{ adminAccount.clients?.length }}</span> <span class="text-slate-400 text-xs">КАБИНЕТЫ</span>
            </div>
            <div class="flex flex-col mb-4 cursor-pointer" @click="setAdminMenu('Users')">
                <span class="num">{{ adminAccount.users?.length }}</span> <span class="text-slate-400 text-xs">ПОЛЬЗОВАТЕЛИ</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAdminStore } from "@/store/admin";

export default {
    name: "ClientsComponent",

    computed: {
        ...mapState(useMainStore, ["s"]),
        ...mapState(useAdminStore, ["adminMenu", "adminAccount", "setAdminMenu"]),
    },
};
</script>
