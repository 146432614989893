<template>
    <div class="fixed top-0 left-0 w-full h-full z-50 p-4 bg-slate-100">
        <div class="absolute top-2 right-2 text-slate-400 hover:text-slate-500 cursor-pointer" @click="closePopup()">
            <IcoBig name="close" :size="6" />
        </div>

        <!-- <div class="w-full rounded-xl h-10 relative my-1 text-slate-500 group hover:my-2 duration-500"></div> -->

        <div class="grid grid-cols-3 gap-8">
            <div class="bg-white p-4 rounded-xl space-y-2 h-[calc(100vh-40px)] overflow-scroll">
                <div class="text-xl text-center text-slate-400">ПОКУПАТЕЛЬ</div>
                <div class="flex items-center gap-4">
                    <img src="https://statapi.timekraken.ru/uploads/users/u9.jpg" class="w-32" />
                    <div class="flex flex-col">
                        <p class="text-slate-400 text-xs">#{{ user.id }}</p>
                        <p>{{ user.name }} {{ user.secondname }}</p>
                        <p>{{ user.email }}</p>
                    </div>
                </div>

                <hr />

                <div class="grid grid-cols-4 text-xl text-center py-4">
                    <div class="flex flex-col">
                        <div class="text-slate-500 text-sm">РЕГИ</div>
                        <div>{{ sums.regc }}</div>
                    </div>
                    <div class="flex flex-col">
                        <div class="text-violet-500 text-sm">ВЕБЫ</div>
                        <div>{{ sums.webc }}</div>
                        <div class="text-sm">{{ sums.webs }}</div>
                    </div>
                    <div class="flex flex-col">
                        <div class="text-amber-500 text-sm">ЗАКАЗЫ</div>
                        <div>{{ sums.orderc }}</div>
                        <div class="text-sm">{{ parseInt(sums.orders).toLocaleString() }} ₽</div>
                    </div>
                    <div class="flex flex-col">
                        <div class="text-teal-500 text-sm">ОПЛАТЫ</div>
                        <div>{{ sums.paymentc }}</div>
                        <div class="text-sm">{{ parseInt(sums.payments).toLocaleString() }} ₽</div>
                    </div>
                </div>

                <hr />

                <p>{{ user.type_id }}</p>
                <p>{{ user.create_at }}</p>

                <div class="text-cyan-600">
                    <div v-if="user.channel_id == 1"><IVk :size="6" /></div>
                    <div v-if="user.channel_id == 2"><IVkAds :size="6" /></div>
                    <div v-if="user.channel_id == 3"><IVkCamp :size="6" /></div>
                    <div v-if="user.channel_id == 4"><IFb :size="6" /></div>
                    <div v-if="user.channel_id == 5"><IYa :size="6" /></div>
                    <div v-if="user.channel_id == 9"><ITgads :size="6" /></div>
                    <div v-if="user.channel_id == 10"><IYoutube :size="6" /></div>
                    <div v-if="user.channel_id == 100"><IBase :size="6" /></div>
                </div>

                <div v-if="parseInt(user.tg_id)" class="flex items-center gap-1">
                    <img
                        :src="targetologs[user.tg_id]?.pic"
                        class="shrink-0 h-8 mt-2 opacity-50 group-hover:h-[40px] group-hover:mt-0 group-hover:opacity-100 duration-500"
                    />
                    <span class="text-xs">{{ targetologs[user.tg_id]?.utm }}</span>
                </div>
                <p v-else class="text-sm text-slate-500">Таргетолог не определен</p>

                <p>{{ user.phone }}</p>
                <p>{{ user.country }}</p>

                <div class="space-y-2 text-sm">
                    <div class="flex flex-col"><span class="text-slate-400 text-xs">source</span>{{ user.utm_source }}</div>
                    <div class="flex flex-col"><span class="text-slate-400 text-xs">medium</span>{{ user.utm_medium }}</div>
                    <div class="flex flex-col"><span class="text-slate-400 text-xs">campaign</span>{{ user.utm_campaign }}</div>
                    <div class="flex flex-col"><span class="text-slate-400 text-xs">content</span>{{ user.utm_content }}</div>
                    <div class="flex flex-col"><span class="text-slate-400 text-xs">term</span>{{ user.utm_term }}</div>
                </div>
            </div>

            <div class="h-[calc(100vh-40px)] overflow-scroll col-span-2">
                <div
                    v-for="(items, key) in actions"
                    :key="items"
                    class="w-full rounded-xl relative my-1 text-slate-500 text-sm hover:my-2 duration-500"
                >
                    <div class="mb-2 mt-8 text-slate-400">
                        {{ moment(key).format("DD") }}
                        {{ month[parseInt(moment(key).format("MM"))] }}
                        <sup>'{{ moment(key).format("YY") }}</sup>
                    </div>

                    <div v-for="item in items" :key="item" class="w-full rounded-xl h-10 relative my-1 text-slate-500 group hover:my-2 duration-500">
                        <div class="bg-white h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 px-2">
                            <div class="text-sm">{{ moment(item.create_at).format("HH:mm") }}</div>

                            <div>
                                <div v-if="item.type_id == 0" class="text-slate-400 text-xs latobold">НЕ&nbsp;РАЗМЕЧЕН</div>
                                <div v-if="item.type_id == 1" class="text-slate-400 text-xs latobold">РЕГА</div>
                                <div v-if="item.type_id == 2" class="text-violet-500 text-xs latobold">ВЕБ</div>
                                <div v-if="item.type_id == 3" class="text-amber-500 text-xs latobold">ЗАКАЗ</div>
                                <div v-if="item.type_id == 4" class="text-teal-500 text-xs latobold">ОПЛАТА</div>
                            </div>

                            <div class="text-cyan-600">
                                <div v-if="item.channel_id == 1"><IVk :size="5" /></div>
                                <div v-if="item.channel_id == 2"><IVkAds :size="5" /></div>
                                <div v-if="item.channel_id == 3"><IVkCamp :size="5" /></div>
                                <div v-if="item.channel_id == 4"><IFb :size="5" /></div>
                                <div v-if="item.channel_id == 5"><IYa :size="5" /></div>
                                <div v-if="item.channel_id == 9"><ITgads :size="5" /></div>
                                <div v-if="item.channel_id == 10"><IYoutube :size="5" /></div>
                                <div v-if="item.channel_id == 100"><IBase :size="5" /></div>
                            </div>

                            <div class="flex items-center gap-1">
                                <img
                                    :src="targetologs[item.tg_id]?.pic"
                                    class="shrink-0 h-8 mt-2 opacity-50 group-hover:h-[40px] group-hover:mt-0 group-hover:opacity-100 duration-500"
                                />
                                <span class="text-xs">{{ targetologs[item.tg_id]?.utm }}</span>
                            </div>

                            <div>
                                <div v-if="item.type_id == 2" class="latobold text-right text-violet-500">
                                    {{ formatViewingTime(item.vi) }}
                                </div>

                                <div v-if="item.type_id == 3" class="latobold text-right text-amber-500">
                                    {{ parseInt(item.sum).toLocaleString() }}
                                </div>

                                <div v-if="item.type_id == 4" class="latobold text-right text-teal-500">
                                    {{ parseInt(item.sum).toLocaleString() }}
                                </div>
                            </div>

                            <div class="text-xs">{{ item.id }}</div>

                            <!-- <div class="text-xs" v-if="item.type_id == 3">{{ item.number }}</div>
                            <div class="text-xs" v-if="item.type_id == 4">{{ item.order_id }}</div> -->

                            <div class="col-span-4 h-6 overflow-hidden">{{ item.title }}</div>

                            <div class="flex gap-2 text-[8px]">
                                <span> {{ item.utm_source }}</span>
                                <span> {{ item.utm_medium }}</span>

                                <span> {{ item.utm_source_2 }}</span>
                                <span> {{ item.utm_medium_2 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 374063362 -->
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

export default {
    name: "UserInfo",

    data() {
        return {
            moment: moment,

            filters: {
                date: "",
                channel_id: null,
                tg_id: null,
                country: null,
                find: null,
                email: null,
                name: null,
            },
            tgDropdown: false,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "targetologs", "month", "closePopup"]),
        ...mapState(useClientStore, ["user", "getUser"]),

        actions() {
            if (!this.user.actions) return [];

            let data = {};

            // Проход по каждому событию и добавление его в соответствующую группу по дате
            this.user.actions.forEach((event) => {
                const date = event.create_at.split(" ")[0]; // Получаем дату без времени
                if (!data[date]) {
                    data[date] = []; // Создаем массив для даты, если он еще не существует
                }
                data[date].push(event); // Добавляем событие в массив для даты
            });

            return data;
        },

        sums() {
            if (!this.user.actions) return [];

            let data = { regc: 0, regs: 0, webc: 0, webs: 0, orderc: 0, orders: 0, paymentc: 0, payments: 0 };

            this.user.actions.forEach((event) => {
                if (event.type_id == 1) data.regc++;

                if (event.type_id == 2) {
                    data.webc++;

                    let intervals;
                    try {
                        intervals = JSON.parse(event.vi);
                    } catch (e) {
                        return;
                    }

                    if (!Array.isArray(intervals)) return;

                    intervals.forEach((interval) => {
                        data.webs += interval.e - interval.s;
                    });
                }
                if (event.type_id == 3) {
                    data.orderc++;
                    data.orders += parseInt(event.sum);
                }
                if (event.type_id == 4) {
                    data.paymentc++;
                    data.payments += parseInt(event.sum);
                }
            });

            // Convert total seconds to hours and minutes
            let totalSeconds = data.webs;
            let hours = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds % 3600) / 60);

            // Store the result as a string in hours and minutes
            data.webs = `${hours}ч ${minutes}м`;

            return data;
        },

        // uniqueCountries() {
        //     const countriesSet = new Set();

        //     this.users.forEach((item) => {
        //         if (item.country) {
        //             countriesSet.add(item.country);
        //         }
        //     });

        //     return Array.from(countriesSet).sort();
        // },

        // uniqueTgIds() {
        //     const tgIdsSet = new Set();
        //     this.users.forEach((order) => {
        //         tgIdsSet.add(order.tg_id);
        //     });
        //     return Array.from(tgIdsSet);
        // },

        // usersF() {
        //     return this.users.filter((item) => {
        //         const dateMatch = !this.filters.date || item.date === this.filters.date;
        //         const channelIdMatch = !this.filters.channel_id || item.channel_id === this.filters.channel_id;
        //         const tgIdMatch = !this.filters.tg_id || item.tg_id === this.filters.tg_id;

        //         const countryMatch = !this.filters.country || item.country === this.filters.country;

        //         const findMatch =
        //             !this.filters.find ||
        //             (item.id && item.id.toString().indexOf(this.filters.find) !== -1) ||
        //             (item.name && item.name.toLowerCase().includes(this.filters.find.toLowerCase())) ||
        //             (item.email && item.email.toLowerCase().includes(this.filters.find.toLowerCase()));

        //         return dateMatch && channelIdMatch && tgIdMatch && countryMatch && findMatch;
        //     });
        // },

        // total() {
        //     const count = this.usersF.length;

        //     return { count };
        // },
    },

    watch: {
        filters: {
            handler() {},
            deep: true,
        },
    },

    methods: {
        // clearFilters() {
        //     this.filters = { date: "", channel_id: null, tg_id: null, country: null, find: null };
        //     this.tgDropdown = false;
        // },

        formatViewingTime(vi) {
            if (!vi) return;

            let intervals;
            try {
                intervals = JSON.parse(vi);
            } catch (e) {
                return;
            }

            if (!Array.isArray(intervals)) return;

            let totalSeconds = 0;

            intervals.forEach((interval) => {
                totalSeconds += interval.e - interval.s;
            });

            let hours = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds % 3600) / 60);
            let seconds = totalSeconds % 60;

            let result = [];

            if (hours > 0) {
                result.push(`${hours}ч`);
            }
            if (minutes > 0 || hours > 0) {
                // Показать минуты, если есть часы или минуты
                result.push(`${minutes}м`);
            }
            if (hours === 0 && minutes === 0) {
                // Показать секунды только если нет часов и минут
                result.push(`${seconds}с`);
            }

            return result.join(" ");
        },
    },

    mounted() {
        this.getUser();
    },
};
</script>
